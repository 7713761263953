<form [formGroup]="tdsreportFilterForm">
  <div class="flexbox aff-info">
    <div class="flexbox aff-info-inner">
      <div class="flexbox ">
        <div class="input-fiel">
          <input class="input-ui" searchtabindex="1" formControlName="start_date" id="from_date" type="date"
              min="{{minDate}}" max="{{maxDate}}"
              [ngClass]="{'error-box': !tdsreportFilterForm.controls.start_date.valid}"
              />
          <label for="from_date">Starting Date</label>

        </div>
        <div class="input-fiel">
          <input class="input-ui" searchtabindex="2" formControlName="end_date" id="to_date" type="date"
              min="{{minDate}}" max="{{maxDate}}"
              [ngClass]="{'error-box': !tdsreportFilterForm.controls.end_date.valid}"
              />
          <label for="to_date">Ending Date</label>
        </div>
      </div>

      <span class="report-style-heading border-bottom mr-10 mt-10 mb-10 pb-10">Report Style</span>

			<div class="flexBox d-flex mt-1 ml-2 mr-2 justify-content-between">

				
				<div class="input-fiel" >
					<label class="radio-label" for="normalView">Normal</label>
					<input class="radio-ui" id="normalView" type="radio" name="viewMode" value="normal"
						 formControlName="viewMode" />
				</div>
				<div class="input-fiel">
					<label class="radio-label" for="detailedView">Detailed</label>
					<input class="radio-ui" id="detailedView" type="radio" name="viewMode" value="detailed"
					formControlName="viewMode" />
				</div>
      <div class="select-field text-input-fiel">
        <select class="input-field" formControlName="subgroup_type">
          <option value="">Select</option>
          <option value="client">Client</option>
          <option value="supplier">Supplier</option>
        </select>
      </div>
      </div>

      <div class="flexbox mt-3">
				<div class="select-field text-input-fiel">
				  <select class="input-field"  formControlName="selectedBranch" id="selectedBranch">
					<option value="">Select</option>
					<option value="">None</option>
					<option value="all">All</option>
					<option *ngFor="let item of branches" value="{{item.tenant_id}}">{{item?.branch_name ? item.branch_name : "HQ"}}
					</option>
				  </select>
				  <label for="selectedBranch">Branch</label>
				</div>
			  </div>
      
      <div class="d-flex mt-5 justify-content-center">
        <div class="input-fiel">
          <button id="submitSearchForm" type="button" class="btn-style bg-purple mr-3" data-dismiss="modal"
            searchtabindex="3" #searchButton (keyup.enter)="clickSearchButton()"
            [disabled]="tdsreportFilterForm.invalid"
            (click)="search(tdsreportFilterForm.value)">
            Search</button>
          <button type="button" class="btn-style bg-green" id="resetSearchForm" (click)="reset()">RESET</button>
        </div>
      </div>
    </div>
    
      
  
  </div>
</form>