
 <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
	<div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
	  <div class="d-flex flex-y-center">
		<img
		  src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
			alertType=='success-box' ? 'green-sucess.svg' : 
			alertType=='warning-box' ? 'red-warning.svg' : 
			null }}"
		  width="20px"
		  height="20px"
		  alt="alert-logo"
		  class="mr-20"
		/>
		<div class="ml-10">
		  <h4 class="m-0 text-size-18 text-bold"
		  [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
			{{alertType === "error-box"
			? "Error"
			: alertType === "warning-box"
			? "Warning"
			: alertType === "success-box"
			? "Success"
			: null}}
		  </h4>
		  <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
		   {{message}}
		  </small>
		</div>
	  </div>
	  <img
		src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
		alt="close-btn"
		(click)="hideError()"
		className=" close-btn"
	  />
	</div>
  </div>
  
<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>

<button type="button" hidden #openSuccessModal data-toggle="modal" data-target="#successModal"></button>
			<!-- Modal -->
    <div id="successModal" class="modal fade" role="dialog" data-backdrop="false">
        <div class="modal-dialog transaction-creation-modal">
            <!-- Modal content-->
            <div class="modal-content"  *ngIf="journal_code">
                <div class="modal-header">
                    <span class="messageFont">
                        <span>
                            {{message}} 
                        </span>
                            <a *ngIf="journal_code != ''" 
                                href="javascript:void(0)"	
                                (click)="viewJournal(journal_code)">
                                {{journal_code}}
                            </a>
                            <i *ngIf="journal_code != ''" [title]="copyELementTitle" (click)="copytext(journal_code)" class="fa fa-copy cursor-pointer"></i>
                        </span>
                        <button type="button" class="close" (click)="closeSuccesModal()">&times;</button>
                    </div>
                <div class="modal-body">
                    <p>Do you want to create new JV or  view newly created JV ?</p>
                </div>
                <div class="modal-footer justify-content-center">
                    <button 
                        tabIndex="1" 
                        tabindex="1"
                        type="button" 
                        class="btn btn-primary bg-blue" 
                        (click)="viewJournal(journal_code)">
                        View
                    </button>
                    <button 
                        tabIndex="3" 
                        tabindex="3"
                        type="button" 
                        class="btn btn-primary bg-green"  
                        (click)="closeSuccesModal()">
                        Create New
                    </button>
                </div>
            </div>
        </div>
    </div>

<div class="ledger-container p-0" [ngClass]="{'d-none': !newJv}" >
	<ng-container>
		<div class="row tab-nav pl-0 pr-0">
			<div class="col-sm-4 pr-0 d-flex align-item-center ">
				<ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
					<li [routerLink]="['/']" class="text-grey text-capitalize cursor  hover-text-primary transition"> Master
					</li>
					<li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
					<li class="text-grey text-capitalize cursor text-grey transition">View JV</li>
					<li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
					<li class="text-grey text-capitalize cursor text-primary transition">Create JV</li>
				</ul>
			</div>
		</div>

		<form [formGroup]="createJvForm">
				<div class="pl-15 p-15 pt-3 custom-form form-container w-100 pb-4">
					<div class="row">
						<div class="col-sm-2  text-field-group d-flex align-items-center  pb-3">
							<div class="text-input-field">
								<input formControlName="jv_date" tabIndex="0" class="input-field" type="date" 
									min="{{minDate}}" 
									max="{{maxDate}}"
									placeholder="MM/DD/YYYY" autocomplete="off"
									[ngClass]="{'validateError': !createJvForm.controls.jv_date.valid}"/>
								<label>JV Date<span class="text-danger">*</span></label>
							</div>
						</div>

						<ng-container *ngIf="!isTenantIndian"> 
							<div class="col-sm-2 text-field-group d-flex align-items-center  pb-3" >
								<div class="text-input-field">
									<select tabIndex="1" tabindex="1" class="input-field w-20"
									formControlName="currency_code" (change)="onJvCurrencyChange()" >
										<option value="">Select</option>
										<option value="AED">AED</option>
										<option value="CAD">CAD</option>
										<option value="USD">USD</option>
										<option value="INR">INR</option>
										<option value="EURO">EURO</option>
									</select>
									<label for="currency_code">Currency</label>
								</div>
							</div>
		
							<div class="col-sm-2 text-field-group d-flex align-items-center  pb-3">
								<div class="text-input-field">
									<input tabIndex="2" tabindex="2" class="input-field w-20" type="text" formControlName="currency_rate"/>
									<label>Rate <span *ngIf="acCurrencyCode && createJvForm.value.currency_code">({{acCurrencyCode}}-{{createJvForm.value.currency_code}})</span></label>
								</div>
							</div>
						</ng-container>

					</div>
					
					<!-- <div class="table-head">Entries</div> -->
					<div class="">
						<table class="table table-bordered">
							<thead>
								<tr>
									<th>S.No.</th>
									<th>Acc. Type</th>
									<th class="text-center" style="width: 25%;">Account</th>
									<th>Acc. Bal</th>
									<th>Sub Ledger Type</th>
									<th>Sub Ledger</th>
									<!-- <th>Date</th> -->
									<th class="text-right">Debit</th>
									<th class="text-right">Credit</th>
									<th class="text-center">Narration</th>
									<th class="text-center">Remarks</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody formArrayName="jvArray">
								<tr *ngFor="let jvEntry of DynamicFormControls.controls; let i = index; "
									[formGroupName]="i">
									<td>{{i+1}}</td>
									<td>
										<select class="inner-select" tabIndex="{{i*8+(isTenantIndian ? 1 : 3)}}" attr.tabIndex="{{i*8+(isTenantIndian ? 1 : 3)}}"
											formControlName="account_type" style="width:  55px;">
											<option value="">Select</option>
											<option value="client">Client</option>
											<option value="airline">Airline</option>
											<option value="supplier">Supplier</option>
											<option value="employee">Employee</option>
											<option value="general">General</option>
										</select>
									</td>
									<td>
										<div class="autocomplete-container" >
											<div class="input-container">
												<input tabIndex="{{i*8+(isTenantIndian ? 2 : 4)}}" attr.tabIndex="{{i*8+(isTenantIndian ? 2 : 4)}}"
													type="text" #accountAC oninput="this.value = this.value.toUpperCase()" 
													(input)="searchAccount($event,i)" formControlName="account_code_name"
													[nzAutocomplete]="accountAutocomplete"
												/>
						
												<nz-autocomplete #accountAutocomplete [nzBackfill]="true"  (selectionChange)="selectAccount($event,'account_code',i)">
													<nz-auto-option  class="colr-1" *ngFor="let item of accountList[i]" [nzValue]="item" [nzLabel]="item.name">
														<a innerHTML='<span class="colr-1">{{item?.name}}({{item?.account_code}})</span><br />'></a>                            
													</nz-auto-option>   
													<nz-auto-option *ngIf="showCreateOption && currentIndex==i" class="colr-1" nzValue="" style="text-align: center"
														nzLabel="Create New">
														<span>Create New</span>                          
													</nz-auto-option>                      
												</nz-autocomplete>
											</div>
										</div>
											<!-- <ng-autocomplete #accountAc [data]="accountList[i]" [searchKeyword]="keyword"
												(selected)="selectAccount($event,'account_code',i)"
												(inputChanged)='searchAccount($event,i)' [itemTemplate]="itemTemplate"
												[notFoundTemplate]="notFoundTemplate">
											</ng-autocomplete>
											<ng-template #itemTemplate let-item>
												<a
													innerHTML='<span class="colr-1">{{item.name}}({{item.account_code}})</span><br />'>
												</a>
											</ng-template>

											<ng-template #notFoundTemplate let-notFound>
												<div [innerHTML]="notFound"></div>
											</ng-template> -->

											
									</td>
									<td>
										<input readonly class="inner-input-ui text-right" type="text" value="" />
									</td>
									<td>
										<select class="inner-select" tabIndex="{{i*8+(isTenantIndian ? 3 : 5)}}" attr.tabIndex="{{i*8+(isTenantIndian ? 3 : 5)}}"
											formControlName="sub_ledger_type" style="width:  55px;">
											<option value="">Select</option>
											<option value="client">Client</option>
											<option value="airline">Airline</option>
											<option value="supplier">Supplier</option>
											<option value="employee">Employee</option>
											<option value="general">General</option>
										</select>
									</td>
									<td>
										<div class="ng-autocomplete custom-ngc" tabIndex="{{i*8+(isTenantIndian ? 4 : 6)}}" 
									     	attr.tabIndex="{{i*8+(isTenantIndian ? 4 : 6)}}" (focus)="focusSubLedgerAC($event,i)">
											<ng-autocomplete [data]="ledgerList[i]" #subLedgerAc [searchKeyword]="keyword"
												(selected)="selectLedger($event,'sub_ledger_code',i)"
												(inputChanged)='searchLedger($event,i)' [itemTemplate]="itemTemplate"
												[notFoundTemplate]="notFoundTemplate">
											</ng-autocomplete>
											<ng-template #itemTemplate let-item>
												<a
													innerHTML='<span class="colr-1">{{item.name}}({{item.account_code}})</span><br />'>
												</a>
											</ng-template>

											<ng-template #notFoundTemplate let-notFound>
												<div [innerHTML]="notFound"></div>
											</ng-template>
										</div>
									</td>

									<td>
										<input class="inner-input-ui" tabIndex="{{i*8+(isTenantIndian ? 5 : 7)}}" 
									    	attr.tabIndex="{{i*8+(isTenantIndian ? 5 : 7)}}"
											oninput="this.value = this.value.toUpperCase()"
											class="inner-input-ui text-right" type="text" (input)="calculateDiff(i,'debit')"
											(focus)="$event.target.select()" formControlName="debit"
											pattern="^\d*(\.\d{0,8})?$"
											[ngClass]="{'error-field': (jvEntry.controls.debit.invalid && jvEntry.controls.debit.dirty),'success-field' : jvEntry.controls.debit.valid && jvEntry.controls.debit.dirty}" />
									</td>
									<td>
										<input class="inner-input-ui" tabIndex="{{i*8+(isTenantIndian ? 6 : 8)}}" 
											attr.tabIndex="{{i*8+(isTenantIndian ? 6 : 8)}}"
											oninput="this.value = this.value.toUpperCase()"
											class="inner-input-ui text-right" type="text"
											(input)="calculateDiff(i,'credit')" (focus)="$event.target.select()"
											formControlName="credit" pattern="^\d*(\.\d{0,8})?$"
											[ngClass]="{'error-field': (jvEntry.controls.credit.invalid && jvEntry.controls.credit.dirty),'success-field' : jvEntry.controls.credit.valid && jvEntry.controls.credit.dirty}" />
									</td>
									<td>
										<input class="inner-input-ui" tabIndex="{{i*8+(isTenantIndian ? 7 : 9)}}" 
											attr.tabIndex="{{i*8+(isTenantIndian ? 7 : 9)}}"
											oninput="this.value = this.value.toUpperCase()"
											class="inner-input-ui text-right" type="text" formControlName="narration_1" />
									</td>
									<td>
										<input class="inner-input-ui" tabIndex="{{i*8+(isTenantIndian ? 8 : 10)}}" 
											attr.tabIndex="{{i*8+(isTenantIndian ? 8 : 10)}}"
											(keyup.enter)="movetoIndex(i)" oninput="this.value = this.value.toUpperCase()"
											class="inner-input-ui text-right" type="text" formControlName="remarks_1" />
									</td>
									<td class="edit-row text-center">
										<button  title="Delete" class="custom-table-btn bg-white border-0 p-0 text-bold hoverableShadow transition cursor" type="button"
										(click)="removeJvRow(i)" id="remove_jv_btn_{{i}}">
										<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/dark-delete.svg" alt="" width="20px">
										
									</button>
										<!-- <button title="Delete..." class="btn   save-as text-bold bg-whiteSmoke "
											type="button" (click)="removeJvRow(i)">
											<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/red-delete.svg" alt="" width="13px">
										</button> -->
									</td>

								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td colspan="4"></td>
									<td class="text-black font-weight-normal text-right" style="padding : 10px">Difference
									</td>
									<td class="text-black font-weight-normal text-right" style="padding : 10px"
										[ngClass]="{'text-danger': createJvForm.value.amountDiff  != 0,'text-success' : createJvForm.value.amountDiff  == 0}">
										<span>{{createJvForm.value.amountDiff | number : '1.2-2'}}</span>
									</td>
									<td class="text-black font-weight-normal text-right" style="padding : 10px">Total</td>
									<td class="text-black font-weight-normal text-right" style="padding : 10px">
										{{createJvForm.value.totalDebit | number : '1.2-2'}}
									</td>
									<td class="text-black font-weight-normal text-right" style="padding : 10px">
										{{createJvForm.value.totalCredit | number : '1.2-2'}}
									</td>
									<td colspan="3"></td>
								</tr>
							</tfoot>
						</table>
					</div>

					<!-- <div class="fare-diff-col d-flex justify-content-between align-items-center">
						
						<div class="text-field-group">
							<div class="text-input-field mt-0">
								<button type="button" class="btn-style mr-3" attr.tabIndex="{{lastInputIndex+1}}" tabIndex="{{lastInputIndex+1}}"   (click)="addJvRow()">Add Row</button>
							</div>
						</div>

						<div class="text-field-group d-flex">
							<span class="info alert-warning" >* To Save JV, Difference Should be zero (0).</span>
						</div>
					</div> -->
					<button type="button" hidden #confirmModal data-toggle="modal"
						data-target="#confirmationModal"></button>
				</div>
			<div class="btn-field bottom-btns-container justify-content-end d-flex align-item-center ml-0 mr-0"> 
				
				<div>
					<button type="button" class="btn-style bg-green mr-3" (click)="reset()">Reset</button>    
				<button type="button" class="btn-style bg-purple" attr.tabIndex="{{lastInputIndex+ (isTenantIndian ? 1 : 3)}}"
					tabIndex="{{lastInputIndex+ (isTenantIndian ? 1 : 3)}}" (click)="openConfirmModal(lastInputIndex, isTenantIndian ? 3 : 5)" id="save_jv_btn">Save</button>
				</div>
			</div>
		</form>

	</ng-container>

</div>


<div class="modal fade" id="confirmationModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
	<div class="modal-dialog modal-md" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h4>Confirmation</h4>
				<button type="button" class="close" #closeModal data-dismiss="modal" aria-label="Close" (click)="setIsModalOpen()">

					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				Add New Entry ?
			</div>
			<div class="modal-footer">
				<!-- <button type="button" class="btn-style mr-3"   data-dismiss="modal">No</button> -->
				<button type="button" attr.tabIndex="{{lastInputIndex+2}}" tabIndex="{{lastInputIndex+2}}"
					class="btn-style" (click)="addJvRow()">Yes</button>
				<button type="button" class="btn-style mr-2" attr.tabIndex="{{lastInputIndex+3}}"
					tabIndex="{{lastInputIndex+3}}" (click)="submit(createJvForm.value)">No</button>
			</div>
		</div>
	</div>
</div>

<button hidden #openMasterCreationModal data-target="#masterCreationModal"data-toggle="modal"></button>
<div
  class="modal"
  id="masterCreationModal"
  role="dialog"
  aria-labelledby="masterModalLabel"
  data-backdrop="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex justify-content-center">
          <h4 class="modal-title">Create {{currentMaster}}</h4>
        </div>
        <button
          type=" button"
          class="close"
          #closeMasterCreationModal
          data-dismiss="modal"
          aria-label="Close"
		  (click)="closeMasterModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
	    <app-master-creation [masterType]="currentMaster" [masterCreationModal]="masterCreationModal" [currentMasterName]="currentMasterInputName" 
		(callSetFormData)="setFormValues($event)" (closeModal)="closeMasterModal()"
		></app-master-creation>
     </div>
    </div>
</div>