<form [formGroup]="sectorService.invoiceForm">
	<div>
		
		<div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
			<div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
			  <div class="d-flex flex-y-center">
				<img
				  src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
					alertType=='success-box' ? 'green-sucess.svg' : 
					alertType=='warning-box' ? 'red-warning.svg' : 
					null }}"
				  width="20px"
				  height="20px"
				  alt="alert-logo"
				  class="mr-20"
				/>
				<div class="ml-10">
				  <h4 class="m-0 text-size-18 text-bold"
				  [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
					{{alertType === "error-box"
					? "Error"
					: alertType === "warning-box"
					? "Warning"
					: alertType === "success-box"
					? "Success"
					: null}}
				  </h4>
				  <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
				   {{message}}
				  </small>
				</div>
			  </div>
			  <img
				src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
				alt="close-btn"
				(click)="hideError()"
				className=" close-btn"
			  />
			</div>
		  </div>


		<div class="invoice-container invoice-table">
			<!-- <div class="heading">INVOICE</div> -->
			<table class="table table-hovered table-bordered">
				<thead>
					<tr>
						<th>CRS ID</th>
						<th  class="text-left">Retrieve Id</th>
						<th  class="text-left">Inv. Type</th>
						<th class="text-left">PNR</th>
						<th class="text-left" style="width: 100px;" *ngIf="invoiceMode != 'view'">Last Name</th>
						<th class="text-left">Airline</th>
						<th  class="text-left">Class</th>
						<th *ngIf="invoiceMode != 'view'">Action</th>
						<th  class="text-left" style="width: 100px;">Sub Ledger Type</th>
						<th  class="text-left">Sub-ledger</th>
						<th  class="text-left">Fare Basis</th>
						<th  class="text-left">Deal Code</th>
						
						<th  class="text-left">Employee</th>
						<th>Traveller(s)</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<select tabIndex="{{isTenantIndian ? 6 : 8}}" style="width: max-content;" class="inner-select"
								(change)="getSupplier($event.target.value)" formControlName="crsId">
								<option *ngFor="let crs of crdIdArr" value="{{crs}}">{{crs}}</option>
							</select>
						</td>
						<td>
							<select tabIndex="{{isTenantIndian ? 7 : 9}}" style="width:max-content" class="inner-select"
								formControlName="supplier" (change)="onSupplierChange($event.target.value)">
								<option value="">Select</option>
								<option *ngFor="let item of supplierArr" value="{{item.display_name}}">
									{{item.display_name}}
								</option>
							</select>
						</td>
						<td>
							<select tabIndex="{{isTenantIndian ? 8 : 10}}" class="inner-select" formControlName="invoiceType"
								(change)="onInvoiceTypeChange($event.target.value,true)">
								<option value="B">BSP</option>
								<option value="C">C-R</option>
								<option value="X">X-O</option>
							</select>
						</td>
						<td><input tabIndex="{{isTenantIndian ? 9 : 11}}" oninput="this.value = this.value.toUpperCase()" type="text"
								class="field-input" formControlName="pnr" (blur)="handlePnrInput(false)"
                                [ngClass]="{'validateError': (sectorService.invoiceForm.controls.pnr.invalid && sectorService.invoiceForm.controls.pnr.touched) || 
								(!sectorService.invoiceForm.controls.pnr.valid && sectorService.submitted)}"
								maxlength="10" pattern="([A-Z0-9a-z])\w+" (change)="sectorService.setNarration()"/>
						</td>

						<td *ngIf="invoiceMode != 'view'">
							<input oninput="this.value = this.value.toUpperCase()" type="text" 
							class="field-input" formControlName="lastName" />
					    </td>

						<td>
							<div class="autocomplete-container" (focus)="focusAirlineAc()">
								<div class="input-container" >
							<input #airlineAC  
								type="text" tabIndex="{{isTenantIndian ? 10 : 12}}"
								oninput="this.value = this.value.toUpperCase()" 
								name="airline_name"
								formControlName="airline_name"
								(input)="airlineSearch($event)"
								[nzAutocomplete]="airlineAuto"
							/>
							<nz-autocomplete #airlineAuto [nzBackfill]="true"  (selectionChange)="selectAccount($event,'airline')" >
									<div class="auto-complete-listing">
									<nz-auto-option  class="colr-1" *ngFor="let item of sectorService.airlineArr" [nzValue]="item" [nzLabel]="item.name"
									>
										<a 	innerHTML='<span class="colr-1 selected-ng">{{item.name}} ({{item.account_code}})</span><br />'></a>                            
									</nz-auto-option>      
									
									<nz-auto-option *ngIf="sectorService.showCreateButton && sectorService.currentMaster == 'airline' " class="colr-1" nzValue="" style="text-align: center"
										nzLabel="Create New">
										<span>Create New</span>                          
									</nz-auto-option>        
								</div>
								</nz-autocomplete>
								</div>
							</div>
							
						</td>
						<td>
							<select tabIndex="{{isTenantIndian ? 15 : 17}}" class="inner-select" formControlName="classType" (change)="invoiceMode!= 'view' && sectorService.calculateServiceChargeFlightWise(invoice_root_data)">
								<option value="">Select</option>
								<option value="ECONOMY">Economy</option>
								<option value="BUSINESS">Business</option>
								<option value="PREMIUM_ECONOMY">PREMIUM ECONOMY</option>
								<option value="FIRST">FIRST</option>
								<option *ngIf="invoice_root_data.flightType == 'international'" value="first">First</option>
							</select>
						</td>
						<td *ngIf="invoiceMode != 'view'">
							<button type="button" class="btn-style retrieveBtn" (click)="retrieveBooking()"
								[ngClass]=" 
									[
									sectorService.invoiceForm.invalid ? 'disbaledBtn' : 'bg-green'
									]" tabIndex="{{isTenantIndian ? 11 : 13}}"
								[disabled]="sectorService.invoiceForm.invalid">Retrieve</button>
						</td>
						<td>
							<select class="inner-select" tabIndex="{{isTenantIndian ? 11 : 13}}" formControlName="sub_ledger_code_1_type"
								(change)="getAccounts($event.target.value)">
								<option value="">None</option>
								<option value="client">Client</option>
								<option value="airline">Airline</option>
								<option value="supplier">Supplier</option>
								<option value="general">General</option>
							</select>
						</td>
						<td >
							<div class="autocomplete-container" (focus)="focusSubledgerAc()">
							<div class="input-container">
							<input #subLedgerAC 
								type="text" tabIndex="{{isTenantIndian ? 12 : 14}}"
								oninput="this.value = this.value.toUpperCase()" 
								name="sub_ledger_code_1_name"
								formControlName="sub_ledger_code_1_name"
								(ngModelChange)="ledgerSearch($event)"
								[nzAutocomplete]="subLedgerAuto"
							/>

							<div class="auto-complete-listing">
								<nz-autocomplete  #subLedgerAuto [nzBackfill]="true" (selectionChange)="selectAccount($event,'sub_ledger_code_1')"  >
									<nz-auto-option  class="colr-1" *ngFor="let item of sectorService.ledgerList" [nzValue]="item" [nzLabel]="item.name">
										<a innerHTML='<span class="colr-1 selected-ng">{{item.name}}({{item.account_code}})</span><br />'></a>                           
									</nz-auto-option>                      
								</nz-autocomplete>
							</div>
							</div>
							</div>

						</td>
						<td><input  tabIndex="{{isTenantIndian ? 13 : 15}}" oninput="this.value = this.value.toUpperCase()" type="text"
								class="field-input" formControlName="fareBasis" pattern="^[a-zA-Z0-9_]*$"  maxlength="10"/></td>

						<td><input tabIndex="16" oninput="this.value = this.value.toUpperCase()" type="text" tabIndex="{{isTenantIndian ? 14 : 16}}"
								class="field-input" formControlName="dealCode" pattern="^[a-zA-Z0-9_]*$" maxlength="10"/></td>

						
						<td>
							<div class="autocomplete-container">
								<div class="input-container">
							<input #employeeAC style="text-transform: uppercase;" 
								[ngClass]="{'validate-ng-error': (sectorService.invoiceForm.controls.employee_name.invalid && sectorService.invoiceForm.controls.employee_name.touched) ||
								(!sectorService.invoiceForm.controls.employee_name.valid && sectorService.submitted)}"
								type="text" (focus)="focusEmployeeAc()"  
								oninput="this.value = this.value.toUpperCase()" 
								name="employee_name"
								formControlName="employee_name"
								(input)="employeeSearch($event)"
								[nzAutocomplete]="employeeAuto"
								[attr.disabled]="invoiceMode == 'view' && loggedInuser && loggedInuser.accountcode != 'ADMIN' && sectorService.invoiceForm.value.employee != ''"
							/>

							<nz-autocomplete #employeeAuto [nzBackfill]="true" (selectionChange)="selectAccount($event,'employee_name')">
								<nz-auto-option  class="colr-1" *ngFor="let item of sectorService.employeeList" [nzValue]="item" [nzLabel]="item.name"
								 >
								    <a innerHTML='<span class="colr-1 selected-ng">{{item.name}}({{item.account_code}})</span><br />'></a>                           
								</nz-auto-option>                      
							</nz-autocomplete>

							
							<span class="text-danger" *ngIf="sectorService.invoiceForm.value.employee == ''">Please
								Enter
								Employee</span>

							<!-- <select class="inner-select" formControlName="employee">
								<ng-container  *ngFor="let item of sectorService.employeeList">
									<option  *ngIf="item.account_code != ''" value="{{item.account_code}}">{{item.name}}</option>
								</ng-container>
							</select> -->
							</div>
							</div>
						</td>
						<!-- <td><input  oninput="this.value = this.value.toUpperCase()" type="text" class="field-input" formControlName="payable" /></td> -->
						<td>
							<input style="width: 50px!important;" tabIndex="{{isTenantIndian ? 16 : 18}}"
								oninput="this.value = this.value.toUpperCase()" type="number" class="field-input"
								formControlName="travellerCount" (change)="onTravellerBlur($event)" name="traveller" />
						</td>
					</tr>
				</tbody>
			</table>
			<app-sector-info [sectorService]="sectorService" [isTenantIndian]="isTenantIndian"></app-sector-info>
			<app-traveller-info [sectorService]="sectorService" (callSaveProduct)="saveProductInvoice()" (callSaveTraveller)="saveTravellers($event)"
				(callShowMessage)="showMessageFn($event)" [invoiceType]="invoiceType" [invoice_no]="invoice_no"
				[invoice_root_data]="invoice_root_data"  [invoiceMode]="invoiceMode">
			</app-traveller-info>
			<app-total-fare-info [sectorService]="sectorService"></app-total-fare-info>
			<!-- <div class="traveller-action-col d-flex justify-content-center mt-4">
				<ng-container *ngIf="invoiceMode != 'view'">
					<button type="button" class="btn-style mb-5" tabIndex="{{sectorService.lastIndexOfTraveller+1}}"
						attr.tabIndex="{{sectorService.lastIndexOfTraveller+1}}" (click)="saveProductInvoice()"
						[ngClass]="{disbaledBtn: sectorService.travellerForm.invalid || sectorService.invoiceForm.invalid || sectorService.sectorForm.invalid}"
						[disabled]="sectorService.travellerForm.invalid || sectorService.invoiceForm.invalid || sectorService.sectorForm.invalid">
						Save
					</button>
				</ng-container>
				<ng-container *ngIf="invoiceMode == 'view'">

					<button type="button" tabIndex="{{sectorService.lastIndexOfTraveller+1}}"
						attr.tabIndex="{{sectorService.lastIndexOfTraveller+1}}" class="btn-style mb-5"
						(click)="updateProduct()"
						[ngClass]="{disbaledBtn: sectorService.travellerForm.invalid || sectorService.invoiceForm.invalid || sectorService.sectorForm.invalid}"
						[disabled]="sectorService.travellerForm.invalid || sectorService.invoiceForm.invalid || sectorService.sectorForm.invalid">
						Update
					</button>
				</ng-container>

				<button type="button" tabIndex="{{sectorService.lastIndexOfTraveller+2}}"
					attr.tabIndex="{{sectorService.lastIndexOfTraveller+2}}" (click)="closeMaxModule()"
					class="btn-style mb-5 ml-3">
					Cancel
				</button>
			</div> -->

		</div>
	</div>
</form>
<button type="button" hidden #deleteManuallyModal data-toggle="modal" data-target="#deleteConfirmationModal"></button>

<div id="deleteConfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Info!</h4>
        <button type="button" #closeDeleteModal class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <p>Please delete travellers manually.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style bg-purple" tabIndex="26" attr.tabIndex="26" 
          data-dismiss="modal">Ok</button>
      </div>
    </div>
  </div>
</div>