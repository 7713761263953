import { Component, OnInit, HostListener, ViewChild, ElementRef, Input } from '@angular/core';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment-timezone';
import { GlobalVariable } from 'src/app/shared/global-variable';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Store } from '@ngrx/store';
import { InitialState } from "src/app/store/reducer";
import {  Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ShortcutInput } from 'ng-keyboard-shortcuts';

@Component({
	selector: 'app-adjust-balance',
	templateUrl: './adjust-balance.component.html',
	styleUrls: ['./adjust-balance.component.scss'],
	providers: [CommonServiceService]
})
export class AdjustBalanceComponent implements OnInit {
	p = 0;
	q = 0;
	leaderData: any;
	navigationSubscription;
	reInitialise: boolean;
	currentDate = moment().format('YYYY-MM-DD');
	currentTime = moment().format('HH:mm:ss');
	innerHeight: string;
	ascSorttdsAmt: boolean = true;
	ascSortRef: boolean = true;
	totalAmountObj: any;

	showMessage:boolean=false;
	alertType:String="";
	message:String=""
	clientData:any
	isAdvancedSearchOpen: boolean = false;
	onSearch;
	onSearchFilter = new Subject<string>();
	parentIndex=0;
	childIndex=0;
	@ViewChild('tableHeader', {static:true}) tableHeader : ElementRef
	alreadyAdjusted: boolean;
	
	isPrint: any;
	duration: any;
	allLedgerArr: any;
	ledgerArr: any[];
	shortcuts: ShortcutInput[] = [];
	referenceTypeName = {
		"invoice" : "INVOICE",
		"credit_note" : "Credit Note",
		"jv" : "Journal",
	}
	company_details: any;
	totalReceiptBalance: any;
	totalBalance: any
	transactionAmount = 0;
	deletedAmount = 0;

	transactionData: any;
	adjustmentForm: FormGroup;
	leftAmount : any;
	referenceType: any

	minDate : any
	maxDate : any
	constructor(
		private route: ActivatedRoute,
		public commonService: CommonServiceService,
		private router: Router,
		private spinner: NgxSpinnerService,
		private fb:FormBuilder,
		private store: Store<InitialState>

	) {
		this.store.select('sharedReducers').subscribe((items)=>{

			if(items.financeYear && items.financeYear.start_date && items.financeYear.end_date){
				this.minDate = items.financeYear.start_date
				this.maxDate = items.financeYear.end_date
			}
				
		})
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});

		this.adjustmentForm = this.fb.group({
			receiptReference: ["",Validators.required]
		})
	}
	
	
	async ngOnInit() {
		let queryParams = this.route.queryParams['value']
	
		let queryData = Object.assign({}, queryParams)
		this.spinner.show()
		
		// queryData['start_date'] = this.minDate;
		if(queryData.reference_type == 'payment' ) {
			this.referenceType = 'payment'
		}else if(queryData.reference_type == 'receipt' ){
			this.referenceType = 'receipt'
		}

		if(queryData) {
			this.getData(queryData)
		}
		
		this.onSearchFilter.pipe(
			debounceTime(300),
			distinctUntilChanged())
			.subscribe(value => {
			  this.filterData(value)
		});
		this.reInitialise = true
	}

	getReceiptDetails(queryData) {
		this.commonService
		  .getOkount(`receiptentryv3?receipt_code=${queryData.adjustment_reference}`)
		  .subscribe(
			async (res: any) => {
				if (res[0] != undefined) {
					this.transactionData = res;
					for(let item of this.transactionData) {
						this.transactionAmount += Number(item.amount)
					}
				}
				this.getDeletedAmount(queryData)
			},
			(err) => {}
		  );
	  }

	getPaymentDetails(queryData) {
		this.commonService
		  .getOkount(`paymententryv3?payment_code=${queryData.adjustment_reference}`)
		  .subscribe(
			async (res: any) => {
				if (res[0] != undefined) {
					this.transactionData = res;
					for(let item of this.transactionData) {
						this.transactionAmount += Number(item.amount)
					}
				}
				this.getDeletedAmount(queryData)
			},
			(err) => {}
		  );
	}
	
	initialiseInvites() {
		if (this.reInitialise) {
			this.ngOnInit()
		}
	}
	filterData(value) {
		let tempArr = Object.assign([], this.allLedgerArr)
		if(value.length > 3){
			let lowerCaseFilter = value.toLowerCase()
			let upperCaseFilter = value.toUpperCase()
			this.ledgerArr = tempArr.filter(
				invoiceData => {
					if (	
							
							(
								invoiceData.narration.join('') != undefined && 
								invoiceData.narration.join('') != null && 
								(	
									invoiceData.narration.join('').toString().includes(value) || 
									invoiceData.narration.join('').toString().includes(upperCaseFilter) ||
									invoiceData.narration.join('').toString().includes(lowerCaseFilter)
								)
							) ||
							(
								invoiceData.date != undefined && 
								invoiceData.date != null && 
								(	
									invoiceData.date.includes(value) || 
									invoiceData.date.includes(upperCaseFilter) ||
									invoiceData.date.includes(lowerCaseFilter)
								)
							) ||
							(
								invoiceData.amount_debit != undefined && 
								invoiceData.amount_debit != null && 
								(	
									invoiceData.amount_debit.toString().includes(value) || 
									invoiceData.amount_debit.toString().includes(upperCaseFilter) ||
									invoiceData.amount_debit.toString().includes(lowerCaseFilter)
								)
							) ||
							(
								invoiceData.amount_credit != undefined && 
								invoiceData.amount_credit != null && 
								(	
									invoiceData.amount_credit.toString().includes(value) || 
									invoiceData.amount_credit.toString().includes(upperCaseFilter) ||
									invoiceData.amount_credit.toString().includes(lowerCaseFilter)
								)
							) ||
							(
								invoiceData.balance != undefined && 
								invoiceData.balance != null && 
								(	
									invoiceData.balance.toString().includes(value) || 
									invoiceData.balance.toString().includes(upperCaseFilter) ||
									invoiceData.balance.toString().includes(lowerCaseFilter)
								)
							) ||
							(
								invoiceData.reference != undefined && 
								invoiceData.reference != null && 
								(	
									invoiceData.reference.includes(value) || 
									invoiceData.reference.includes(upperCaseFilter) ||
									invoiceData.reference.includes(lowerCaseFilter)
								)
							) 
	
						) {
						return invoiceData
					}
				}
			)
		}else{
			this.ledgerArr = []
			for (let i = 0; i <= this.allLedgerArr.length; i++) {
				const element = this.allLedgerArr[i];
				if (element != undefined) {
					this.ledgerArr.push(element)
				}
			}
		}
		
	}
	

	ngOnDestroy() {
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		if (this.leaderData != undefined) {
			this.setHeight(this.p, this.q, event.target.innerHeight)
		}
	}

	getData(queryParams) {
		this.spinner.show()
		this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/payment_adjustment`, queryParams).subscribe((res: any) => {

			if(this.referenceType == 'receipt') {
				this.getReceiptDetails(queryParams)
			}else {
				this.getPaymentDetails(queryParams)
			}

			this.leaderData = res
			this.totalBalance = res['all_clients_records'][0]['outstanding']
			this.duration = res['duration']
			this.company_details = res['company_details']
			
			let responseArr= res['all_clients_records'][0].client_records[0].records
			this.ledgerArr = []
			this.allLedgerArr = []

			if(this.referenceType == 'receipt') {
				if(responseArr && responseArr.length > 0) {
					for(let obj of responseArr) {
					obj['is_adjusted'] = false
					if(obj['amount_debit']> 0) {
						if(obj['transaction_remaining_amount'] > 0) {
							obj['remaining_amount'] = obj['transaction_remaining_amount']
						}else {
							obj['remaining_amount'] = obj['amount_debit']
						}
					} else {
						if(obj['transaction_remaining_amount'] > 0) {
							obj['remaining_amount'] = obj['transaction_remaining_amount'] * -1
						}else {
							obj['remaining_amount'] = obj['amount_credit'] * -1
						}
					}
					
					this.allLedgerArr.push(obj)
					}
				}
			}else {
				if(responseArr && responseArr.length > 0) {
					for(let obj of responseArr) {
					obj['is_adjusted'] = false
					if(obj['amount_credit']> 0) {
						if(obj['transaction_remaining_amount'] > 0) {
							obj['remaining_amount'] = obj['transaction_remaining_amount'] * -1;
						}else {
							obj['remaining_amount'] = obj['amount_credit'] * -1;
						}
					} else {
						if(obj['transaction_remaining_amount'] > 0) {
							obj['remaining_amount'] = obj['transaction_remaining_amount'] 
						}else {
							obj['remaining_amount'] = obj['amount_debit'] * -1
						}
					}
					this.allLedgerArr.push(obj)
					}
				}
			}


			for (let i = 0; i <= this.allLedgerArr.length; i++) {
				const element = this.allLedgerArr[i];
				if (element != undefined) {
					this.commonService.selectedInput.next(1)
					this.ledgerArr.push(element)
				}
			}		

			this.focusOnTableHeader()
			if (this.allLedgerArr != undefined) {
				this.setHeight(0, 0, window.innerHeight)
			}

		}, err => {
			console.error(err)
		})
		this.spinner.hide()
	}

	setHeight(pIndex, innerPIndex, height) {
		if (height == undefined) {
			height = window.innerHeight
		}
		pIndex = Number(pIndex)
		innerPIndex = Number(innerPIndex)
		this.p = pIndex
		this.q = innerPIndex

		let parent = 0
		if (pIndex > 0) {
			parent = pIndex - 1
		}
		let child = 0
		if (innerPIndex > 0) {
			child = innerPIndex - 1
		}
		if (this.leaderData['all_clients_records'].length > 0) {
			if (this.leaderData['all_clients_records'][parent].client_records.length > 0) {
				if (this.leaderData['all_clients_records'][parent].client_records[child].records.length <= 5) {
					this.innerHeight = 'auto';
				}
				else {
					this.innerHeight = height - 230 + 'px';
				}
			}
			else {
				this.innerHeight = height - 230 + 'px';
			}
		}
		else {
			this.innerHeight = height - 230 + 'px';
		}
	}

	tdsamtSort() {
		this.ascSorttdsAmt = !this.ascSorttdsAmt;
		var testData = this.leaderData;
		this.leaderData.data = testData.data.sort((a, b) => {
			if (this.ascSorttdsAmt) {
				if (b['balance'] < a['balance']) {
					return -1;
				}
				if (b['balance'] > a['balance']) {
					return 1;
				}
				return 0;
			} else {
				if (a['balance'] < b['balance']) {
					return -1;
				}
				if (a['balance'] > b['balance']) {
					return 1;
				}
				return 0;
			}
		});
	}

	refSort() {
		this.ascSortRef = !this.ascSortRef;
		let pIndex = Number(this.p)
		let parent = 0
		if (pIndex > 0) {
			parent = pIndex - 1
		}
		var testData = Object.assign([], this.leaderData['all_clients_records'][parent].client_records)
		for (let index = 0; index < testData.length; index++) {
			const element = testData[index];
			let sortedData = element.records.sort((a, b) => {
				if (this.ascSortRef) {
					if (b['reference'] < a['reference']) {
						return -1;
					}
					if (b['reference'] > a['reference']) {
						return 1;
					}
					return 0;
				} else {
					if (a['reference'] < b['reference']) {
						return -1;
					}
					if (a['reference'] > b['reference']) {
						return 1;
					}
					return 0;
				}
			});
			this.leaderData['all_clients_records'][parent].client_records[index].records = sortedData
		}
	}
	
	
	hideError(){
		this.showMessage = false
	}

	getClient(value) {
		if (value.length > 2) {
			this.commonService.getOkount(`client/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
					this.clientData = res[0]
			}, err => {
				console.log(err)
			})
		}
	}

	getAmount() {
		let amountObj = {
			total_amount_to_be_adjusted: 0 ,
			total_amount_selected: 0,
			remaining_balance: 0
		}
		
		let totalAdjustingAmount = 0
		let totalSelectedAmount = 0
	
		if(this.deletedAmount > 0) {
			totalAdjustingAmount = Number(this.deletedAmount)
	    }else {
			totalAdjustingAmount = Number(this.transactionAmount)
		}

		let selectedInvoices = this.allLedgerArr.filter(data => data.is_adjusted == true)

		if(this.referenceType == 'receipt') {
			if(selectedInvoices.length > 0) {
				for(let obj of selectedInvoices) {
					if(obj.amount_debit > 0) {
						if(obj['transaction_remaining_amount'] && Number(obj['transaction_remaining_amount']) > 0) {
							totalSelectedAmount += Number(obj.transaction_remaining_amount)
						}else {
							totalSelectedAmount += Number(obj.amount_debit)
						}
					} else {
						if(obj['transaction_remaining_amount'] && Number(obj['transaction_remaining_amount']) > 0) {
							totalSelectedAmount -= Number(obj.transaction_remaining_amount)
						}else {
							totalSelectedAmount -= Number(obj.amount_credit)
						}
					}
				}
			}
		}else {
			if(selectedInvoices.length > 0) {
				for(let obj of selectedInvoices) {
					if(obj.amount_credit > 0) {
						if(obj['transaction_remaining_amount'] && Number(obj['transaction_remaining_amount']) > 0) {
							totalSelectedAmount += Number(obj.transaction_remaining_amount)
						}else {
							totalSelectedAmount += Number(obj.amount_credit)
						}
					} else {
						if(obj['transaction_remaining_amount'] && Number(obj['transaction_remaining_amount']) > 0) {
							totalSelectedAmount -= Number(obj.transaction_remaining_amount)
						}else {
							totalSelectedAmount -= Number(obj.amount_debit)
						}
					}
				}
			}
		}
			
		amountObj['total_amount_to_be_adjusted'] = Number(totalAdjustingAmount)
		amountObj['total_amount_selected'] = Number(totalSelectedAmount)
		amountObj['remaining_balance'] = Number(totalAdjustingAmount) - (Number(totalSelectedAmount))	

		return amountObj
	}

	focusOnTableHeader() :void{
		if (this.tableHeader != undefined) {
			this.tableHeader.nativeElement.focus()
		}
	}

	onKeyDown(e: any) {
		if (GlobalVariable.openModalStatus == false && GlobalVariable.openGlobalSearch == false) {
			if (e.keyCode == 40) {
				e.preventDefault();
				this.moveToNext()
			} else if (e.keyCode === 38) {
				e.preventDefault();
				this.moveToPrev()
			}
		}
	}
	moveToNext(){
		if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex+1}`) != null) {
			document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex+1}`).focus()
			this.childIndex = this.childIndex+1
		}else{
			if (this.parentIndex < this.leaderData['all_clients_records'][0].client_records.length -1) {
				this.parentIndex = this.parentIndex+1
				this.childIndex = -1
			}
			if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex+1}`) != null) {
				document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex+1}`).focus()
				this.childIndex = this.childIndex+1
			}else if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex}`) != null) {
				document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex}`).focus()
				this.childIndex = this.childIndex+1
			}
		}
	}
	moveToPrev(){
		if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex-1}`) != null) {
			document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex-1}`).focus()
			this.childIndex = this.childIndex-1
		}else{
			if (this.parentIndex > 0) {
				this.parentIndex = this.parentIndex-1
				if (this.leaderData['all_clients_records'][0].client_records[this.parentIndex] != undefined) {
					this.childIndex = this.leaderData['all_clients_records'][0].client_records[this.parentIndex].records.length
				}
			}else{
				this.childIndex = 0
			}
			if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex-1}`) != null) {
				document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex-1}`).focus()
				if (this.childIndex >0) {
					this.childIndex = this.childIndex-1
				}
			}else if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex}`) != null) {
				document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex}`).focus()
				if (this.childIndex >0) {
					this.childIndex = this.childIndex-1
				}
			}
		}
	}
	
	moveToIndex(parentIndex,childIndex){
		document.getElementById(`rowIndex_${parentIndex}_${childIndex}`).focus()
		this.parentIndex = parentIndex
		this.childIndex = childIndex
	}

	updateAdjustmentData() {
		let updatedData = this.allLedgerArr.filter(data => data.is_adjusted == true)
		let transactionData = this.transactionData
		
		let queryRef, accountCode;
		 if(this.referenceType == 'receipt') {
			queryRef = transactionData && transactionData.length > 0 && transactionData[0].receipt_code ? transactionData[0].receipt_code : ''
			accountCode = transactionData && transactionData.length > 0 && transactionData[0].account_code_from ? transactionData[0].account_code_from : ''
		 }else {
			queryRef = transactionData && transactionData.length > 0 && transactionData[0].payment_code ? transactionData[0].payment_code : ''
			accountCode = transactionData && transactionData.length > 0 && transactionData[0].account_code_to ? transactionData[0].account_code_to : ''
		}
		
		this.commonService.patchRequest(`${environment.okountUrl}/payment_adjustment?reference=${queryRef}&account_code=${accountCode}&reference_type=${this.referenceType}`, updatedData).subscribe(res => {
			    this.showMessage = true;
				this.alertType = 'success-box'
				this.message = 'Balance Adjusted Successfully...'

			let data = transactionData && transactionData.length > 0 && transactionData[0] 
			let queryParams = {
				"account_code" : accountCode,
				"adjustment_reference": queryRef,
				"reference_type": this.referenceType
			}

			setTimeout(() => {
				this.router.navigate(['report/adjusted-invoices'], { queryParams: queryParams })
			}, 1500)

		}, error => {
		
		})
	}

	onSelectItem(item) {
		if(item!=null && item != undefined) {
			let data = this.allLedgerArr.find(obj => obj.id == item.id)
			data.is_adjusted = !item['is_adjusted']
			this.totalAmountObj = this.getAmount()

	        if(this.referenceType == 'receipt') {
				if(data['is_adjusted'] == true) {
					if(data['amount_debit'] > 0) {
						if(this.totalAmountObj['remaining_balance'] >= 0) {	
							if(data['transaction_remaining_amount'] && Number(data['transaction_remaining_amount']) > 0) { 
								data['adjustment_amount'] = Number(data['transaction_remaining_amount'])
							}else {
								data['adjustment_amount'] = Number(data['amount_debit']) 
							}
							data['remaining_amount'] = 0
						}else {
							data['remaining_amount'] = this.totalAmountObj['remaining_balance']
							if(data['transaction_remaining_amount'] && Number(data['transaction_remaining_amount']) > 0) { 
								data['adjustment_amount'] = Number(data['transaction_remaining_amount']) + Number(data['remaining_amount'])
							}else {
								data['adjustment_amount'] = Number(data['amount_debit']) - (Number(data['remaining_amount']) * -1)
							}
							this.showMessage = true;
							this.alertType = 'warning-box'
							this.message = `Invoice ${item['reference']} will be adjusted partially with remaining amount ${Math.abs(this.totalAmountObj.remaining_balance)}`
							data['remaining_amount'] = item.amount_debit != 0 && item.amount_credit == 0 ? Math.abs(this.totalAmountObj['remaining_balance']) : 0
						}
					} else {
						data['remaining_amount'] = this.totalAmountObj['remaining_balance']
						if(data['transaction_remaining_amount'] && Number(data['transaction_remaining_amount']) > 0) {
							data['adjustment_amount'] = Number(data['transaction_remaining_amount'])
						}else {
							data['adjustment_amount'] = Number(data['amount_credit'])
						}
					}
				} else {
					data['adjustment_amount'] = 0
					if(data['amount_debit'] > 0) {
						if(data['transaction_remaining_amount'] && Number(data['transaction_remaining_amount']) > 0) {
							data['remaining_amount'] = Number(data['transaction_remaining_amount'])
						} else {
							data['remaining_amount'] = Number(data['amount_debit'])
						}
					} else {
						if(data['transaction_remaining_amount'] && Number(data['transaction_remaining_amount']) > 0) {
							data['remaining_amount'] = Number(data['transaction_remaining_amount']) * -1
						} else {
						    data['remaining_amount'] = Number(data['amount_credit']) * -1 
					    }
					}
				}	
		    } else {
				if(data['is_adjusted'] == true) {
					if(data['amount_credit'] > 0) {
						if(this.totalAmountObj['remaining_balance'] >= 0) {	
							if(data['transaction_remaining_amount'] && Number(data['transaction_remaining_amount']) > 0) { 
								data['adjustment_amount'] = Number(data['transaction_remaining_amount'])
							}else {
								data['adjustment_amount'] = Number(data['amount_credit']) 
							}
							data['remaining_amount'] = 0
						}else {
							data['remaining_amount'] = this.totalAmountObj['remaining_balance']
							if(data['transaction_remaining_amount'] && Number(data['transaction_remaining_amount']) > 0) { 
								data['adjustment_amount'] = Number(data['transaction_remaining_amount']) + Number(data['remaining_amount'])
							}else {
								data['adjustment_amount'] = Number(data['amount_credit']) - (Number(data['remaining_amount']) * -1)
							}
							this.showMessage = true;
							this.alertType = 'warning-box'
							this.message = `Credit Note ${item['reference']} will be adjusted partially with remaining amount ${Math.abs(this.totalAmountObj.remaining_balance)}`
							data['remaining_amount'] = item.amount_credit != 0 && item.amount_debit == 0 ? Math.abs(this.totalAmountObj['remaining_balance']) : 0
						}
					} else {
						data['remaining_amount'] = 0
						if(data['transaction_remaining_amount'] && Number(data['transaction_remaining_amount']) > 0) {
							data['adjustment_amount'] = Number(data['transaction_remaining_amount'])
						}else {
							data['adjustment_amount'] = Number(data['amount_debit'])
						}
					}
				} else {
					data['adjustment_amount'] = 0
					if(data['amount_credit'] > 0) {
						if(data['transaction_remaining_amount'] && Number(data['transaction_remaining_amount']) > 0) {
							data['remaining_amount'] = Number(data['transaction_remaining_amount'])
						} else {
							data['remaining_amount'] = Number(data['amount_credit'])
						}
					} else {
						if(data['transaction_remaining_amount'] && Number(data['transaction_remaining_amount']) > 0) {
							data['remaining_amount'] = Number(data['transaction_remaining_amount']) * -1
						} else {
							data['remaining_amount'] = Number(data['amount_debit']) * -1
						}
					}
				}	
			}
		}
		
		if(this.totalAmountObj.remaining_balance <= 0){
			let notSelected = this.allLedgerArr.filter(obj => obj.is_adjusted == false)
			for(let record of notSelected) {
				let element = document.getElementById(`is_adjusted_${record.reference}`)
				if(element != null) {
					element['disabled'] = true
				}
			}
		} 

		if(this.totalAmountObj.remaining_balance > 0){
			for(let record of this.allLedgerArr) {
				if(record['already_adjusted'] != true) {
					let element = document.getElementById(`is_adjusted_${record.reference}`)
					if(element != null) {
						element['disabled'] = false
					}
				}
			}
		} 
	}

	getDeletedAmount(data) {
		this.commonService
		  .getOkount(`payment_adjustment/get-reference-data?reference=${data['adjustment_reference']}&account_code=${data['account_code']}`)
		  .subscribe(
			async (res: any) => {		
				if(res && res['data'] && res['data']['adjustment_amount'] && res['data']['adjustment_amount'] >= this.transactionAmount) {
					let queryParams = {
						"account_code" : data['account_code'],
						"adjustment_reference": data['adjustment_reference']
					}

					this.showMessage = true;
					this.alertType = 'error-box'
					this.message = `${this.referenceType == 'receipt' ? 'Receipt' : 'Payment'} ${data['adjustment_reference']} is already fully adjusted.`

					setTimeout(() => {
						this.router.navigate(['report/adjusted-invoices'], { queryParams: queryParams })
					}, 1500)
			    }
		
				if (res && res['data'] && res['data']['adjustment_amount']) {
					let adjustment_amount = Number(res['data']['adjustment_amount'])
					this.deletedAmount = Number(this.transactionAmount) - Number(adjustment_amount)
				}
				this.totalAmountObj = this.getAmount()
			},
			(err) => {}
		  );
	}

}