import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PurchaseInvoiceComponent } from './purchase-invoice.component';
import { AddEntryPurchaseInvoiceComponent } from './add-entry-purchase-invoice/add-entry-purchase-invoice.component';


const routes: Routes = [

	{
		path: 'purchase', component: PurchaseInvoiceComponent
	}, 
	{
		path: 'purchase/:mode', component: AddEntryPurchaseInvoiceComponent
	},
	{
		path: 'purchase/:mode/:invoice', component: AddEntryPurchaseInvoiceComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PurchaseInvoiceRoutingModule { }
