import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  Injectable,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormArray, FormBuilder, Validators } from "@angular/forms";
import { Subscription, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import * as moment from "moment-timezone"
import { MsFareSummaryModel } from "../models/ms-fare-summary-model";
import { CommonServiceService } from "src/app/shared/common-service.service";
@Injectable({
  providedIn: "root",
})
export class MiscellaneousInvoiceService {
  
  invoiceMode: string;
  invoiceForm: any;
  ledgerList: any = [];
  travellerForm: any;
  travellerList: FormArray;
  totalFareModel = []
  fareList = []
  lastIndexOfTraveller = 48

  invoice_config = {
    "hotel" : {
      "general_ledger" : "I03HT",
      "sac_code" : ""
    },
    "insurance" : {
      "general_ledger" : "I03IN",
      "sac_code" : ""
    },
    "visa" : {
      "general_ledger" : "I03VS",
      "sac_code" : ""
    },
    "rail" : {
      "general_ledger" : "I03RL",
      "sac_code" : ""
    },
    
  }
  updated_invoice_config= {
    "hotel" : {
      "general_ledger" : "HI0000121",
      "sac_code" : ""
    },
    "insurance" : {
      "general_ledger" : "II0000123",
      "sac_code" : ""
    },
    "visa" : {
      "general_ledger" : "VI0000122",
      "sac_code" : ""
    },
    "rail" : {
      "general_ledger" : "RI0000126",
      "sac_code" : ""
    },
    "car" : {
      "general_ledger" : "CI0000128",
      "sac_code" : ""
    },
    "bus" : {
      "general_ledger" : "BI0000127",
      "sac_code" : ""
    },
  }
  constructor(private fb: FormBuilder, private httpClient: HttpClient,public commonService: CommonServiceService) {
    this.initialiseFormData({},{})
    this.travellerForm = this.fb.group({
			travellerList: this.fb.array([]),
		});
    let obj = {
      invoice_no: 'New Invoice',
    };
    this.fareList.push(obj);
    this.addTraveller({})
    this.calculateFromApi(0)
  }

  initialiseTraveller(){
    this.travellerForm = this.fb.group({
			travellerList: this.fb.array([]),
		});
    this.travellerList = this.travellerForm.get("travellerList");
    this.travellerList = this.travellerForm.get("travellerList") as FormArray;
    this.travellerList.controls = []
    let obj = {
      invoice_no: 'New Invoice',
    };
    this.fareList = [obj]
    this.addTraveller({})
    this.calculateFromApi(0)
  }

  addTraveller(data) {
    if(!this.travellerList){
      this.travellerList = this.travellerForm.get("travellerList");
		  this.travellerList = this.travellerForm.get("travellerList") as FormArray;
      this.travellerList.controls = []
    }
		this.travellerList.push(this.buildTraveller(data));
    if(this.invoiceForm.value.invoice_type == 'hotel'){
      this.lastIndexOfTraveller = 48
    }else{
      this.lastIndexOfTraveller = 45
    }
    this.lastIndexOfTraveller = this.travellerList.controls.length * this.lastIndexOfTraveller
	}

  removeTraveller(i) {
		this.travellerList.removeAt(i)
    this.invoiceForm.controls.number_of_pax.setValue(this.travellerList.length)
		this.calculateFromApi(0)
	}
  travellerCountUpdate(travellerLength) {

		this.travellerList = this.travellerForm.get("travellerList");
		this.travellerList = this.travellerForm.get("travellerList") as FormArray;
    let tempControl = Object.assign({},this.travellerList.controls)
    this.travellerList.controls = []
		for (let i = 0; i < travellerLength; i++) {
      if(tempControl[i]){
        let data = tempControl[i].value
        this.addTraveller(data)
      }else{
        this.addTraveller({})
      }
		}
		return ""
	}

  buildTraveller(data) {
    let hsn = this.getHSN(this.invoiceForm.value.invoice_type)
		return this.fb.group({

      hotel : [data.hotel ? data.hotel : ""],
      hotel_name : [data.hotel_name ? data.hotel_name : ""],

      room_name : [data.room_name ? data.room_name : ""],
      reference_number: [data.reference_number ? data.reference_number : ""],
      lead_pax_name: [data.lead_pax_name ? data.lead_pax_name.account_name?data.lead_pax_name.account_name:data.lead_pax_name : ""],

      supplier_non_account: [data.supplier_non_account ? data.supplier_non_account : 0],
      supplier_basic: [data.supplier_basic ? data.supplier_basic : 0],
      supplier_taxes: [data.supplier_taxes ? data.supplier_taxes : 0],
      supplier_commission_type: [data.supplier_commission_type ? data.supplier_commission_type : "RB"],
      supplier_commission_value: [data.supplier_commission_value ? data.supplier_commission_value : 0],
      supplier_commission_amount: [data.supplier_commission_amount ? data.supplier_commission_amount : 0],
      supplier_discount_type: [data.supplier_discount_type ? data.supplier_discount_type : "VL"],
      supplier_discount_amount: [data.supplier_discount_amount ? data.supplier_discount_amount : 0],
      supplier_tds: [data.supplier_tds ? data.supplier_tds : 0],
      supplier_tds_type: [data.supplier_tds_type ? data.supplier_tds_type : "C"],
      supplier_tds_conf: [data.supplier_tds_conf ? data.supplier_tds_conf : 5],

      supplier_sc1_type: [data.supplier_sc1_type ? data.supplier_sc1_type : "T"],
      supplier_sc1_amount: [this.invoiceMode && this.invoiceMode=='save'?0:data.supplier_sc1_amount ? data.supplier_sc1_amount : 0],
      supplier_sc2_type: [data.supplier_sc2_type ? data.supplier_sc2_type : "B"],
      supplier_sc2_taxable: [data.supplier_sc2_taxable ? data.supplier_sc2_taxable : "N"],
      supplier_sc2_amount: [data.supplier_sc2_amount ? data.supplier_sc2_amount : 0],
      supplier_sc3_type: [data.supplier_sc3_type ? data.supplier_sc3_type : "OT"],
      supplier_sc3_taxable: [data.supplier_sc3_taxable ? data.supplier_sc3_taxable : "N"],
      supplier_sc3_amount: [data.supplier_sc3_amount ? data.supplier_sc3_amount : 0],
      
      supplier_gst_type: [this.invoiceMode && this.invoiceMode=='save'?"S":data.supplier_gst_type ? data.supplier_gst_type : "S"],
      supplier_gst_conf: [this.invoiceMode && this.invoiceMode=='save'?18:data.supplier_gst_conf ? data.supplier_gst_conf : 18],
      supplier_cgst: [this.invoiceMode && this.invoiceMode=='save'?0:data.supplier_cgst ? data.supplier_cgst : 0],
      supplier_sgst: [this.invoiceMode && this.invoiceMode=='save'?0:data.supplier_sgst ? data.supplier_sgst : 0],
      supplier_igst: [this.invoiceMode && this.invoiceMode=='save'?0:data.supplier_igst ? data.supplier_igst : 0],
     
      supplier_cgst_on_raf: [data.supplier_cgst_on_raf ? data.supplier_cgst_on_raf : 0],
      supplier_sgst_on_raf: [data.supplier_sgst_on_raf ? data.supplier_sgst_on_raf : 0],
      supplier_igst_on_raf: [data.supplier_igst_on_raf ? data.supplier_igst_on_raf : 0],
      supplier_vat_on_raf: [data.supplier_vat_on_raf ? data.supplier_vat_on_raf : 0],

      supplier_gst_type_2: [this.invoiceMode && this.invoiceMode=='save'?"S":data.supplier_gst_type_2 ? data.supplier_gst_type_2 : "B"],
      supplier_gst_conf_2: [this.invoiceMode && this.invoiceMode=='save'?0:data.supplier_gst_conf_2 ? data.supplier_gst_conf_2 : 0],
      supplier_cgst_2: [this.invoiceMode && this.invoiceMode=='save'?0:data.supplier_cgst_2 ? data.supplier_cgst_2 : 0],
      supplier_sgst_2: [this.invoiceMode && this.invoiceMode=='save'?0:data.supplier_sgst_2 ? data.supplier_sgst_2 : 0],
      supplier_igst_2: [this.invoiceMode && this.invoiceMode=='save'?0:data.supplier_igst_2 ? data.supplier_igst_2 : 0],

      supplier_gst_type_3: [this.invoiceMode && this.invoiceMode=='save'?"S":data.supplier_gst_type_3 ? data.supplier_gst_type_3 : "T"],
      supplier_gst_conf_3: [this.invoiceMode && this.invoiceMode=='save'?0:data.supplier_gst_conf_3 ? data.supplier_gst_conf_3 : 0],
      supplier_cgst_3: [this.invoiceMode && this.invoiceMode=='save'?0:data.supplier_cgst_3 ? data.supplier_cgst_3 : 0],
      supplier_sgst_3: [this.invoiceMode && this.invoiceMode=='save'?0:data.supplier_sgst_3 ? data.supplier_sgst_3 : 0],
      supplier_igst_3: [this.invoiceMode && this.invoiceMode=='save'?0:data.supplier_igst_3 ? data.supplier_igst_3 : 0],
      
      supplier_travel_insurance : [data.supplier_travel_insurance ? data.supplier_travel_insurance : 0],
      supplier_meal_charges : [data.supplier_meal_charges ? data.supplier_meal_charges : 0],
      supplier_permit_charges : [data.supplier_permit_charges ? data.supplier_permit_charges : 0],
      supplier_parking_charges : [data.supplier_parking_charges ? data.supplier_parking_charges : 0],
      supplier_toll_charges : [data.supplier_toll_charges ? data.supplier_toll_charges : 0],
      supplier_xxl_charges: [data.supplier_xxl_charges ? data.supplier_xxl_charges : 0],
      supplier_raf_charges: [data.supplier_raf_charges ? data.supplier_raf_charges : 0],

      supplier_net_amount: [this.invoiceMode && this.invoiceMode=='save'?0:data.supplier_net_amount ? data.supplier_net_amount : 0],

      client_non_account: [data.client_non_account ? data.client_non_account : 0],
      client_basic: [data.client_basic ? data.client_basic : 0],
      client_taxes: [data.client_taxes ? data.client_taxes : 0],
      client_commission_type: [data.client_commission_type ? data.client_commission_type : "RB"],
      client_commission_value: [data.client_commission_value ? data.client_commission_value : 0],
      client_commission_amount: [data.client_commission_amount ? data.client_commission_amount : 0],
      client_discount_type: [data.client_discount_type ? data.client_discount_type : "VL"],
      client_discount_amount: [data.client_discount_amount ? data.client_discount_amount : 0],
      client_tds: [data.client_tds ? data.client_tds : 0],
      client_tds_type: [data.client_tds_type ? data.client_tds_type : "C"],
      client_tds_conf: [data.client_tds_conf ? data.client_tds_conf : 5],

      client_sc1_type: [data.client_sc1_type ? data.client_sc1_type : "T"],
      client_sc1_amount: [this.invoiceMode && this.invoiceMode=='save'?0:data.client_sc1_amount ? data.client_sc1_amount : 0],
      client_sc2_type: [data.client_sc2_type ? data.client_sc2_type : "B"],
      client_sc2_taxable: [data.client_sc2_taxable ? data.client_sc2_taxable : "N"],
      client_sc2_amount: [data.client_sc2_amount ? data.client_sc2_amount : 0],
      client_sc3_type: [data.client_sc3_type ? data.client_sc3_type : "OT"],
      client_sc3_taxable: [data.client_sc3_taxable ? data.client_sc3_taxable : "N"],
      client_sc3_amount: [data.client_sc3_amount ? data.client_sc3_amount : 0],

      client_gst_type: [this.invoiceMode && this.invoiceMode=='save'?"S":data.client_gst_type ? (data.invoice_type=='non_account' && data.client_gst_type=="T"?"TOTAL": data.client_gst_type) : "S"],
      client_gst_conf: [this.invoiceMode && this.invoiceMode=='save'?18:data.client_gst_conf ? data.client_gst_conf :18],
      client_cgst: [this.invoiceMode && this.invoiceMode=='save'?0:data.client_cgst ? data.client_cgst : 0],
      client_sgst: [this.invoiceMode && this.invoiceMode=='save'?0:data.client_sgst ? data.client_sgst : 0],
      client_igst: [this.invoiceMode && this.invoiceMode=='save'?0:data.client_igst ? data.client_igst : 0],
    
      client_cgst_on_raf: [data.client_cgst_on_raf ? data.client_cgst_on_raf : 0],
      client_sgst_on_raf: [data.client_sgst_on_raf ? data.client_sgst_on_raf : 0],
      client_igst_on_raf: [data.client_igst_on_raf ? data.client_igst_on_raf : 0],
      client_vat_on_raf: [data.client_vat_on_raf ? data.client_vat_on_raf : 0],

      client_gst_type_2: [this.invoiceMode && this.invoiceMode=='save'?"S":data.client_gst_type_2 ? data.client_gst_type_2 : "B"],
      client_gst_conf_2: [this.invoiceMode && this.invoiceMode=='save'?0:data.client_gst_conf_2 ? data.client_gst_conf_2 : 0],
      client_cgst_2: [this.invoiceMode && this.invoiceMode=='save'?0:data.client_cgst_2 ? data.client_cgst_2 : 0],
      client_sgst_2: [this.invoiceMode && this.invoiceMode=='save'?0:data.client_sgst_2 ? data.client_sgst_2 : 0],
      client_igst_2: [this.invoiceMode && this.invoiceMode=='save'?0:data.client_igst_2 ? data.client_igst_2 : 0],

      client_gst_type_3: [this.invoiceMode && this.invoiceMode=='save'?"S":data.client_gst_type_3 ? data.client_gst_type_3 : "T"],
      client_gst_conf_3: [this.invoiceMode && this.invoiceMode=='save'?0:data.client_gst_conf_3 ? data.client_gst_conf_3 : 0],
      client_cgst_3: [this.invoiceMode && this.invoiceMode=='save'?0:data.client_cgst_3 ? data.client_cgst_3 : 0],
      client_sgst_3: [this.invoiceMode && this.invoiceMode=='save'?0:data.client_sgst_3 ? data.client_sgst_3 : 0],
      client_igst_3: [this.invoiceMode && this.invoiceMode=='save'?0:data.client_igst_3 ? data.client_igst_3 : 0],
      hsn: [data.hsn? data.hsn:hsn],
      hsn_2: [data.hsn_2? data.hsn_2:''],
      hsn_3: [data.hsn_3? data.hsn_3:''],

      
      client_travel_insurance : [data.client_travel_insurance ? data.client_travel_insurance : 0],
      client_meal_charges : [data.client_meal_charges ? data.client_meal_charges : 0],
      client_toll_charges : [data.client_toll_charges ? data.client_toll_charges : 0],
      client_parking_charges : [data.client_parking_charges ? data.client_parking_charges : 0],
      client_permit_charges : [data.client_permit_charges ? data.client_permit_charges : 0],
      client_xxl_charges: [data.client_xxl_charges ? data.client_xxl_charges : 0],
      client_raf_charges: [data.client_raf_charges ? data.client_raf_charges : 0],

      client_net_amount: [this.invoiceMode && this.invoiceMode=='save'?0:data.client_net_amount ? data.client_net_amount : 0],

      service_started : [data.service_started ? moment(data.service_started, 'YYYY-MM-DD HH:mm:ssZ').format('YYYY-MM-DD') : ''],
      service_ended : [data.service_ended ? moment(data.service_ended, 'YYYY-MM-DD HH:mm:ssZ').format('YYYY-MM-DD')  : ''],

      pay_type: [data.pay_type ? data.pay_type : ""],
      ledger_card_no: [data.ledger_card_no ? data.ledger_card_no : ""],
      ledger_card_name: [data.ledger_card_name ? data.ledger_card_name : ""],
      e_code: [data.e_code ? data.e_code : ""],
      cost_center: [data.cost_center ? data.cost_center : ""],
      payable_transaction_code: [data.payable_transaction_code ? data.payable_transaction_code : ""],
      payable_date: [data.payable_date ? moment(data.payable_date, 'YYYY-MM-DD HH:mm:ssZ').format('YYYY-MM-DD'): ""],
      payble_code: [data.payble_code ? data.payble_code : ""],
      payble_code_name: [data.payble_code_name ? data.payble_code_name : ""],

      coach: data.coach ? data.coach : '',
      seat_no: data.seat_no ? data.seat_no : '',
      berth: data.berth ? data.berth : '',
      supplier_vat_type: [data.supplier_vat_type ? data.supplier_vat_type : "S"],
      supplier_vat_conf: [data.supplier_vat_conf ? data.supplier_vat_conf : 0],
      supplier_vat_amount: [data.supplier_vat_amount ? data.supplier_vat_amount : 0],
      client_vat_type: [data.client_vat_type ? data.client_vat_type : "S"],
      client_vat_conf: [data.client_vat_conf ? data.client_vat_conf : 0],
      client_vat_amount: [data.client_vat_amount ? data.client_vat_amount : 0]
    });
  }


  initialiseFormData(data,invoiceData){
    this.invoiceForm = this.fb.group({
      invoice_type: [data.invoice_type ? data.invoice_type : "hotel",Validators.required],
      transaction_method: [data.transaction_method ? data.transaction_method : "sales",Validators.required],
      client_account_type: [data.client_account_type ? data.client_account_type : "client",Validators.required],
      client_code: [data.client_code ? data.client_code : "",Validators.required],
      client_code_name: [data.client_code_name ? data.client_code_name : "",Validators.required],
      general_ledger_code: [data.general_ledger_code ? data.general_ledger_code : "",Validators.required],
      general_ledger_code_name: [data.general_ledger_code_name ? data.general_ledger_code_name : "",Validators.required],
      supplier_account_type: [data.supplier_account_type ? data.supplier_account_type : "supplier"],
      supplier_code: [data.supplier_code ? data.supplier_code : ""],
      supplier_code_name: [data.supplier_code_name ? data.supplier_code_name : ""],
      subledger_type: [data.subledger_type ? data.subledger_type : "client"],
      subledger_code: [data.subledger_code ? data.subledger_code : ""],
      subledger_code_name: [data.subledger_code_name ? data.subledger_code_name : ""],
      invoice_date: [data.invoice_date ? moment(data.invoice_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')],
      invoice_number: [data.invoice_number ? data.invoice_number : ""],
      crn_against:[invoiceData.crn_against?invoiceData.crn_against:''],
      type: [data.type ? data.type : ""],

      service_date: [data.service_date ? moment(data.service_date).format('YYYY-MM-DD') : ""],
      supplier_transaction_number: [data.supplier_transaction_number ? data.supplier_transaction_number : ""],

      hotel: [data.hotel ? data.hotel : ""],
      hotel_name: [data.hotel_name ? data.hotel_name : ""],
      reference_number: [data.reference_number ? data.reference_number : ""],
      employee: [data.employee ? data.employee : ""],
      employee_name: [data.employee_name ? data.employee_name : ""],
      number_of_pax: [data.number_of_pax ? data.number_of_pax : 1],
      is_p2p: [invoiceData.is_p2p ? invoiceData.is_p2p : false],
      narration_1: [invoiceData.narration_1 ? invoiceData.narration_1 : "",Validators.pattern('^[^\,\"\(\)\']*$')],
      narration_2: [invoiceData.narration_2 ? invoiceData.narration_2 : "",Validators.pattern('^[^\,\"\(\)\']*$')],
      narration_3: [invoiceData.narration_3 ? invoiceData.narration_3 : "",Validators.pattern('^[^\,\"\(\)\']*$')],
      narration_4: [invoiceData.narration_4 ? invoiceData.narration_4 : "",Validators.pattern('^[^\,\"\(\)\']*$')],
      narration_5: [invoiceData.narration_5 ? invoiceData.narration_5 : "",Validators.pattern('^[^\,\"\(\)\']*$')],
      skip_v3_entry : [false],
      reference_invoice_number : [''],
      remark: [invoiceData.remark ? invoiceData.remark : ''],
     
      sector: [data.sector ? data.sector : ''],
      train_no: [data.train_no ? data.train_no : ''],
      class: [data.class ? data.class : ''],
      departure_date: [data.departure_date ? moment(data.departure_date).format('YYYY-MM-DD') : ''],
      arrival_date: [data.arrival_date ? moment(data.arrival_date).format('YYYY-MM-DD') : ''],
      departure_station: [data.departure_station ? data.departure_station : ''],
      arrival_station: [data.arrival_station ? data.arrival_station : ''],
      train_name: [data.train_name ? data.train_name : ''],
      car_name: [data.car_name ? data.car_name : ''],
      pnr: [data.pnr ? data.pnr : ''],
      ticket_no: [data.ticket_no ? data.ticket_no : ''],
      boarding_point: [data.boarding_point ? data.boarding_point : ''],
      arrival_point : [data.arrival_point  ? data.arrival_point  : ''],
      journey_type: [data.journey_type ? data.journey_type : ''],
      journey_distance: [data.journey_distance ? data.journey_distance : ''],
      status: [invoiceData.status? invoiceData.status:"Active"],
      tcs_rate : [invoiceData.tcs_rate ? Number(invoiceData.tcs_rate) : ''],
      tcs_amount : [invoiceData.tcs_amount ? Number(invoiceData.tcs_amount) : ''],
      purchase_currency: [invoiceData.purchase_currency ? invoiceData.purchase_currency : ''],
      purchase_currency_rate: [invoiceData.purchase_currency_rate ? Number(invoiceData.purchase_currency_rate) : 1],
      sales_currency: [invoiceData.sales_currency ? invoiceData.sales_currency  : ''],
      sales_currency_rate: [invoiceData.sales_currency_rate ? Number(invoiceData.sales_currency_rate) : null],
      sales_currency_amount: [invoiceData.sales_currency_amount ? Number(invoiceData.sales_currency_amount) : ''],

      nonRefundable : [invoiceData.non_refundable_to_client ? invoiceData.non_refundable_to_client : false],
      hotel_address: [data.hotel_address ? data.hotel_address :''],
      passport_no: [data.passport_no ? data.passport_no :''],
      booking_plan: [data.booking_plan ? data.booking_plan :''],
      room_rent_per_day: [data.room_rent_per_day ? data.room_rent_per_day :''],
      inclusions: [data.inclusions ? data.inclusions :''],
      exclusions: [data.exclusions ? data.exclusions :''],
      confirmed_by: [data.confirmed_by ? data.confirmed_by :''],
      issued_by: [data.issued_by ? data.issued_by :''],
      payment_mode: [data.payment_mode ? data.payment_mode :''],
      confirmation_no: [data.confirmation_no ? data.confirmation_no :''],
      hotel_tel_no: [data.hotel_tel_no ? data.hotel_tel_no :''],
      adults: [data.adults ? data.adults :''],
      childs: [data.childs ? data.childs :''],

      bill_of_supply: [invoiceData.bill_of_supply ? invoiceData.bill_of_supply : false],
      is_consolidate:  [invoiceData.is_consolidate ? invoiceData.is_consolidate : false],
      tcs_on : [invoiceData.tcs_on ? invoiceData.tcs_on : 'total'],
    });

    return true
  }

  async  bindInvoiceData(invoiceData: any,invoiceMode:string) {
    this.invoiceMode = invoiceMode
    let res = await this.initialiseFormData(invoiceData.products[0],invoiceData)
    if(invoiceMode=='save'){
      this.invoiceForm.controls.invoice_date.setValue(moment().format('YYYY-MM-DD'));
      let invCode  = invoiceData.invoice_number
      //this.invoiceForm.controls.reference_invoice_number.setValue(invoiceData.invoice_number ? invoiceData.invoice_number : "");
      this.invoiceForm.controls.transaction_method.setValue(invoiceMode=='save'?'purchase':invoiceData.transaction_method?invoiceData.transaction_method:'');
      // this.invoiceForm.controls.invoice_type.setValue(invoiceMode=='save'?'purchase':invoiceData.invoice_type?invoiceData.invoice_type:'');
      this.invoiceForm.controls.invoice_date.setValue("");
      this.invoiceForm.controls.crn_against.setValue(invoiceData.invoice_number ? invoiceData.invoice_number : "");

    }else{
      this.invoiceForm.controls.invoice_date.setValue(invoiceData.invoice_date ? moment(invoiceData.invoice_date).format('YYYY-MM-DD') : "");
      this.invoiceForm.controls.invoice_number.setValue(invoiceData.invoice_number ? invoiceData.invoice_number : "");
      
    }
    if(invoiceData.reference_invoice_number){
      this.invoiceForm.controls.reference_invoice_number.setValue(invoiceData.reference_invoice_number ? invoiceData.reference_invoice_number : "");
      this.invoiceForm.controls.skip_v3_entry.setValue(invoiceData.reference_invoice_number ? true : false);
    }
    this.travellerList = this.travellerForm.get("travellerList");
		  this.travellerList = this.travellerForm.get("travellerList") as FormArray;
      this.travellerList.controls = []
    for (const iterator of invoiceData.products) {
      this.addTraveller(iterator)
    }
    let obj = {
      invoice_no: invoiceData.invoice_number ? invoiceData.invoice_number : 'New Invoice',
    };
    this.fareList[0] = obj;
    let calcResp = await this.calculateFromApi(0)
  }
 
  calculateFromApi(i){
    
    let calculationObj = this.createMsProduct(this.invoiceForm.value,this.travellerForm.value)
    this.postMethod(`invoice/miscellaneous-fare`,calculationObj).subscribe(async (res)=> {
      this.totalFareModel[i] = new MsFareSummaryModel(res);
      if(this.totalFareModel[i] && this.totalFareModel[i].client_net_amount){
        
        let sales_currency = this.invoiceForm.controls.sales_currency.value;
        let sales_currency_rate = this.invoiceForm.controls.sales_currency_rate.value;
        if(sales_currency && sales_currency_rate) {
          let client_net_amount =  this.totalFareModel[i].client_net_amount;
          let grandTotal = Number(client_net_amount)
          this.invoiceForm.controls.sales_currency_amount.setValue((grandTotal * sales_currency_rate).toFixed(2));
        } else {
          this.invoiceForm.controls.sales_currency_amount.setValue('')
        }
        
        
        let tcs_rate_value = this.invoiceForm.controls.tcs_rate.value;
        let tcs_on_value = this.invoiceForm.controls.tcs_on.value
        if(Number(tcs_rate_value)>0 && tcs_on_value) {
          let tcsAmount = (Number(tcs_rate_value) * Number(tcs_on_value=='basic'?Number(this.totalFareModel[i].client_basic)+Number(this.travellerForm.value.travellerList.reduce((acc,curr)=>acc+ Number(curr.client_sc2_type=="B"?curr.client_sc2_amount:0)+ Number(curr.client_sc3_type=="B"?curr.client_sc3_amount:0),0)):this.totalFareModel[i].client_net_amount))/ 100
          let roundedAmount = Math.round(tcsAmount)
          if(this.invoiceForm.value.invoice_type=='hotel'){
          this.invoiceForm.controls.tcs_amount.setValue(roundedAmount)
          }
        }else {
          this.invoiceForm.controls.tcs_amount.setValue(0)
        }
      }
    },err=> {

    })
  }

  createMsProduct(data,travellers) {
    let products = []
    let gst = 0
    let netAmount = 0
    let hsn = this.getHSN(data.invoice_type)
    for (const traveller of travellers.travellerList) {
      const billOfSupplyValue = data.bill_of_supply ? data.bill_of_supply : false;
      
      const igstAmount = Number(billOfSupplyValue !== true && traveller.client_igst ? traveller.client_igst : 0)
      const cgstAmount = Number(billOfSupplyValue !== true && traveller.client_cgst ? traveller.client_cgst : 0)
      const sgstAmount = Number(billOfSupplyValue !== true && traveller.client_sgst ? traveller.client_sgst : 0)
      const serviceChargeAmount = Number(billOfSupplyValue !== true && traveller.client_sc1_amount ? traveller.client_sc1_amount : 0)

      const totalClientAmount =  billOfSupplyValue !== true ? 
              Number(traveller.client_net_amount) : 
              ( Number(traveller.client_net_amount) - Number(traveller.client_sc1_amount) - 
                Number(traveller.client_igst) - Number(traveller.client_cgst) - Number(traveller.client_sgst) )

      let productObj =  {
        invoice_type: data.invoice_type ? data.invoice_type : "hotel",
        transaction_method: data.transaction_method ? data.transaction_method : "sales",
  
        client_account_type: data.client_account_type ? data.client_account_type : "client",
        client_code: data.client_code ? data.client_code : "",
        client_code_name: data.client_code_name ? data.client_code_name.name ? data.client_code_name.name : data.client_code_name : "",
        
        general_ledger_code: data.general_ledger_code ? data.general_ledger_code : "",
        general_ledger_code_name: data.general_ledger_code_name ? data.general_ledger_code_name.name ? data.general_ledger_code_name.name : data.general_ledger_code_name : "",
  
        supplier_account_type: data.supplier_account_type ? data.supplier_account_type : "supplier",
        supplier_code: data.supplier_code ? data.supplier_code : "",
        supplier_code_name: data.supplier_code_name ? data.supplier_code_name.name ? data.supplier_code_name.name : data.supplier_code_name : "",
  
        subledger_type: data.subledger_type ? data.subledger_type : "client",
        subledger_code: data.subledger_code ? data.subledger_code : "",
        subledger_code_name: data.subledger_code_name ? data.subledger_code_name.name ? data.subledger_code_name.name : data.subledger_code_name : "",
  
        type: data.type ? data.type : "",

  
        invoice_date: data.invoice_date ? moment(data.invoice_date).format('YYYY-MM-DDTHH:MM') : "",
        invoice_number: data.invoice_number ? data.invoice_number : "",
        crn_against: data.crn_against ? data.crn_against:'',
  
        service_date: data.service_date ? moment(data.service_date).format('YYYY-MM-DDTHH:MM') : "",
        supplier_transaction_number: data.supplier_transaction_number ? data.supplier_transaction_number : "",
       
        sector: data.sector ? data.sector : "",
        train_no: data.train_no ? data.train_no : "",
        class: data.class ? data.class : "",
        departure_date: data.departure_date ? moment(data.departure_date).format('YYYY-MM-DDTHH:MM') : "",
        arrival_date: data.arrival_date ? moment(data.arrival_date).format('YYYY-MM-DDTHH:MM') : "",
        departure_station: data.departure_station ? data.departure_station : "",
        arrival_station: data.arrival_station ? data.arrival_station : "",
        train_name: data.train_name ? data.train_name : "",
        car_name: data.car_name ? data.car_name : "",
        pnr: data.pnr ? data.pnr : "",
        ticket_no: data.ticket_no ? data.ticket_no : "",

        boarding_point: data.boarding_point ? data.boarding_point : '',
        arrival_point: data.arrival_point ? data.arrival_point : '',
        journey_type: data.journey_type ? data.journey_type : '',
        journey_distance: data.journey_distance ? data.journey_distance : '',
        
        employee: data.employee ? data.employee : "",
        employee_name: data.employee_name ? data.employee_name.name ? data.employee_name.name : data.employee_name : "",
  
        number_of_pax: data.number_of_pax ? data.number_of_pax : "",
        narration: data.narration_1 ? data.narration_1 : "",

        hotel: data.hotel ? data.hotel : "",
        hotel_name: data.hotel_name ? data.hotel_name.name ? data.hotel_name.name : data.hotel_name : "",


        room_name: traveller.room_name ? traveller.room_name : "",
        lead_pax_name: traveller.lead_pax_name ? traveller.lead_pax_name.account_name?traveller.lead_pax_name.account_name:traveller.lead_pax_name : "",
        reference_number: traveller.reference_number ? traveller.reference_number : "",
       
        service_started: traveller.service_started ? moment(traveller.service_started).format('YYYY-MM-DD') : "",
        service_ended : traveller.service_ended ? moment(traveller.service_ended).format('YYYY-MM-DD') : "",
  
        supplier_non_account: traveller.supplier_non_account ? traveller.supplier_non_account : "",
        supplier_basic: traveller.supplier_basic ? traveller.supplier_basic : "",
        supplier_taxes: traveller.supplier_taxes ? traveller.supplier_taxes : "",
        supplier_commission_type: traveller.supplier_commission_type ? traveller.supplier_commission_type : "RB",
        supplier_commission_value: traveller.supplier_commission_value ? traveller.supplier_commission_value : "",
        supplier_commission_amount: traveller.supplier_commission_amount ? traveller.supplier_commission_amount : "",
        supplier_discount_type: traveller.supplier_discount_type ? traveller.supplier_discount_type : "VL",
        supplier_discount_amount: traveller.supplier_discount_amount ? traveller.supplier_discount_amount : "",
        supplier_tds: traveller.supplier_tds ? traveller.supplier_tds : "",
        supplier_tds_type: traveller.supplier_tds_type ? traveller.supplier_tds_type : "",
        supplier_tds_conf: traveller.supplier_tds_conf ? traveller.supplier_tds_conf : "",
  
        supplier_sc1_type: traveller.supplier_sc1_type ? traveller.supplier_sc1_type : "T",
        supplier_sc1_amount: traveller.supplier_sc1_amount ? traveller.supplier_sc1_amount : "",
        supplier_sc2_type: traveller.supplier_sc2_type ? traveller.supplier_sc2_type : "B",
        supplier_sc2_taxable: traveller.supplier_sc2_taxable ? traveller.supplier_sc2_taxable : "N",
        supplier_sc2_amount: traveller.supplier_sc2_amount ? traveller.supplier_sc2_amount : "",
        supplier_sc3_type: traveller.supplier_sc3_type ? traveller.supplier_sc3_type : "OT",
        supplier_sc3_taxable: traveller.supplier_sc3_taxable ? traveller.supplier_sc3_taxable : "N",
        supplier_sc3_amount: traveller.supplier_sc3_amount ? traveller.supplier_sc3_amount : "",
        
        supplier_gst_type: traveller.supplier_gst_type ? traveller.supplier_gst_type : "S",
        supplier_gst_conf: traveller.supplier_gst_conf ? traveller.supplier_gst_conf : "",
        supplier_cgst: traveller.supplier_cgst ? traveller.supplier_cgst : "",
        supplier_sgst: traveller.supplier_sgst ? traveller.supplier_sgst : "",
        supplier_igst: traveller.supplier_igst ? traveller.supplier_igst : "",

        supplier_gst_type_2: traveller.supplier_gst_type_2 ? traveller.supplier_gst_type_2 : "B",
        supplier_gst_conf_2: traveller.supplier_gst_conf_2 ? traveller.supplier_gst_conf_2 : "",
        supplier_cgst_2: traveller.supplier_cgst_2 ? traveller.supplier_cgst_2 : "",
        supplier_sgst_2: traveller.supplier_sgst_2 ? traveller.supplier_sgst_2 : "",
        supplier_igst_2: traveller.supplier_igst_2 ? traveller.supplier_igst_2 : "",

        supplier_gst_type_3: traveller.supplier_gst_type_3 ? traveller.supplier_gst_type_3 : "T",
        supplier_gst_conf_3: traveller.supplier_gst_conf_3 ? traveller.supplier_gst_conf_3 : "",
        supplier_cgst_3: traveller.supplier_cgst_3 ? traveller.supplier_cgst_3 : "",
        supplier_sgst_3: traveller.supplier_sgst_3 ? traveller.supplier_sgst_3 : "",
        supplier_igst_3: traveller.supplier_igst_3 ? traveller.supplier_igst_3 : "",

        supplier_raf_charges: traveller.supplier_raf_charges ? traveller.supplier_raf_charges : "0",
        supplier_igst_on_raf: traveller.supplier_igst_on_raf ? traveller.supplier_igst_on_raf : "",
        supplier_cgst_on_raf: traveller.supplier_cgst_on_raf ? traveller.supplier_cgst_on_raf : "",
        supplier_sgst_on_raf: traveller.supplier_sgst_on_raf ? traveller.supplier_sgst_on_raf : "",
        supplier_xxl_charges : traveller.supplier_xxl_charges ? traveller.supplier_xxl_charges : 0,  

        supplier_travel_insurance : traveller.supplier_travel_insurance ? traveller.supplier_travel_insurance : 0,
        supplier_meal_charges : traveller.supplier_meal_charges ? traveller.supplier_meal_charges : 0,  
        supplier_permit_charges : traveller.supplier_permit_charges ? traveller.supplier_permit_charges : 0, 
        supplier_parking_charges : traveller.supplier_parking_charges ? traveller.supplier_parking_charges : 0, 
        supplier_toll_charges : traveller.supplier_toll_charges ? traveller.supplier_toll_charges : 0, 

        supplier_net_amount: traveller.supplier_net_amount ? traveller.supplier_net_amount : "",
  
        client_non_account: traveller.client_non_account ? traveller.client_non_account : "",
        client_basic: traveller.client_basic ? traveller.client_basic : "",
        client_taxes: traveller.client_taxes ? traveller.client_taxes : "",
        client_commission_type: traveller.client_commission_type ? traveller.client_commission_type : "RB",
        client_commission_value: traveller.client_commission_value ? traveller.client_commission_value : "",
        client_commission_amount: traveller.client_commission_amount ? traveller.client_commission_amount : "",
        client_discount_type: traveller.client_discount_type ? traveller.client_discount_type : "RB",
        client_discount_amount: traveller.client_discount_amount ? traveller.client_discount_amount : "",
        client_tds: traveller.client_tds ? traveller.client_tds : "",
        client_tds_type: traveller.client_tds_type ? traveller.client_tds_type : "",
        client_tds_conf: traveller.client_tds_conf ? traveller.client_tds_conf : "",

        client_sc1_type: traveller.client_sc1_type ? traveller.client_sc1_type : "T",
        client_sc1_amount: serviceChargeAmount ? serviceChargeAmount : "",  
        client_sc2_type: traveller.client_sc2_type ? traveller.client_sc2_type : "B",
        client_sc2_taxable: traveller.client_sc2_taxable ? traveller.client_sc2_taxable : "N",
        client_sc2_amount: traveller.client_sc2_amount ? traveller.client_sc2_amount : "",
        client_sc3_type: traveller.client_sc3_type ? traveller.client_sc3_type : "OT",
        client_sc3_taxable: traveller.client_sc3_taxable ? traveller.client_sc3_taxable : "N",
        client_sc3_amount: traveller.client_sc3_amount ? traveller.client_sc3_amount : "",
  
        client_gst_type: traveller.client_gst_type ? traveller.client_gst_type : "S",
        client_gst_conf: traveller.client_gst_conf ? traveller.client_gst_conf : "",
        client_cgst: cgstAmount ? cgstAmount : "",
        client_sgst: sgstAmount ? sgstAmount : "",
        client_igst: igstAmount ? igstAmount : "",

        client_gst_type_2: traveller.client_gst_type_2 ? traveller.client_gst_type_2 : "B",
        client_gst_conf_2: traveller.client_gst_conf_2 ? traveller.client_gst_conf_2 : "",
        client_cgst_2: traveller.client_cgst_2 ? traveller.client_cgst_2 : "",
        client_sgst_2: traveller.client_sgst_2 ? traveller.client_sgst_2 : "",
        client_igst_2: traveller.client_igst_2 ? traveller.client_igst_2 : "",

        client_gst_type_3: traveller.client_gst_type_3 ? traveller.client_gst_type_3 : "T",
        client_gst_conf_3: traveller.client_gst_conf_3 ? traveller.client_gst_conf_3 : "",
        client_cgst_3: traveller.client_cgst_3 ? traveller.client_cgst_3 : "",
        client_sgst_3: traveller.client_sgst_3 ? traveller.client_sgst_3 : "",
        client_igst_3: traveller.client_igst_3 ? traveller.client_igst_3 : "",

        client_raf_charges: traveller.client_raf_charges ? traveller.client_raf_charges : "0",
        client_igst_on_raf: traveller.client_igst_on_raf ? traveller.client_igst_on_raf : "0",
        client_cgst_on_raf: traveller.client_cgst_on_raf ? traveller.client_cgst_on_raf : "0",
        client_sgst_on_raf: traveller.client_sgst_on_raf ? traveller.client_sgst_on_raf : "0",
        client_xxl_charges : traveller.client_xxl_charges ? traveller.client_xxl_charges : "0",  

        client_travel_insurance : traveller.client_travel_insurance ? traveller.client_travel_insurance : 0,
        client_meal_charges : traveller.client_meal_charges ? traveller.client_meal_charges : 0,  
        client_toll_charges : traveller.client_toll_charges ? traveller.client_toll_charges : 0,  
        client_parking_charges : traveller.client_parking_charges ? traveller.client_parking_charges : 0,  
        client_permit_charges : traveller.client_permit_charges ? traveller.client_permit_charges : 0,  

        client_net_amount: totalClientAmount ? totalClientAmount : "",
        
        hsn : traveller.hsn?traveller.hsn:hsn,
        hsn_2 : traveller.hsn_2?traveller.hsn_2:'',
        hsn_3 : traveller.hsn_3?traveller.hsn_3:'',
        pay_type: traveller.pay_type ? traveller.pay_type : "",
        ledger_card_no: traveller.ledger_card_no ? traveller.ledger_card_no : "",
        ledger_card_name: traveller.ledger_card_name ? traveller.ledger_card_name.name ? traveller.ledger_card_name.name : traveller.ledger_card_name : "",
        e_code: traveller.e_code ? traveller.e_code.account_code ? traveller.e_code.account_code : traveller.e_code : "",
        cost_center: traveller.cost_center ? traveller.cost_center.cost_center ? traveller.cost_center.cost_center : traveller.cost_center : "",
        payable_transaction_code: traveller.payable_transaction_code ? traveller.payable_transaction_code : "",
        payable_date: traveller.payable_date? moment(traveller.payable_date).format('YYYY-MM-DD'): "",
        payble_code: traveller.payble_code ? traveller.payble_code : "",
        payble_code_name: traveller.payble_code_name ? traveller.payble_code_name.name ? traveller.payble_code_name.name : traveller.payble_code_name : "",
        reference_invoice_number : data.reference_invoice_number ? data.reference_invoice_number : "",
        skip_v3_entry : data.skip_v3_entry,

        coach: traveller.coach ? traveller.coach : '',
        seat_no: traveller.seat_no ? traveller.seat_no : '',
        berth: traveller.berth ? traveller.berth : '',
        status: data.status? data.status:"Active",
        supplier_vat_type: traveller.supplier_vat_type ? traveller.supplier_vat_type : "",
        supplier_vat_conf: traveller.supplier_vat_conf ? traveller.supplier_vat_conf : 0,
        supplier_vat_amount: traveller.supplier_vat_amount ? traveller.supplier_vat_amount : 0,
        supplier_vat_on_raf: traveller.supplier_vat_on_raf ? traveller.supplier_vat_on_raf : 0,

        client_vat_type: traveller.client_vat_type ? traveller.client_vat_type : "",
        client_vat_conf: traveller.client_vat_conf ? traveller.client_vat_conf : 0,
        client_vat_amount: traveller.client_vat_amount ? traveller.client_vat_amount : 0,
        hotel_address: data.hotel_address ? data.hotel_address :'',
        passport_no: data.passport_no ? data.passport_no :'',
        booking_plan: data.booking_plan ? data.booking_plan :'',
        room_rent_per_day: data.room_rent_per_day ? data.room_rent_per_day :'',
        inclusions: data.inclusions ? data.inclusions :'',
        exclusions: data.exclusions ? data.exclusions :'',
        confirmed_by: data.confirmed_by ? data.confirmed_by :'',
        issued_by: data.issued_by ? data.issued_by :'',
        payment_mode: data.payment_mode ? data.payment_mode :'',
        confirmation_no: data.confirmation_no ? data.confirmation_no :'',
        hotel_tel_no: data.hotel_tel_no ? data.hotel_tel_no :'',
        adults: data.adults ? data.adults :'',
        childs: data.childs ? data.childs :'',

        is_p2p: data.is_p2p ? data.is_p2p : false,

        client_vat_on_raf: traveller.client_vat_on_raf ? traveller.client_vat_on_raf : 0
      };
      gst += Number(igstAmount) + Number(cgstAmount) + Number(sgstAmount)+Number(traveller.client_igst_2) + Number(traveller.client_cgst_2) + Number(traveller.client_sgst_2)+Number(traveller.client_igst_3) + Number(traveller.client_cgst_3) + Number(traveller.client_sgst_3)
      netAmount += Number(totalClientAmount)
      products.push(productObj)
    }
  let totalAmount = Number(data.tcs_amount) + Number(netAmount)

  let invoioceObj = {
    "affiliate_code": data.client_code,
    "name": data.client_code_name ? data.client_code_name.name ? data.client_code_name.name : data.client_code_name : "",
    "billaddress": "",
    "invoice_uuid": data.invoice_number,
    "crn_against":data.crn_against,
    "invoice_date": data.invoice_date,
    "created_by": data.employee,
    "updated_by": data.employee,
    "year_id": 1,
    "tenant_id": 1,
    "narration_1": data.narration_1,
    "narration_2": data.narration_2,
    "narration_3": data.narration_3,
    "narration_4": data.narration_4,
    "narration_5": data.narration_5,
    "remark": data.remark,
    "tcs_rate": data.tcs_rate ? data.tcs_rate : null,
    "tcs_amount": data.tcs_amount ? data.tcs_amount : null,
    "total": totalAmount ? Math.round(totalAmount) : null,
    "balance_due": 0,
    "gst_total": gst,
    "products": products,
    "reference_invoice_number": products[0].reference_invoice_number,
    "product_type": data.invoice_type == "non_account"  ? "gst" :"miscellaneous",
    "status": data.status? data.status:"Active",
    "sales_currency": data.sales_currency ? data.sales_currency : null,
    "sales_currency_rate" : data.sales_currency_rate ? data.sales_currency_rate : null,
    "sales_currency_amount" : data.sales_currency_amount ? data.sales_currency_amount : null,
    "purchase_currency": data.purchase_currency ? data.purchase_currency : null,
    "purchase_currency_rate" : data.purchase_currency_rate ? data.purchase_currency_rate : null,
    "nonRefundable" : data.nonRefundable ? data.nonRefundable : false,
    "bill_of_supply" : data.bill_of_supply ? data.bill_of_supply : false,
    "is_p2p": data.is_p2p ? data.is_p2p : false,
    "is_consolidate":  data.is_consolidate ? data.is_consolidate : false,
    "tcs_on": data.tcs_on ? data.tcs_on : 'total',

  }
  
  return invoioceObj;

  }
  getHSN(invoice_type: any) {
    let SAC_CODES = {
        "non_account" : 996425,
        "flight" : 998551,
        "taxi" : 998551,
        "hotel" : 998551,
        "visa" : 998551,
        "insurance" : 998559,
        "other" : 998559,
        "car" : 998551,
        "bus" : 998551,
        "rail" : 998551
    }
    if(SAC_CODES[invoice_type]){
      return SAC_CODES[invoice_type]
    }else{
      return 999799
    }
  }

  postMethod(url, data) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.httpClient
      .post(`${environment.okountUrl}/${url}`, data, { headers: headers })
      .pipe(map((res) => {
          return res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
  }
  putMethod(url, data) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.httpClient
      .put(`${environment.okountUrl}/${url}`, data, { headers: headers })
      .pipe(map((res) => {
          return res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
  }
  deleteMethod(url) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.httpClient
      .delete(`${environment.okountUrl}/${url}`, { headers: headers })
      .pipe(map((res) => {
          return res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
  }
  getMethod(url) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.httpClient
      .get(`${environment.okountUrl}/${url}`, { headers: headers })
      .pipe(map((res) => {
          return res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
  }

  calculateCommission(
    base,
    tax,
    commType,
    commFor,
    commRate,
    tdsType,
    tdsRate,
    roundOffType = "NORMAL_ROUND_OFF",
    i
  ) {
    var commAmount = 0.0;
    base = Number(base);
    tax = Number(tax);
    commRate = Number(commRate)
    tdsRate = Number(tdsRate)
    if (commType.toUpperCase() == "VL" || commType.toUpperCase() == "VLN") {
      commAmount = commRate;
    } else if (commType.toUpperCase() == "RB") {
      commAmount = (base * commRate) / 100;
      base = base - commAmount;
    } else if (commType.toUpperCase() == "RT") {
      commAmount = ((base + tax) * commRate) / 100;
    }

    // NORMAL_ROUND_OFF :- roundoff to normal value
    // LOWER_ROUND_OFF : round to lower value
    // if (roundOffType && roundOffType.toUpperCase() == "NORMAL_ROUND_OFF") {
    //   commAmount = Math.round(commAmount);
    // }
    // if (roundOffType && roundOffType.toUpperCase() == "LOWER_ROUND_OFF") {
    //   commAmount = Number(String(commAmount).split(".")[0]);
    // }

    const gstOnCommission = (commAmount * 18) / 100;
    // commAmount = commAmount - (commAmount * 18) / 100;
    var totalTds = (commAmount * tdsRate) / 100;
    if(tdsType == 'B'){
      totalTds = (base * tdsRate) / 100;
    }

    if (commFor == "CLIENT") {
      this.travellerForm.controls.travellerList.controls[i].controls.client_commission_amount.setValue(commAmount.toFixed(2));
      this.travellerForm.controls.travellerList.controls[i].controls.client_tds.setValue(totalTds.toFixed(2));
      
    } else if (commFor == "SUPPLIER") {
      this.travellerForm.controls.travellerList.controls[i].controls.supplier_commission_amount.setValue(commAmount.toFixed(2));
      this.travellerForm.controls.travellerList.controls[i].controls.supplier_tds.setValue(totalTds.toFixed(2));
    }
    return null
  }

  roundOffAmount(amount, type = "NORMAL_ROUND_OFF") {
    if (type && type.toUpperCase() == "NORMAL_ROUND_OFF") {
      amount = Math.round(amount);
    }
    if (type && type.toUpperCase() == "LOWER_ROUND_OFF") {
      amount = Number(String(amount).split(".")[0]);
    }
    return amount;
  }  

  createMsProductForBillSupply(data,travellers) {
    let products = []
    let gst = 0
    let netAmount = 0
    let hsn = this.getHSN(data.invoice_type)
    for (const traveller of travellers.travellerList) {

      const igstAmount = Number(traveller.client_igst ? traveller.client_igst : 0)
      const cgstAmount = Number(traveller.client_cgst ? traveller.client_cgst : 0)
      const sgstAmount = Number(traveller.client_sgst ? traveller.client_sgst : 0)
      const serviceChargeAmount = Number(traveller.client_sc1_amount ? traveller.client_sc1_amount : 0)

      const gstAmount = igstAmount + cgstAmount + sgstAmount;
      const totalClientAmount = serviceChargeAmount + gstAmount;

      let productObj =  {
        invoice_type: data.invoice_type ? data.invoice_type : "hotel",
        transaction_method: data.transaction_method ? data.transaction_method : "sales",
  
        client_account_type: data.client_account_type ? data.client_account_type : "client",
        client_code: data.client_code ? data.client_code : "",
        client_code_name: data.client_code_name ? data.client_code_name.name ? data.client_code_name.name : data.client_code_name : "",
        
        general_ledger_code: data.general_ledger_code ? data.general_ledger_code : "",
        general_ledger_code_name: data.general_ledger_code_name ? data.general_ledger_code_name.name ? data.general_ledger_code_name.name : data.general_ledger_code_name : "",
  
        supplier_account_type: data.supplier_account_type ? data.supplier_account_type : "supplier",
        supplier_code: data.supplier_code ? data.supplier_code : "",
        supplier_code_name: data.supplier_code_name ? data.supplier_code_name.name ? data.supplier_code_name.name : data.supplier_code_name : "",
  
        subledger_type: data.subledger_type ? data.subledger_type : "client",
        subledger_code: data.subledger_code ? data.subledger_code : "",
        subledger_code_name: data.subledger_code_name ? data.subledger_code_name.name ? data.subledger_code_name.name : data.subledger_code_name : "",
  
        type: data.type ? data.type : "",

  
        invoice_date: data.invoice_date ? moment(data.invoice_date).format('YYYY-MM-DDTHH:MM') : "",
        invoice_number: data.invoice_number ? data.invoice_number : "",
        crn_against: data.crn_against ? data.crn_against : '',
  
        service_date: data.service_date ? moment(data.service_date).format('YYYY-MM-DDTHH:MM') : "",
        supplier_transaction_number: data.supplier_transaction_number ? data.supplier_transaction_number : "",
       
        sector: data.sector ? data.sector : "",
        train_no: data.train_no ? data.train_no : "",
        class: data.class ? data.class : "",
        departure_date: data.departure_date ? moment(data.departure_date).format('YYYY-MM-DDTHH:MM') : "",
        arrival_date: data.arrival_date ? moment(data.arrival_date).format('YYYY-MM-DDTHH:MM') : "",
        departure_station: data.departure_station ? data.departure_station : "",
        arrival_station: data.arrival_station ? data.arrival_station : "",
        train_name: data.train_name ? data.train_name : "",
        car_name: data.car_name ? data.car_name : "",
        pnr: data.pnr ? data.pnr : "",
        ticket_no: data.ticket_no ? data.ticket_no : "",

        boarding_point: data.boarding_point ? data.boarding_point : '',
        arrival_point: data.arrival_point ? data.arrival_point : '',
        journey_type: data.journey_type ? data.journey_type : '',
        journey_distance: data.journey_distance ? data.journey_distance : '',
        
        employee: data.employee ? data.employee : "",
        employee_name: data.employee_name ? data.employee_name.name ? data.employee_name.name : data.employee_name : "",
  
        number_of_pax: data.number_of_pax ? data.number_of_pax : "",
        narration: data.narration_1 ? data.narration_1 : "",

        hotel: data.hotel ? data.hotel : "",
        hotel_name: data.hotel_name ? data.hotel_name.name ? data.hotel_name.name : data.hotel_name : "",
        
        room_name: traveller.room_name ? traveller.room_name : "",
        lead_pax_name: traveller.lead_pax_name ? traveller.lead_pax_name.account_name?traveller.lead_pax_name.account_name:traveller.lead_pax_name : "",
        reference_number: traveller.reference_number ? traveller.reference_number : "",
       
        service_started: traveller.service_started ? moment(traveller.service_started).format('YYYY-MM-DD') : "",
        service_ended : traveller.service_ended ? moment(traveller.service_ended).format('YYYY-MM-DD') : "",
  
        supplier_non_account: "",
        supplier_basic: "0",
        supplier_taxes: "0",
        supplier_commission_type: "RB",
        supplier_commission_value: "",
        supplier_commission_amount: "",
        supplier_tds: "",
        supplier_tds_type: "",
        supplier_tds_conf: "",
  
        supplier_sc1_type: "T",
        supplier_sc1_amount: "",
        supplier_sc2_type: "B",
        supplier_sc2_taxable: "N",
        supplier_sc2_amount: "",
        supplier_sc3_type: "OT",
        supplier_sc3_taxable: "N",
        supplier_sc3_amount: "",
        
        supplier_gst_type: "S",
        supplier_gst_conf: "",
        supplier_cgst: "",
        supplier_sgst: "",
        supplier_igst: "",

        supplier_gst_type_2: "B",
        supplier_gst_conf_2: "",
        supplier_cgst_2: "",
        supplier_sgst_2: "",
        supplier_igst_2: "",

        supplier_gst_type_3: "T",
        supplier_gst_conf_3: "",
        supplier_cgst_3: "",
        supplier_sgst_3: "",
        supplier_igst_3: "",

        supplier_raf_charges: "0",
        supplier_igst_on_raf: "",
        supplier_cgst_on_raf: "",
        supplier_sgst_on_raf: "",
        supplier_xxl_charges : 0,  

        supplier_travel_insurance : 0,
        supplier_meal_charges : 0,  
        supplier_permit_charges : 0, 
        supplier_parking_charges : 0, 
        supplier_toll_charges : 0, 

        supplier_net_amount: "",
  
        client_non_account: "",
        client_basic: "0",
        client_taxes: "0",
        client_commission_type: "RB",
        client_commission_value: "",
        client_commission_amount: "",
        client_tds: "",
        client_tds_type: "",
        client_tds_conf: "",

        client_sc1_type: traveller.client_sc1_type ? traveller.client_sc1_type : "T",
        client_sc1_amount: serviceChargeAmount ? serviceChargeAmount : "",
        client_sc2_type: "B",
        client_sc2_taxable: "N",
        client_sc2_amount: "",
        client_sc3_type: "OT",
        client_sc3_taxable: "N",
        client_sc3_amount: "",
  
        client_gst_type: traveller.client_gst_type ? traveller.client_gst_type : "S",
        client_gst_conf: traveller.client_gst_conf ? traveller.client_gst_conf : "",
        client_cgst: cgstAmount ? cgstAmount : "",
        client_sgst: sgstAmount ? sgstAmount : "",
        client_igst: igstAmount ? igstAmount : "",

        client_gst_type_2: "B",
        client_gst_conf_2: "",
        client_cgst_2: "",
        client_sgst_2: "",
        client_igst_2: "",

        client_gst_type_3: "T",
        client_gst_conf_3: "",
        client_cgst_3: "",
        client_sgst_3: "",
        client_igst_3: "",

        client_raf_charges: "0",
        client_igst_on_raf: "0",
        client_cgst_on_raf: "0",
        client_sgst_on_raf: "0",
        client_xxl_charges : "0",  

        client_travel_insurance : 0,
        client_meal_charges : 0,  
        client_toll_charges : 0,  
        client_parking_charges : 0,  
        client_permit_charges : 0,  

        client_net_amount: totalClientAmount ? totalClientAmount : '',
        
        hsn : traveller.hsn?traveller.hsn:hsn,
        hsn_2 : traveller.hsn_2?traveller.hsn_2:'',
        hsn_3 : traveller.hsn_3?traveller.hsn_3:'',
        pay_type: traveller.pay_type ? traveller.pay_type : "",
        ledger_card_no: traveller.ledger_card_no ? traveller.ledger_card_no : "",
        ledger_card_name: traveller.ledger_card_name ? traveller.ledger_card_name.name ? traveller.ledger_card_name.name : traveller.ledger_card_name : "",
        e_code: traveller.e_code ? traveller.e_code.account_code ? traveller.e_code.account_code : traveller.e_code : "",
        cost_center: traveller.cost_center ? traveller.cost_center.cost_center ? traveller.cost_center.cost_center : traveller.cost_center : "",
        payble_code: traveller.payble_code ? traveller.payble_code : "",
        payble_code_name: traveller.payble_code_name ? traveller.payble_code_name.name ? traveller.payble_code_name.name : traveller.payble_code_name : "",
        reference_invoice_number : data.reference_invoice_number ? data.reference_invoice_number : "",
        skip_v3_entry : data.skip_v3_entry,

        coach: traveller.coach ? traveller.coach : '',
        seat_no: traveller.seat_no ? traveller.seat_no : '',
        berth: traveller.berth ? traveller.berth : '',
        status: data.status? data.status:"Active",
        supplier_vat_type: traveller.supplier_vat_type ? traveller.supplier_vat_type : "",
        supplier_vat_conf: traveller.supplier_vat_conf ? traveller.supplier_vat_conf : 0,
        supplier_vat_amount: traveller.supplier_vat_amount ? traveller.supplier_vat_amount : 0,
        supplier_vat_on_raf: traveller.supplier_vat_on_raf ? traveller.supplier_vat_on_raf : 0,

        client_vat_type: traveller.client_vat_type ? traveller.client_vat_type : "",
        client_vat_conf: traveller.client_vat_conf ? traveller.client_vat_conf : 0,
        client_vat_amount: traveller.client_vat_amount ? traveller.client_vat_amount : 0,
        hotel_address: data.hotel_address ? data.hotel_address :'',
        passport_no: data.passport_no ? data.passport_no :'',
        booking_plan: data.booking_plan ? data.booking_plan :'',
        room_rent_per_day: data.room_rent_per_day ? data.room_rent_per_day :'',
        inclusions: data.inclusions ? data.inclusions :'',
        exclusions: data.exclusions ? data.exclusions :'',
        confirmed_by: data.confirmed_by ? data.confirmed_by :'',
        issued_by: data.issued_by ? data.issued_by :'',
        payment_mode: data.payment_mode ? data.payment_mode :'',
        confirmation_no: data.confirmation_no ? data.confirmation_no :'',
        hotel_tel_no: data.hotel_tel_no ? data.hotel_tel_no :'',
        adults: data.adults ? data.adults :'',
        childs: data.childs ? data.childs :'',

        is_p2p: data.is_p2p ? data.is_p2p : false,

        client_vat_on_raf: traveller.client_vat_on_raf ? traveller.client_vat_on_raf : 0
      };
      gst += gstAmount;
      netAmount += Number(totalClientAmount)
      products.push(productObj)
    }
  let totalAmount = Number(data.tcs_amount) + Number(netAmount)

  let invoioceObj = {
      "affiliate_code": data.client_code,
      "name": data.client_code_name ? data.client_code_name.name ? data.client_code_name.name : data.client_code_name : "",
      "billaddress": "",
      "invoice_uuid": data.invoice_number,
      "invoice_date": data.invoice_date,
      "created_by": data.employee,
      "updated_by": data.employee,
      "year_id": 1,
      "tenant_id": 1,
      "narration_1": data.narration_1,
      "narration_2": data.narration_2,
      "narration_3": data.narration_3,
      "narration_4": data.narration_4,
      "narration_5": data.narration_5,
      "remark": data.remark,
      "tcs_rate": data.tcs_rate ? data.tcs_rate : null,
      "tcs_amount": data.tcs_amount ? data.tcs_amount : null,
      "total": totalAmount ? Math.round(totalAmount) : null,
      "balance_due": 0,
      "gst_total": gst,
      "products": products,
      "reference_invoice_number": products[0].reference_invoice_number,
      "product_type": data.invoice_type == "non_account"  ? "gst" :"miscellaneous",
      "status": data.status? data.status:"Active",
      "sales_currency": data.sales_currency ? data.sales_currency : null,
      "sales_currency_rate" : data.sales_currency_rate ? data.sales_currency_rate : null,
      "sales_currency_amount" : data.sales_currency_amount ? data.sales_currency_amount : null,
      "purchase_currency": data.purchase_currency ? data.purchase_currency : null,
      "purchase_currency_rate" : data.purchase_currency_rate ? data.purchase_currency_rate : null,
      "bill_of_supply" : false,
      "nonRefundable" : data.nonRefundable ? data.nonRefundable : false,
      "tcs_on": data.tcs_on ? data.tcs_on : 'total',
   }
  
    return invoioceObj;

  }
  

}
