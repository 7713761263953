
 <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
  <div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
    <div class="d-flex flex-y-center">
      <img
        src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
          alertType=='success-box' ? 'green-sucess.svg' : 
          alertType=='warning-box' ? 'red-warning.svg' : 
          null }}"
        width="20px"
        height="20px"
        alt="alert-logo"
        class="mr-20"
      />
      <div class="ml-10">
        <h4 class="m-0 text-size-18 text-bold"
        [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
          {{alertType === "error-box"
          ? "Error"
          : alertType === "warning-box"
          ? "Warning"
          : alertType === "success-box"
          ? "Success"
          : null}}
        </h4>
        <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
         {{message}}
        </small>
      </div>
    </div>
    <img
      src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
      alt="close-btn"
      (click)="hideError()"
      className=" close-btn"
    />
  </div>
</div>

<div class="ledger-container  ">
  <div class="row tab-nav pl-0 pr-0">
    <!-- <div class="col-sm-4 pl-0">
        <ul class="list-inline list-unstyled p-0 m-0">
            <li class="active page-title text-uppercase cursor"> View Client</li>
        </ul>
    </div> -->
    <div class="col-sm-12">
      <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
          <li  
              class="text-grey text-capitalize cursor  hover-text-primary transition"> Master</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li  
              class="text-grey text-capitalize cursor  hover-text-primary transition"> General</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li  
              class="text-grey text-capitalize cursor  hover-text-primary transition"> View General</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li class="text-grey text-capitalize cursor text-primary transition"> Edit General</li>
      </ul>
  </div>
</div>


    <form [formGroup]="generalForm" #form="ngForm">
      <div class="custom-form form-container mt-4 pl-15 pr-15" style="    width: 600px;
      margin: 20px 0;">
      <div class="text-field-group d-flex">
        <div class="select-field text-input-field mr-2 col-10 p-0">
          <select class="input-field h-100" oninput="this.value = this.value.toUpperCase()" tabIndex="1" (change)="setHierarchy($event.target.value,false)"
            formControlName="parent_id" [ngClass]="{'error-field': (!generalForm.controls.parent_id.valid && submitted),
            'success-field' : generalForm.controls.parent_id.valid && generalForm.controls.parent_id.dirty}">
            <option selected disabled>SELECT</option>
            <!-- <ng-container *ngIf="groupList.length > 0"> -->
            <option *ngFor="let item of groupList" value="{{item.id}}">{{item.name}}</option>
            <!-- </ng-container> -->
          </select>
          <label
            [ngClass]="{'error-label': generalForm.controls.parent_id.invalid && generalForm.controls.parent_id.dirty || (!generalForm.controls.parent_id.valid && submitted)}">Group
            <ng-container
              *ngIf="generalForm.controls.parent_id.invalid && generalForm.controls.parent_id.dirty || (!generalForm.controls.parent_id.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Group Required" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>
        <div class="text-input-field">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="2"
            formControlName="hierarchy" type="text" />
          <label>
            Hierarchy
          </label>
        </div>
      </div>
      <div class="text-field-group d-flex">
        <div class="text-input-field">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="3"
            formControlName="account_name" type="text" [ngClass]="{'error-field': generalForm.controls.account_name.invalid && generalForm.controls.account_name.dirty || (!generalForm.controls.account_name.valid && submitted) ,
              'success-field' : generalForm.controls.account_name.valid && generalForm.controls.account_name.dirty}" />
          <label
            [ngClass]="{'error-label': generalForm.controls.account_name.invalid && generalForm.controls.account_name.dirty || (!generalForm.controls.account_name.valid && submitted)}">Account
            <span class="text-danger">*</span>

            <ng-container
              *ngIf="generalForm.controls.account_name.invalid && generalForm.controls.account_name.dirty || (!generalForm.controls.account_name.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Invalid telephone Number" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        
        <div style="width: 150px;" class="text-input-field mr-2" *ngIf="generalData?.account_code">
          <input class="input-field" [value]="generalData?.account_code"  type="text" readonly />
          <label>Account Code</label>
        </div>
      </div>
      <div class="text-input-group d-flex justify-content-between">

        <div class="text-input-field mr-2">
          <select class="input-field h-100" oninput="this.value = this.value.toUpperCase()" tabIndex="4"
            formControlName="state_id" (change)="selectState($event.target.value)">
            <option *ngFor="let state of stateArr" value="{{state.location_id}}">{{state.name}}</option>
          </select>
          <label>State</label>
        </div>

        <div class="text-input-field" *ngIf="isTenantIndian">
          <input class="input-field" onblur="this.value = this.value.toUpperCase()" name="gst_number" tabIndex="5"
            formControlName="gst_number" type="text" maxlength="15"
            pattern="^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$"
            [ngClass]="{'error-field': generalForm.controls.gst_number.invalid && generalForm.controls.gst_number.dirty || (!generalForm.controls.gst_number.valid && submitted),
              'success-field' : generalForm.controls.gst_number.valid && generalForm.controls.gst_number.dirty}" />
          <label
            [ngClass]="{'error-label': generalForm.controls.gst_number.invalid && generalForm.controls.gst_number.dirty} || (!generalForm.controls.gst_number.valid && submitted)">
            GST NUMBER
            <ng-container
              *ngIf="generalForm.controls.gst_number.invalid && generalForm.controls.gst_number.dirty || (!generalForm.controls.gst_number.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Invalid GST Number ( ex: 12ABCDE1234F1Z3)"
                aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <div class="text-input-field" *ngIf="!isTenantIndian">
          <input class="input-field" onblur="this.value = this.value.toUpperCase()" name="vat_number" tabIndex="5"
            formControlName="vat_number" type="text" maxlength="20"
            [ngClass]="{'error-field': generalForm.controls.vat_number.invalid && generalForm.controls.vat_number.dirty || (!generalForm.controls.vat_number.valid && submitted),
              'success-field' : generalForm.controls.vat_number.valid && generalForm.controls.vat_number.dirty}" />
          <label
            [ngClass]="{'error-label': generalForm.controls.vat_number.invalid && generalForm.controls.vat_number.dirty} || (!generalForm.controls.vat_number.valid && submitted)">
            {{vatAlternetname}} NUMBER
          </label>
        </div>

      </div>

      <ng-container *ngIf="!isTenantIndian">
        <div class="text-input-group d-flex justify-content-between">
          
          <div class="select-field text-input-field mr-3" >
            <select id="selectscroll" class="input-field" tabIndex="1" formControlName="currency"  (change)="currencyChange($event.target.value)"  >
              <option value="INR">INR</option>
              <option value="CAD">CAD</option>
                <option value="USD">USD</option>
                <option value="AED">AED</option>
                <option value="EURO">EURO</option>
            </select>
            <label>CURRENCY</label>
          </div>
          <div class="text-input-field mr-3">
            <input class="input-field" style="    width: 185px;" oninput="this.value = this.value.toUpperCase()" 
              name="currency" tabIndex="13" formControlName="currency_rate" type="text" maxlength="10"
              [ngClass]="{'error-field': generalForm.controls.currency_rate.invalid && generalForm.controls.currency_rate.dirty,
          'success-field' : generalForm.controls.currency_rate.valid && generalForm.controls.currency_rate.dirty}" />
            <label
              [ngClass]="{'error-label': generalForm.controls.currency_rate.invalid && generalForm.controls.currency_rate.dirty}">CURRENCY RATE<ng-container *ngIf="generalForm.controls.currency_rate.invalid && generalForm.controls.currency_rate.dirty">
                <i class="fa fa-info-circle ml-2" title="Invalid pan number" aria-hidden="true"></i>
              </ng-container>
            </label>
          </div>
          <div class="text-input-group d-flex justify-content-between">
            <div class="text-input-field" style="    width: 185px;">
              <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="local_balance" tabIndex="13"
            formControlName="local_balance"  type="text" [ngClass]="{'error-field': generalForm.controls.local_balance.invalid && generalForm.controls.local_balance.dirty,
          'success-field' : generalForm.controls.local_balance.valid && generalForm.controls.local_balance.dirty}" (input)="changeCurrentAmount($event)" />
          <label
            [ngClass]="{'error-label': generalForm.controls.local_balance.invalid && generalForm.controls.local_balance.dirty}">AMOUNT
            <ng-container *ngIf="generalForm.controls.local_balance.invalid && generalForm.controls.local_balance.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid amount" aria-hidden="true"></i>
            </ng-container>
          </label>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="text-field-group d-flex">
        <div class="text-input-field mr-2">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="hsn_code" tabIndex="6"
            formControlName="hsn_code" pattern="^\d*(\.\d{0,8})?$" type="text" [ngClass]="{'error-field': generalForm.controls.hsn_code.invalid && generalForm.controls.hsn_code.dirty,
              'success-field' : generalForm.controls.hsn_code.valid && generalForm.controls.hsn_code.dirty}" />
          <label
            [ngClass]="{'error-label': generalForm.controls.hsn_code.invalid && generalForm.controls.hsn_code.dirty}">HSN
            Code
            <ng-container *ngIf="generalForm.controls.hsn_code.invalid && generalForm.controls.hsn_code.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid  HSN Code" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>
        <div class="text-input-field mr-2">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="balance" tabIndex="7" [readonly]="!isTenantIndian"
            formControlName="balance" pattern="^\d*(\.\d{0,8})?$" type="text" [ngClass]="{'error-field': generalForm.controls.balance.invalid && generalForm.controls.balance.dirty,
              'success-field' : generalForm.controls.balance.valid && generalForm.controls.balance.dirty}" />
          <label
            [ngClass]="{'error-label': generalForm.controls.balance.invalid && generalForm.controls.balance.dirty}">Opening
            Balance
            <ng-container *ngIf="generalForm.controls.balance.invalid && generalForm.controls.balance.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid opening balance" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <div class="select-field text-input-field mr-2">
          <select class="input-field h-100" tabIndex="8" formControlName="balance_type">
            <option value="debit">DR</option>
            <option value="credit">CR</option>
          </select>
        </div>

        <div class="select-field text-input-field">
          <select class="input-field h-100" tabIndex="9" formControlName="status">
            <option value="active">ACTIVE</option>
            <option value="inactive">INACTIVE</option>
          </select>
          <label>Status</label>
        </div>


      </div>
      <div  class="select-field text-input-field" *ngIf="isHQBranch">
        <label for="selectedBranch" style="font-size: 13px; font-weight: 500!important;">Branch</label>
        <ng-container *ngFor="let item of branches">
          <div *ngIf="item?.branch_type=='BRANCH'" class="input-fiel" style="font-size: 13px; font-weight: 500!important;">
            <input class="radio-ui mr-2"  type="checkbox"  (change)="handleValueChange($event,item?.branch_name)"  />
            <label class="radio-label" style="transform: none;font-size: 12px;margin-bottom: 0;" >{{item?.branch_name}}</label>
          </div>
    </ng-container>
      </div>
    </div>
      <div class="btn-field bottom-btns-container full-width-btn-container d-flex align-item-center justify-content-end ml-0 mr-0">    
         <!-- <button type="button" class="btn-style bg-green mr-3" tabIndex="20"
          >save</button> -->
          <button type="button" class="btn-style bg-green mr-3" tabIndex="9" (click)="setGeneralData()">Reset</button>
          <button type="button" class="btn-style bg-cyan mr-3" tabIndex="10"
          *hasAccess="{'key' : 'F0001053'}"
          (click)="openUpdateConfirmModal()">Modify</button> 
       
        <button type="button" class="btn-style bg-red mr-3" (click)="openDeleteConfirmModal()"
        *hasAccess="{'key' : 'F0001054'}"
          tabIndex="11">Delete</button>
        <button type="button" class="btn-style bg-purple-iris mr-3" tabIndex="12"
        (click)="openConfiguration(generalId)">Configuration</button>
        <button type="button" class="btn-style bg-indigo " id="general_activity_log" (click)="openActivity()">Activity</button> 

      </div>
    </form>
</div>


<button type="button" hidden #confirmUpdateModal data-toggle="modal" data-target="#updateconfirmationModal"></button>
<div id="updateconfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" #closeUpdateModal class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        Update {{generalForm?.value?.account_name}} ?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style mr-3" tabIndex="14" attr.tabIndex="14"
          data-dismiss="modal">Close</button>
          <button type="button" class="btn-style bg-green" (click)="checkBalance()" data-dismiss="modal" tabIndex="13"
            attr.tabIndex="13">Yes</button>
      </div>
    </div>
  </div>
</div>

<button type="button" hidden #confirmDeleteModal data-toggle="modal" data-target="#deleteConfirmationModal"></button>

<div id="deleteConfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" #closeDeleteModal class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <p>Are you sure to delete ‎this entry ?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style" tabIndex="14" attr.tabIndex="14" (click)="closeDeleteConfirmModal()"
          data-dismiss="modal">No</button>
          <button type="button" class="btn-style bg-red" tabIndex="13" attr.tabIndex="13" (click)="deleteGeneral()"
            data-dismiss="modal">Yes</button>
      </div>
    </div>
  </div>
</div>
<button type="button" hidden #confirmOBModal data-toggle="modal" data-target="#updateOBModal"></button>
  <div id="updateOBModal" class="modal fade" role="dialog" data-backdrop="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Confirmation</h4>
          <button type="button" #closeOBModal class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          There is a mismatch of amount {{remainingAmount}} in your opening balance. It will be adjusted in the Opening Balance Adjustment account. Would you like to proceed?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn-style mr-3" 
          data-dismiss="modal">Close</button>
          <button type="button" class="btn-style bg-green" (click)="updateOpeningBalancecombine()" >UPDATE</button>
        </div>
      </div>
    </div>
  </div>