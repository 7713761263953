import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormArray, Validators, NgForm, AbstractControl, FormGroup } from '@angular/forms';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { JvService } from '../services/jv.service';
import * as moment from 'moment-timezone';
import { FormCanDeactivate } from 'src/app/shared/form-can-deactivate/form-can-deactivate';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';

@Component({
	selector: 'app-add-entry-jv',
	templateUrl: './add-entry-jv.component.html',
	styleUrls: ['./add-entry-jv.component.scss'],
	providers: [JvService]
})
export class AddEntryJvComponent extends FormCanDeactivate  implements OnInit {
	@Input() data
	@ViewChild('form')
 	form: NgForm;
	jvData: any;
	jvArray: FormArray
	updateJvForm: any;
	selectedDate;
	jvId: any;
	keyword = "name"
	sendEmailForm: FormGroup;

	showMessage: boolean = false;
	alertType = '';
	message: string = ``;

	accountList = []
	ledgerList = []
	attachmentFile: File | null = null;

	@ViewChildren('accountAc') accountAc: QueryList<ElementRef>;
	@ViewChildren('subLedgerAc') subLedgerAc: QueryList<ElementRef>;
	// @ViewChild('accountAc') accountAc;
	// @ViewChild('subLedgerAc') subLedgerAc;
	lastInputIndex = 8;
	@ViewChild('confirmModal',{static:true}) confirmModal : ElementRef
	@ViewChild('closeModal',{static:true}) closeModal : ElementRef

	@ViewChild('confirmDeleteModal',{static:true}) confirmDeleteModal : ElementRef
	@ViewChild('closeDeleteModal',{static:true}) closeDeleteModal : ElementRef
	@ViewChild('deleteConfirmBtn',{static:true}) deleteConfirmBtn : ElementRef

	@ViewChild('confirmUpdateModal',{static:true}) confirmUpdateModal : ElementRef
	@ViewChild('closeUpdateModal',{static:true}) closeUpdateModal : ElementRef

	@ViewChild('updateConfirmBtn',{static:true}) updateConfirmBtn : ElementRef
	@ViewChild('updateButton',{static:true}) updateButton : ElementRef
	@ViewChild('openEmailModal') openEmailModal;
	@ViewChild('closeSendEmailModal') closebutton;

	shortcuts: ShortcutInput[] = [];
	currentOpenedModal = ''
	minDate;
  	maxDate;
	acCurrencyCode = ''
	isTenantIndian: boolean = true;
	financialYearDiff = false;
	constructor(
		public commonService: CommonServiceService,
		private spinner: NgxSpinnerService,
		private fb: FormBuilder,
		private route: ActivatedRoute,
		public jvService: JvService

	) {
		super()
		this.updateJvForm = this.fb.group({
			amountDiff: [0],
			totalCredit: [0],
			totalDebit: [0],
			jv_date: [moment().format('YYYY-MM-DD'),Validators.required],
			jvArray: this.fb.array([]),
			currency_code: [''],
			currency_rate: [''],

		});
		this.sendEmailForm = this.fb.group({
			toEmails: ["",Validators.required],
			bccEmails: [''],
			ccEmails:[''],
			subject:[''],
			body:['']
		})
		this.jvArray = this.updateJvForm.get("jvArray") as FormArray;
		this.route.paramMap.subscribe(params => {
			this.jvId = params['params'].id
			// this.getJv()
		});

	}

	async ngOnInit() {
		/** spinner starts on init */
		this.spinner.show();

		setTimeout(() => {
			/** spinner ends after 5 seconds */
			this.spinner.hide();
		}, 1000);

		if(this.data) {
			this.jvId = this.data.jv_code
		}

		this.minDate = moment().subtract(15, 'days').format('YYYY-MM-DD')
		try {
			let empConfiguration = this.commonService.employeeConfiguration;
				if(!empConfiguration || empConfiguration.length == 0){
					let loggedInUser = JSON.parse(localStorage.okountUserInfo);
					empConfiguration = await this.commonService.getConfiguration(loggedInUser.accountcode)
				}
		} catch (error) {
			
		}

		let globalSettingConfig = this.commonService.globalSettingConfig;
		if(!globalSettingConfig || globalSettingConfig.length == 0) {
			globalSettingConfig = await this.commonService.getGlobalSettings();
		}

		this.isTenantIndian = this.commonService.checkTenantIndian()

		this.jvDateValidation();

		if (this.jvId != undefined) {
			this.getJv()
		}
		this.commonService.selectedInput.next(0)
	}


	validateDate(){
		return (control: AbstractControl) => {
			let valid = true
			if(this){
				if (moment(control.value).isBefore(this.minDate) || moment(control.value).isAfter(this.maxDate)) {
					if(this.jvData && this.jvData.length > 0 && this.jvData[0].jv_date && moment(control.value,'YYYY-MM-DD').isSame(moment(this.jvData[0].jv_date,'YYYY-MM-DD'))){
						valid = true
					}else{
						valid = false
					}
				}
			}
			return !valid ? {jv_date: {value: control.value}} : null;
		};
	}

	ngAfterViewInit(){

		this.shortcuts.push(			
			{
				key: "alt + e",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Update Jv Entry",
				description: "Update Jv Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("modify_jv_btn")){

				document.getElementById("modify_jv_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + d",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Delete Jv Entry",
				description: "Delete Jv Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("delete_jv_btn")){

				document.getElementById("delete_jv_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + l",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Activity Log Of Jv Entry",
				description: "Activity Log Of Jv Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("jv_activity_log_btn")){

				document.getElementById("jv_activity_log_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + plus",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Add New Jv Row",
				description: "Add New Jv Row",
				
				command: (e)=>{
				console.log(e)	
					this.addJvRow()
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
					},
					preventDefault: true
			},
			{
				key: "alt + -",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Delete Last Jv Row",
				description: "Delete Last Jv Row",
				
				command: (e)=>{
				console.log(e)	
				if(this.jvArray.length>0){
					this.removeJvRow(this.jvArray.length-1)
					setTimeout(() => {
					document.getElementsByTagName('body')[0].click()
					}, 100);
				}
						},
						preventDefault: true
			},
			{
				key: "alt + y",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Clicked On Yes Button",
				description: "Clicked On Yes Button",
				
				command: (e)=>{
				if(this.currentOpenedModal=='update'){
					this.submit(this.updateJvForm.value)
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}else if(this.currentOpenedModal=='delete'){
					this.deleteJV(this.jvId)
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}
				this.currentOpenedModal = ''
					},
					preventDefault: true
			},
			{
				key: "alt + n",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Clicked On No Button",
				description: "Clicked On No Button",
				
				command: (e)=>{
				if(this.currentOpenedModal=='update'){
					this.closeUpdateConfirmModal()
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}else if(this.currentOpenedModal=='delete'){
					this.closeDeleteConfirmModal()
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}
				this.currentOpenedModal = ''
					},
					preventDefault: true
			},
			
	  )
	}

	openConfirmModal(lastIndex,addIndex) :  void{
		this.hideError()
		this.confirmModal.nativeElement.click()
		this.moveToTab(lastIndex,addIndex)
	}
	closeConfirmModal() :  void{
		console.log('Modal Closed')
		if (this.closeModal) {
			this.closeModal.nativeElement.click()
		}
	}

	focusAccountAc(e: Event,index) {
		e.stopPropagation();
		// this.accountAc["_results"][(<HTMLInputElement>e.target).tabIndex%(+this.accountAc["_results"].length-1)+1].el.nativeElement.focus();
		if (this.accountAc["_results"][index]) {
			this.accountAc["_results"][index].focus()
		}

	  }
	  focusSubLedgerAC(e: Event,index) {
		e.stopPropagation();
		if (this.subLedgerAc["_results"][index]) {
			this.subLedgerAc["_results"][index].focus()
		}
	  }

	movetoIndex(index){
		this.lastInputIndex = ((index+1)*8)
		setTimeout(() => {
			this.commonService.selectedInput.next(this.lastInputIndex+1)
		}, 100);
	}
	moveToTab(lastInputIndex,addIndex){
		this.commonService.selectedInput.next(lastInputIndex+addIndex)
	}

	addJvRow() {
		this.closeConfirmModal()
		this.jvArray.push(this.buildJv());
	}

	removeJvRow(index) {
		this.jvArray.removeAt(index);
		this.movetoIndex(this.jvArray.length - 1)
	}

	getAccounts(type,value, i) {
		return new Promise((resolve, reject) => {
			let searchType = type
			if (value.length > 2 && searchType != null && searchType != '') {
			this.jvService.getMethod(`${searchType}/autocomplete?name_like=${value.toUpperCase()}&status=active`).subscribe(res => {
				this.jvService.accountList[i] = res
				resolve(res)
			}, err => {
				resolve("")
			})
		}
		})
	}

	getLedgers(url, index) {
		return new Promise((resolve, reject) => {
			this.jvService.getMethod(url).subscribe(res => {
				this.jvService.ledgerList[index] = res
				resolve(res)
			}, err => {
				resolve("")
			})
		})
	}

	get DynamicFormControls() {
		return <FormArray>this.updateJvForm.get("jvArray");
	}

	dateSelection(event) {
		let jvFormGroupControl = this.updateJvForm.controls.jvArray.controls
		this.selectedDate = moment(event).format('YYYY-MM-DD')
		for (let i = 0; i < jvFormGroupControl.length; i++) {
			const jvControl = jvFormGroupControl[i];
			jvControl.controls.jv_date.setValue(this.selectedDate)
		}
	}

	searchAccount(value, i) {
		try {
			let searchType = this.updateJvForm.controls.jvArray.controls[i].value.account_type
			if (value.length > 2 && searchType != null && searchType != '') {
				this.commonService.getRequest(`${environment.okountUrl}/${searchType}/autocomplete?name_like=${value.toUpperCase()}&status=active`).subscribe(res => {
					
					this.accountList[i] = res
					console.log(this.accountList)
				});
			}
		} catch (error) {
			console.log('Error occured while account autoo complete search : ',error)
		}
	}
	selectAccount(data, formControlName, index) {
		this.updateJvForm.controls.jvArray.controls[index].controls[formControlName].setValue(data.account_code)
	}
	searchLedger(value, i) {
		try {
			let searchType = this.updateJvForm.controls.jvArray.controls[i].value.sub_ledger_type
			if (value.length > 2 && searchType != null && searchType != '') {
				this.commonService.getRequest(`${environment.okountUrl}/${searchType}/autocomplete?name_like=${value.toUpperCase()}&status=active`).subscribe(res => {
					this.ledgerList[i] = res
					console.log(this.ledgerList)
				});
			}
		} catch (error) {

		}
	}
	selectLedger(data, formControlName, index) {
		this.updateJvForm.controls.jvArray.controls[index].controls[formControlName].setValue(data.account_code)
	}

	calculateDiff(index, formControlName) {
		if (formControlName == 'debit') {
			this.updateJvForm.controls.jvArray.controls[index].controls.credit.setValue('0')
		}
		if (formControlName == 'credit') {
			this.updateJvForm.controls.jvArray.controls[index].controls.debit.setValue('0')
		}
		let totalDebit = this.getTotal('debit')
		let totalCredit = this.getTotal('credit')
		let difference = Number((totalDebit - totalCredit).toFixed(2))
		this.updateJvForm.controls.amountDiff.setValue(difference)
		this.updateJvForm.controls.totalDebit.setValue(totalDebit)
		this.updateJvForm.controls.totalCredit.setValue(totalCredit)
	}

	getTotal(amountType) {
		let amount = 0
		if (this.updateJvForm.controls.jvArray.controls.length > 0) {
			let controls = this.updateJvForm.controls.jvArray.controls
			for (let index = 0; index < controls.length; index++) {
				const element = controls[index];
				amount += Number(element.controls[amountType].value)
			}
		}
		return amount
	}


	// doTransactionEntry(entries) {
	// 	this.commonService.postRequest(`${environment.okountUrl}/v3/jv`, entries).subscribe(res => {
	// 		console.log(res)
	// 	}, err => {
	// 	})
	// }

	buildJv() {
		let currentUser = JSON.parse(localStorage.getItem('okountUserInfo'))
		let date = moment().format('YYYY-MM-DD')
		if (this.selectedDate != undefined) {
			date = this.selectedDate
		}
		return this.fb.group({
			"id": [""],
			"account_type": [""],
			"account_code": [""],
			"account_code_name": [''],
			"jv_date": [date, Validators.required],
			"account_balance": [""],
			"sub_ledger_type": [""],
			"sub_ledger_code": [""],
			"sub_ledger_code_name": [""],
			"debit": ["0"],
			"credit": ["0"],
			"narration_1": [""],
			"narration_2": [""],
			"remarks_1": [""],
			"remarks_2": [""],
			"last_updated_by_id": [currentUser.id], // FIX ME
			"last_updated_by_name": [currentUser.name], // FIX ME
			"last_updated_by_code": [currentUser.accountcode] // FIX ME
			
		});
	}

	getJv() {
		this.commonService.getOkount(`jv?journal_code=${this.jvId}`).subscribe(async (res) => {
			if (res && res['data']) {
				this.jvData = res['data']
				
				if(res && res['accounting_company']) {
					this.acCurrencyCode = res['accounting_company']['currency_code'] ? res['accounting_company']['currency_code'] : ''
				}

				for (const key in this.jvData) {
					if (this.jvData.hasOwnProperty(key)) {
						const element = this.jvData[key];
						let accounts = await this.getAccounts(element.account_type,element.account_code, key)
						if (element.sub_ledger_type != null) {
							let ledgers = await this.getLedgers(element.sub_ledger_type, key)
						}
						this.addJvRow()
						await this.setJvData(element, key)
						this.calculateDiff(key,Number(element.credit) ? 'credit' : 'debit')
						this.lastInputIndex = ((this.jvData.length)*8)
					}
				}
			}
			console.log(this.jvData)
		})
	}

	setJvData(data, index) {
		if (this.updateJvForm != undefined && data != undefined) {
			this.updateJvForm.controls.jvArray.controls[index].controls.id.setValue(data.id)
			this.updateJvForm.controls.jvArray.controls[index].controls.account_type.setValue(data.account_type)
			this.updateJvForm.controls.jvArray.controls[index].controls.account_code.setValue(data.account_code)
			try {
				this.updateJvForm.controls.jv_date.setValue(moment(data.jv_date).format('YYYY-MM-DD'))
				
				this.updateJvForm.controls.currency_code.setValue(data.currency_code);
				this.updateJvForm.controls.currency_rate.setValue(data.currency_rate);

				this.updateJvForm.controls.jvArray.controls[index].controls.jv_date.setValue(moment(data.jv_date).format('YYYY-MM-DD'))
			} catch (error) {
			}
			if (this.jvService.accountList.length > 0) {
				let selectedAccount = this.jvService.accountList[index].filter(option => {
					return option.account_code == data.account_code;
				});
				if (selectedAccount.length > 0) {
					this.accountList[index] = selectedAccount
					this.updateJvForm.controls.jvArray.controls[index].controls.account_code_name.setValue(selectedAccount[0].name)
				}
			}
			let selectedLedger = [];
			if(this.jvService.ledgerList.length > 0 && this.jvService.ledgerList[index] != undefined) {

				selectedLedger = this.jvService.ledgerList[index].filter(option => {
					return option.account_code == data.sub_ledger_code;
				});
			}
			if (selectedLedger.length > 0) {
				this.ledgerList[index] = selectedLedger
				this.updateJvForm.controls.jvArray.controls[index].controls.sub_ledger_code_name.setValue(selectedLedger[0].name)
			}
			this.updateJvForm.controls.jvArray.controls[index].controls.sub_ledger_type.setValue(data.sub_ledger_type)
			this.updateJvForm.controls.jvArray.controls[index].controls.sub_ledger_code.setValue(data.sub_ledger_code)
			this.updateJvForm.controls.jvArray.controls[index].controls.account_balance.setValue(data.account_balance)
			this.updateJvForm.controls.jvArray.controls[index].controls.debit.setValue(data.debit)
			this.updateJvForm.controls.jvArray.controls[index].controls.credit.setValue(data.credit)
			this.updateJvForm.controls.jvArray.controls[index].controls.narration_1.setValue(data.narration_1)
			this.updateJvForm.controls.jvArray.controls[index].controls.narration_2.setValue(data.narration_2)
			this.updateJvForm.controls.jvArray.controls[index].controls.remarks_1.setValue(data.remarks_1)
			this.updateJvForm.controls.jvArray.controls[index].controls.remarks_2.setValue(data.remarks_2)
			this.updateJvForm.controls.jvArray.controls[index].controls.last_updated_by_id.setValue(data.last_updated_by_id)
			this.updateJvForm.controls.jvArray.controls[index].controls.last_updated_by_name.setValue(data.last_updated_by_name)
			this.updateJvForm.controls.jvArray.controls[index].controls.last_updated_by_code.setValue(data.last_updated_by_code)

			// if (this.jvData.gst_expiry_date != null && this.jvData.gst_expiry_date != '') {
			// 	this.updateJvForm.controls.gst_expiry_date.setValue(moment(this.jvData.g.format('YYYY-MM-DD')st_expiry_date))
			// }

		}
	}

	async submit(value) {
		const invalid = [];
		const controls = this.updateJvForm.controls;
		for (const name in controls) {
			if (controls[name].invalid && name != "jvArray") {
				invalid.push(name);
			}
		}
		!this.financialYearDiff && await this.initDateValidation();
		
		if(invalid.length > 0) {
			window.scroll(0,0)
			this.showMessage = true;
			this.alertType = 'error-box';
			this.message = `Invalid field ${invalid[0]} ${invalid.length > 1 ? `+${invalid.length-1}` : ''}`
			this.spinner.hide()
			this.closeUpdateConfirmModal()
			return false
		}
		this.closeUpdateConfirmModal()
		this.hideError()
		if (value.amountDiff != 0) {
			return false
		}
		let date = value.jv_date
		if(this.updateJvForm.invalid){
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = 'Invalid Data, please validate data and try again.'
			return false
		}
		for (let i = 0; i < value.jvArray.length; i++) {
			value.jvArray[i].jv_date = date;
		}
		
		// await this.dateSelection(date)
		this.commonService.selectedInput.next(this.lastInputIndex+1)
		this.spinner.show()

		if(controls.currency_rate.value && controls.currency_code.value) {
			for(let item of value.jvArray){
				item['currency_rate'] = controls.currency_rate.value;
				item['currency_code'] = controls.currency_code.value;
			}
		}

		this.commonService.putRequest(`${environment.okountUrl}/jv/${this.jvId}`, value.jvArray).subscribe(async (res) => {
			let journal_code = res[0].journal_code
			this.showMessage = true
			res['old_transaction_no'] = journal_code
			// await this.doTransactionEntry(res)
			this.alertType = 'success-box';
			this.message = `JV Updated successfully.`
			this.spinner.hide();
		}, err => {
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = 'Failed to update JV.'
			if( err['error']['message']){
				this.message = err['error']['message']
			  }
			this.spinner.hide();
		})
	}

	reset() {
		this.updateJvForm.reset()
		this.jvArray.controls = []
		this.addJvRow()
	}

	openActivity(){
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/activity/activity-summary/jv/${this.jvId}`;
		if(window.location.protocol != 'file:'){
			window.open(path,'_blank')
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : path
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}

	closeWindow() {
		window.close();
	}

	deleteJV(jv_code){
		this.closeDeleteConfirmModal()
		this.hideError()
		this.commonService.selectedInput.next(this.lastInputIndex+1)
		this.commonService.deleteRequest(`${environment.okountUrl}/jv?journal_code=${jv_code}`).subscribe(res=>{
			this.showMessage = true
			this.alertType = 'success-box';
			this.message = `JV Deleted`
		},err=>{
			debugger
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = `Failed to delete JV.`
		})
	}

	hideError(){
		this.showMessage = false
	}
	resetCurrentModalType(){
		this.currentOpenedModal = ''
	}
	openDeleteConfirmModal(){
		if(this.commonService.gstFiledDate) {
			if(moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.jvData[0].jv_date).format('YYYY-MM-DD')) || moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.updateJvForm.controls.jv_date.value).format('YYYY-MM-DD'))) {
				this.showMessage = true
				this.spinner.hide()
				this.alertType = "error-box"
				this.message = 'Not Allowed to delete the jv.'
				return false;
			}
		}
		
		this.closeOpenedModals('delete')
		this.currentOpenedModal = 'delete'
		this.confirmDeleteModal.nativeElement.click()
		this.commonService.selectedInput.next(this.lastInputIndex+5)
	}
	closeDeleteConfirmModal(){
		this.currentOpenedModal = ''
		this.closeDeleteModal.nativeElement.click()
	}
	
	focusDeleteConfirm(){
		this.deleteConfirmBtn.nativeElement.focus()
	}
	openUpdateConfirmModal(){

		if(this.commonService.gstFiledDate) {
			if(moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.jvData[0].jv_date).format('YYYY-MM-DD')) || moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.updateJvForm.controls.jv_date.value).format('YYYY-MM-DD'))) {
				this.showMessage = true
				this.spinner.hide()
				this.alertType = "error-box"
				this.message = 'Not Allowed to update the jv.'
				return false;
			}
		}
		
		this.closeOpenedModals('update')
		this.currentOpenedModal = 'update'
		this.confirmUpdateModal.nativeElement.click()
		this.commonService.selectedInput.next(this.lastInputIndex+7)
	}
	closeUpdateConfirmModal(){
		this.currentOpenedModal = ''
		this.closeUpdateModal.nativeElement.click()
	}

	closeOpenedModals(modalName){
		if(modalName!='delete') this.closeDeleteConfirmModal()
		if(modalName!='update') this.closeUpdateConfirmModal()
	}
	focusUpdateConfirm(){
		this.updateConfirmBtn.nativeElement.focus()
	}

	openJvActivity(jvId) {
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/activity/activity-summary/jv/${jvId}`
		window.open(path,'_blank')
	}

	openJvPrint(jvId) {
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/jv/print/${jvId}`
		window.open(path,'_blank')
	}

	openSendEmailModal(){
		let primary_email = ''
		let secondary_email = ''
		let accounting_company = this.jvData && this.jvData.length>0 && this.jvData[0].accounting_company ? this.jvData[0].accounting_company : null;
		let subject = `JV. ${this.jvId}`
		let body = `Dear Travel Partner,\n\nPlease find Attached Jv ${this.jvId}`
		body+=`\n\nPlease do not hesitate to contact us if you have any query or concern about attached document.\n\nIt's been a pleasure to serve you.\n\nBest Regards\n\n${accounting_company?.name}\nAccounts Team`
		if(accounting_company && accounting_company.telephone){
			body += `\nContact Us on ${accounting_company.telephone}`
		}
		if(this.jvData && this.jvData.length>0 && this.jvData[0].client){
			primary_email = this.jvData[0].client.hasOwnProperty('primary_email')?this.jvData[0].client.primary_email:''
			secondary_email = this.jvData[0].client.hasOwnProperty('secondary_email')?this.jvData[0].client.secondary_email:''
		}
		this.sendEmailForm.controls.toEmails.setValue(primary_email)
		this.sendEmailForm.controls.bccEmails.setValue("")
		this.sendEmailForm.controls.ccEmails.setValue(secondary_email?.split(',').join('\n'))
		this.sendEmailForm.controls.subject.setValue(subject)
		this.sendEmailForm.controls.body.setValue(body)
		this.openEmailModal.nativeElement.click();
	}

	callTosendEmail(){
		let toEmails = this.sendEmailForm.value.toEmails
		let bccEmails = this.sendEmailForm.value.bccEmails?.split(",")
		let ccEmails = this.sendEmailForm.value.ccEmails?.split(",")
		let subject = this.sendEmailForm.value.subject
        let body = this.sendEmailForm.value.body
		if(toEmails){
			toEmails = toEmails.split(",")
			window.scrollTo(0, 0)
			let emails = {
				to_addresses:toEmails,
				cc_addresses:ccEmails,
				bcc_addresses:bccEmails
			}
			let pageUrl = `transactions/jv/print/${this.jvId}`
			const formData = new FormData();
			formData.append('email',JSON.stringify(emails))
			formData.append('page_url',pageUrl)
			formData.append('subject',subject)
			formData.append('email_body',body)
			formData.append('document_num',this.jvId)
			if(this.attachmentFile){
				formData.append('attachmentFile',this.attachmentFile)
			}
			let data = formData
			this.commonService.fileUpload(`${environment.okountUrl}/send_mail?transaction_type=jv`,data).subscribe((res : any)=> {
				this.showMessage = true;
				this.alertType = 'success-box'
				this.message = res.msg
				this.closebutton.nativeElement.click();
			},err => {
				this.showMessage = true
				this.alertType = "error-box"
				this.message = err.msg
				this.closebutton.nativeElement.click();
			 })
			}
	  }

	  async initDateValidation (){
	    this.minDate = moment().subtract(15, 'days').format('YYYY-MM-DD')
		try {
		let empConfiguration = this.commonService.employeeConfiguration;
			if(!empConfiguration || empConfiguration.length == 0){
				let loggedInUser = JSON.parse(localStorage.okountUserInfo);
				empConfiguration = await this.commonService.getConfiguration(loggedInUser.accountcode)
			}
			let config = this.commonService.getConfigurationByKey('jv')
			if(config){
				let dateConfig = config['value'].filter(data => data.key == 'minimum_update_date')
				let daysConfig = config['value'].filter(data => data.key == 'minimum_update_days')
				let dateWithMinDate = moment(dateConfig ? dateConfig[0].value : '')
				let dateWithMinDays = dateConfig && daysConfig[0].value ? moment().subtract(Number(daysConfig[0].value), 'days') : dateWithMinDate
				if(dateWithMinDays.isBefore(dateWithMinDate)){
					this.minDate = dateWithMinDate.format('YYYY-MM-DD')
				}else{
					this.minDate = dateWithMinDays.format('YYYY-MM-DD')
				}
			}
		} catch (error) {
			
		}
		this.maxDate = moment().format('YYYY-MM-DD')
		this.updateJvForm.controls.jv_date.setValidators([Validators.required,this.validateDate()])
		this.updateJvForm.controls.jv_date.updateValueAndValidity()

	}   

	onJvCurrencyChange(){
		this.updateJvForm.controls.currency_rate.setValue("");
	}
	handleAttachment(event: any) {
		this.attachmentFile = event.target.files[0];
	}

	jvDateValidation() {
		let financeYearResponse = this.commonService.checkFinancialYearValidation();
		this.financialYearDiff = financeYearResponse.isFinancialYearDiff;
		this.minDate = financeYearResponse.minDate;
		this.maxDate = financeYearResponse.maxDate;

		this.updateJvForm.controls.jv_date.setValue(this.maxDate);
		this.updateJvForm.controls.jv_date.setValidators([Validators.required, this.validateDate()]);
		this.updateJvForm.controls.jv_date.updateValueAndValidity()
	}

}
