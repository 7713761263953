import { Component, OnInit, ViewChild, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { CommonServiceService } from '../common-service.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalVariable } from '../global-variable';
import { DarkModeService } from 'angular-dark-mode';

import { InitialState } from "src/app/store/reducer";
import { NgRedux } from "@angular-redux/store";
import { Store } from '@ngrx/store';
import { AddInternalTabs, RemoveInternalTab, SetActiveTab, SetFinancialyear, SetLoaderTemplate, UpdateInternalTabs } from '../storev1/actions';
import { TabService } from '../services/tab.service';
import { Tab } from '../model/tab.model';
import { CookiesService } from '../cookies.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';

declare var electron: any;


@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	darkModeStatus = false;
	keyword = 'name';
	public placeholder: string = 'Search';
	data: any;
	modalSubscription : Subscription
	logoutModalSubscription : Subscription
	dropDownSubscription : Subscription
	count = 0
	selectedSearchType: any;
	settingImgSrc : string =''
	notificationImgSrc : string =''
	minimizeImgSrc : string =''
	maximizeImgSrc : string =''
	closeImgSrc : string =''
	@ViewChild('searchModal',{static : true}) searchModal: ElementRef;
	@ViewChild('logoutBtn',{static : true}) logoutBtn: ElementRef;
	@ViewChild('openDropDown',{static : true}) openDropDown: ElementRef;
	@ViewChild('closeModal',{static : true}) closeModal: ElementRef;
	@ViewChild('modalContent',{static : true}) modalContent: ElementRef;
	userInfo: any;
	shortHandName = "";
	isModalOpened = GlobalVariable.openModalStatus
	financialYear = "2022-2023"
	@Input() hasAccessToken;
	hideWinActionBtn: boolean = false;
	@Input() notificationCount
	@Output() callShowNotification : EventEmitter<any> = new EventEmitter();
	@Output() callSwitchVersion : EventEmitter<any> = new EventEmitter();

	// darkMode = this.darkModeService.darkMode$
	internalTabs = []
	activeTabIndex = 0
	tabs = new Array<Tab>();
  	selectedTab: number;
	showTabs: boolean = false;
	appTitle = 'Home'
	menuLoaded = false
	financialYears: any;
	branches = []
	parentAccount = []
	currentCompany = ''
	constructor(
		public commonService: CommonServiceService,
		// private darkModeService: DarkModeService,
		private router: Router,
		private ngRedux: NgRedux<InitialState>,
        private store: Store<InitialState>,
		private tabService: TabService,
		private cookiesService : CookiesService,
		private spinner: NgxSpinnerService,
	) { 
		// this.darkModeService.toggle();
		this.commonService.menuLoadedStatus.subscribe(res=> {
			this.menuLoaded = res
		})
		this.settingImgSrc = this.commonService.aws_asset_url+'/_assets/icons/light-mode/inactive-dev-tool.svg'
		this.notificationImgSrc = this.commonService.aws_asset_url+'/_assets/icons/light-mode/menues/inactive-notification.svg'
		this.minimizeImgSrc = this.commonService.aws_asset_url+'/_assets/icons/light-mode/inactive-minimize.svg'
		this.maximizeImgSrc = this.commonService.aws_asset_url+'/_assets/icons/light-mode/inactive-maximize.svg'
		this.closeImgSrc = this.commonService.aws_asset_url+'/_assets/icons/light-mode/inactive-close.svg'
		router.events.subscribe( (event) => {
			if (event instanceof NavigationEnd) {
				console.log('router Events : ',event)
				this.appTitle = document.getElementById('appTitle') ? document.getElementById('appTitle').innerText : 'Home'
				// if(this.internalTabs.length == 0){
				// 	let payLoad = {
				// 		name : this.appTitle,
				// 		route : event['url'],
				// 		queryParams : {}
				// 	}
				// 	this.store.dispatch(new AddInternalTabs(payLoad));	
				// }
				// if(this.internalTabs.length != 0 && this.activeTabIndex == 0 && this.internalTabs[0]['route'] != event['url']){
				// 	let payLoad = {
				// 		tabIndex : 0,
				// 		internalTabsData : {
				// 			name : this.appTitle,
				// 			route : event['url'],
				// 			queryParams : {}
				// 		}
				// 	}
				// 	this.store.dispatch(new UpdateInternalTabs(payLoad));	
				// }
				this.setSelectedTab(-1)
			}
		})
		this.store.select('TransactionReducers').subscribe((items)=>{
			if(items && items.hasOwnProperty('advanceSearchModalStatus')){
				if(items.advanceSearchModalStatus.status==true){
					this.openModal()
					this.commonService.setSearchModal(items.advanceSearchModalStatus.title)

				}

			}
		})
		this.store.select('sharedReducers').subscribe((items)=> {
			// if(items){
			// 	if(items.internalTabs){
			// 		this.internalTabs = items.internalTabs
			// 	}
				
			// 	if(items.activeTabIndex != undefined){
			// 		let data = this.internalTabs[items.activeTabIndex]
			// 		if(data){
			// 			this.activeTabIndex = items.activeTabIndex
			// 			this.router.navigateByUrl(data.route)
			// 		}
			// 	}
			// }
		})
		this.tabService.tabSub.subscribe(tabs => {
			this.tabs = tabs
			this.selectedTab = tabs.findIndex(tab => tab.active)
			console.log('TABS IN HEADER',tabs)
		});
		this.commonService.toggleTab.subscribe(showtab => this.showTabs = showtab)

	}

	ngOnInit() {
		// if(localStorage.getItem('financial_year')){
		// 	this.financialYear = localStorage.getItem('financial_year')
		// }else{
		// 	localStorage.setItem('financial_year',this.financialYear)
		// }
		this.getFinancialYears(5)

		if(sessionStorage.getItem('current_financial_year')){
			let financialYearObj = JSON.parse(sessionStorage.getItem('current_financial_year'))
			this.financialYear = this.commonService.getFinancialSession(moment(financialYearObj.start_date))
		} else {
			this.financialYear = this.commonService.getFinancialSession(moment());
			let startDate = `${this.financialYear}-04-01`
			let endDate = `${Number(this.financialYear)+1}-03-31`
			let financialYearObject = {
				start_date : startDate,
				end_date : endDate
			}
			sessionStorage.setItem('current_financial_year',JSON.stringify(financialYearObject))
			this.cookiesService.setCookie('SYSTACC_FINANCIAL_YEAR',JSON.stringify(financialYearObject))
		}
		if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
			this.hideWinActionBtn = false
		}else{
			this.hideWinActionBtn =true
		}
		// this.darkModeService.darkMode$.subscribe(status => {
		// 	this.darkModeStatus = !status
		// 	this.notificationImgSrc = commonService.aws_asset_url+'/_assets/icons/${this.darkModeStatus? 'dark' : 'light'}-mode/menues/inactive-notification.svg`
		//   })
		this.activeTabIndex = 0

        let userData = JSON.parse(this.cookiesService.getCookie('SYSTACC_USER_LOGIN_DATA'));
		if(userData && userData.t_tenant_info) {
            if(userData.t_tenant_info.branch_name) {
                this.currentCompany = userData.t_tenant_info.branch_name;
            }else {
                this.currentCompany = userData.t_tenant_info.tenant_company_name;
            }
        }
        let branch_type = userData.t_tenant_info && userData.t_tenant_info.branch_type;
        if(branch_type && branch_type == 'HQ') {
            if(!this.branches || this.branches.length==0) {
                this.getBranchesData()
            }
        } else {
            let parent_id = userData.t_tenant_info && userData.t_tenant_info.parent_id;
            if(!this.parentAccount || this.parentAccount.length==0) {
				this.getBranchParent(parent_id);
            }
        }
	}

// 	  @HostListener('document:mousedown', ['$event'])
//      onGlobalClick(event): void {

//     //  if (!this.elementRef.nativeElement.contains(event.target)) {
//         // clicked outside => close dropdown list
//     //  this.isOpen = false;
//     //  }
//   }

  @HostListener('document:click')
  clickout() {
     	GlobalVariable.openGlobalSearch = false;
  }
	
	ngAfterViewInit(){
		this.modalSubscription = this.commonService.searchModalstatus.subscribe((data : any)=>{
			console.log('Modal Name in Subscriber : ',data)
			if (data != 'empty') {
				this.selectedSearchType = data;
				this.openModal()
			}
		})


		this.logoutModalSubscription = this.commonService.openLogoutModalstatus.subscribe((status : any)=>{
			if (status == true) {
				console.log('Open logout Modal')
				this.openLogoutModal()
			}
			
		})
		this.dropDownSubscription = this.commonService.searchDropDownstatus.subscribe((data : any)=>{
			if (data != false) {
				this.openDropdownFn()
			}
		})
		if (localStorage.getItem('okountUserInfo') != null && localStorage.getItem('okountUserInfo') != undefined) {
			this.userInfo = JSON.parse(localStorage.getItem('okountUserInfo'))
			this.getInitialName(this.userInfo.name)
		}
	}
	ngOnDestroy() {
		if(this.modalSubscription){
			this.modalSubscription.unsubscribe();
		}
		if(this.dropDownSubscription){
			this.dropDownSubscription.unsubscribe();
		}
		if(this.logoutModalSubscription){
			this.logoutModalSubscription.unsubscribe();
		}
	}
	
	openModal(){
		this.searchModal.nativeElement.click();
		this.setModalStatus()
		this.closeLogoutModalFn()
		setTimeout(() => {
		this.isModalOpened = GlobalVariable.openModalStatus
		this.commonService.selectedSearchInput.next(0)
		}, 500);
	}
	setLogoutMOdalStatus(status){
		this.commonService.setLogOutModalStatus(status)
	}

	openLogoutModal(){
		this.logoutBtn.nativeElement.click();
		setTimeout(() => {
			this.closeLogoutModalFn()
			this.commonService.selectedInput.next(1)
		}, 100);
	}
	closeLogoutModalFn(){
		this.closeModal.nativeElement.click()
	}

	closeModalFn() {
		GlobalVariable.openModalStatus = false;
		this.isModalOpened = GlobalVariable.openModalStatus
		this.closeModal.nativeElement.click();
	}

	logout() {
		this.closeLogoutModalFn()
		this.commonService.setLogOutModalStatus(false)
		localStorage.clear()
		this.commonService.showLogout(false)
		this.cookiesService.deleteCookie('SYSTACC_ACCESS_TOKEN')
        this.cookiesService.deleteCookie('SYSTACC_FINANCIAL_YEAR')
        this.cookiesService.deleteCookie('SYSTACC_USER_LOGIN_DATA')
		// this.router.navigate(['/login']);
		window.location.href = environment.accountManagement+"/signin"
	}

	setModalStatus(){
		this.commonService.setModalStatus(true)
		
	}
	openDropdownFn(){
		this.openDropDown.nativeElement.click();
	}
	selectSearchType(group) {
		this.selectedSearchType = group
		console.log('Selected group : ',group)
		// this.commonService.selectedSearchInput.next(1)
	}

	globalSearch(event) {
        if (event.length > 2) {
			GlobalVariable.openGlobalSearch = true;
            this.commonService.getRequestNoloader(`${environment.okountUrl}/globalSearch/${this.selectedSearchType ? this.selectedSearchType : 'invoice'}/freetext?freetext=${event}`).subscribe(async (res) => {
                this.data = res
            })
		}
    }

	openData(data) {
		if (this.selectedSearchType == 'invoice') {
			this.router.navigateByUrl(`transactions/invoice/view/${data.reference}`)
		}
		else if (this.selectedSearchType == 'payment' || this.selectedSearchType == 'receipt' || this.selectedSearchType == 'jv') {
			this.router.navigateByUrl(`transactions/${this.selectedSearchType}/view/${data.reference}`)
		}else if(this.selectedSearchType == 'ledger'){
			this.router.navigateByUrl(`display/account-book/ledger/normal-ledger?viewMode=normal&client=${data.account_code}`)
		}else if(this.selectedSearchType == 'adm_acm'){
			this.router.navigateByUrl(`transactions/adm-acm/view/${data.reference}`)
		}
	}

	// logout() {
	// 	localStorage.removeItem("token")
	// 	localStorage.removeItem("userInfo")
	// 	this.commonService.showLogout(false)
	// 	this.router.navigate(['/login']);
	// }

	getInitialName(fullName) {
		if (fullName != undefined) {
			let nameArr = fullName.split(" ")
			let shorthandName = ""
			if (nameArr.length == 1) {
				shorthandName = nameArr[0].substring(0, 2)
			} else if (nameArr.length > 1)
				for (const key in nameArr) {
					if (nameArr.hasOwnProperty(key)) {
						if (Number(key) <= 1) {
							const element = nameArr[key];
							shorthandName += element.substring(0, 1)
						}
					}
				}
			this.shortHandName = shorthandName
			console.log('Shorthand name ', this.shortHandName)
		}
	}



	mimimizeWin(){
		window.dispatchEvent(new Event('minimize-app'))
		this.notificationImgSrc = this.commonService.aws_asset_url+'/_assets/icons/dark-mode/menues/inactive-notification.svg';
	}

	maximizeWin(){
		window.dispatchEvent(new Event('maximize-app'))
		this.notificationImgSrc = this.commonService.aws_asset_url+'/_assets/icons/dark-mode/menues/inactive-notification.svg';
	}
	closeWin(){
		window.dispatchEvent(new Event('close-app'))
	}

	openDevtools(){
		window.dispatchEvent(new Event('handle-dev-tools'));
		this.notificationImgSrc = this.commonService.aws_asset_url+'/_assets/icons/dark-mode/menues/inactive-notification.svg';

	}
	showNotificationArea(){
		this.callShowNotification.emit()
		this.notificationImgSrc = this.commonService.aws_asset_url+'/_assets/icons/dark-mode/menues/active-notification.svg';
	}
	switchVersion(){
		this.callSwitchVersion.emit(false)
	}
	// changeTheme(): void {
	// 	this.darkModeService.toggle();
	//   }

	setActiveTab (i){
		this.store.dispatch(new SetActiveTab({
			tabIndex : i
		}))
	}
	closeTab(i){
		this.store.dispatch(new RemoveInternalTab({
			tabIndex : i
		}))
	}

	// addNewTab() {
	// 	this.tabService.addTab(
	// 	  new Tab(Comp1Component, `Comp1 ${this.tabs.length} View`, { parent: "AppComponent" })
	// 	);
	//   }
	setSelectedTab(index: number): void {
		if(index <0){
			this.commonService.toggleTabs(false)
			this.tabService.setActive(-1)
		}else{
			this.commonService.toggleTabs(true)
			this.tabService.setActive(index);
		}
	}

	removeTab(index: number): void {
		this.tabService.removeTab(index);
	}

	openAccountPage(path){
		window.location.href = `${environment.accountManagement}/${path}`
		// this.router.navigateByUrl(`${environment.accountManagement}/${path}`)
	}
	getFinancialYears(totalFinancialYear){
		let financialYearList = Array.from({length: totalFinancialYear}, (v, i) => i)
		this.financialYears = []
		for (const fy of financialYearList) {
		  let data = moment().subtract(fy,'y').format('YYYY-MM-DD')
		  let fySession = this.commonService.getFinancialSession(data)
		  let fySessionTill = this.commonService.getFinancialSession(moment(data).add(1,'y'))
		  fySessionTill = ""+fySessionTill
		  fySessionTill = fySessionTill.substring(fySessionTill.length-2)
		  this.financialYears.push({
			key : `${fySession}-${fySessionTill}`,
			value : fySession
		  })
		}
		console.log(`LAST ${totalFinancialYear} FINANCIAL_YEARS`,this.financialYears)
	  }
  

	setFinancialYear(e){
		let startYear = e.target.value
		let startDate = `${startYear}-04-01`
		let endDate = `${Number(startYear)+1}-03-31`
		let financialYearObject = {
		  start_date : startDate,
		  end_date : endDate
		}
		sessionStorage.setItem('current_financial_year',JSON.stringify(financialYearObject))
		this.cookiesService.setCookie('SYSTACC_FINANCIAL_YEAR',JSON.stringify(financialYearObject))
		this.financialYear = e.target.value
		this.store.dispatch(new SetFinancialyear(financialYearObject));
		this.store.dispatch(new SetLoaderTemplate(`
			<p 
				class="loading"
				style="color: white;
				padding-top: 30px;">
				Swithching to FY-${this.financialYear}
				</p>
		`));
		this.spinner.show();
		setTimeout(() => {
			this.spinner.hide()
			window.location.href = `${environment.accountManagement}?FY=${this.financialYear}`
			// this.router.navigate(["/"]).then(resp => {
			// 	this.store.dispatch(new SetLoaderTemplate(''));
			// 		if(window && window.location && window.location.reload) window.location.reload()
			// 	})
		}, 2000);
		
	  }


	onBranchChange(branchEvent, tenantEvent) {
		let branchId = branchEvent && branchEvent.target && branchEvent.target.value ? branchEvent.target.value : null
		let tenant_id = tenantEvent && tenantEvent.target && tenantEvent.target.value ? tenantEvent.target.value : null
		if(branchId || tenant_id) {
			window.location.href = `${environment.accountManagement}?tenant_id=${tenant_id ? tenant_id : ''}&branch_id=${branchId ? branchId : ''}`;
		}
	}

	getBranchesData() {
            this.commonService.getRequestNoloader(`${environment.okountUrl}/branch/invite`).subscribe(async (res) => {
				if(res && res['data']) {
					this.branches = res['data'];
				}
            })

    }


	getBranchParent(tenant_id) {
		if(tenant_id) {
			this.commonService.getRequestNoloader(`${environment.okountUrl}/user/tenant?tenant_id=${tenant_id}`).subscribe(async (res) => {
				if(res && res['data']) {
					this.parentAccount = res['data'];
				}
			})
		}
    }
}