import { Component, HostListener, OnInit } from '@angular/core';

import { CommonServiceService } from 'src/app/shared/common-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import * as moment from 'moment-timezone';
import * as FileSaver from 'file-saver'; 
import { Store } from '@ngrx/store';
import { InitialState } from "src/app/store/reducer";
import { OpenAdvanceSearchModal } from 'src/app/transactions/store/actions';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GlobalVariable } from 'src/app/shared/global-variable';
import { TabService } from 'src/app/shared/services/tab.service';
import { NormalLedgerComponent } from 'src/app/display/account-book/ledger/normal-ledger/normal-ledger.component';
import { Tab } from 'src/app/shared/model/tab.model';


@Component({
  selector: 'app-view-balance-sheet',
  templateUrl: './view-balance-sheet.component.html',
  styleUrls: ['./view-balance-sheet.component.scss']
})
export class ViewBalanceSheetComponent implements OnInit {
    balanceSheetData: any;
	valid: boolean = false;
	tablelayout: boolean = false;
	columnlayout: boolean = true;
	p;
	zeroAmount;
	currentDate = moment().format('YYYY-MM-DD')
	innerHeight: string;
	duration;
	queryParams;
	reInitialise: boolean;
	navigationSubscription: any;
	selectedData : any;
	reportType = 'balanceSheet'

	currentSide = 'left'
	currentIndex = [0]
	currentActiveId = ''
	isMainTabVisible = true
	waterMark;
	showWaterMark =false;
	constructor(
		public commonService: CommonServiceService,
		private spinner: NgxSpinnerService,
		private store: Store<InitialState>,
		private route: ActivatedRoute,
		private router: Router,
		private tabService : TabService
	) {
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			// If it is a NavigationEnd event re-initalise the component
			if (e instanceof NavigationEnd) {
				if(e['url'].includes('report/profit-loss')){
					this.reportType = 'profit_loss'
				}else{
					this.reportType = 'balance_sheet'
				}
				this.initialiseInvites();
			}
		});
	}

	ngOnInit() {

		let queryParams = this.route.queryParams['value']
		this.queryParams = Object.assign({}, queryParams)
		this.getProfitLossData()
		localStorage.setItem('activeBalanceSheetQueryParams', JSON.stringify(queryParams))
		this.reInitialise = true

		this.tabService.tabSub.subscribe(tabs => {
			let selectedTab = tabs.findIndex(tab => tab.active)
			this.isMainTabVisible = false
			if(selectedTab == -1){
				this.isMainTabVisible = true
			}
			console.log('TABS IN BAL :',selectedTab)
		});
	}

	initialiseInvites() {
		if (this.reInitialise) {
			this.ngOnInit()
		}
	}

	ngOnDestroy() {
		// avoid memory leaks here by cleaning up after ourselves. If we  
		// don't then we will continue to run our initialiseInvites()   
		// method on every navigationEnd event.
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
	}

	openAdvanceSearchModal(){
		// this.store.dispatch(new OpenAdvanceSearchModal(true));
		this.store.dispatch(new OpenAdvanceSearchModal({status: true,title : 'balance-sheet'}));	

	}

	getProfitLossData() {
		this.spinner.show()
		this.queryParams['reportType'] = this.reportType
		this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/balancesheetv2`,this.queryParams).subscribe(res => {
			this.balanceSheetData = res
			if(res && res['accounting_company'] && res['accounting_company'].tenant_logo) {
				this.waterMark = `url('${res['accounting_company'].tenant_logo}')`
			}
			if(res['duration']){
				this.duration = res['duration']
				res['duration'].from ? this.queryParams.start_date = moment(res['duration'].from).format('YYYY-MM-DD') : null
				res['duration'].to ? this.queryParams.end_date = moment(res['duration'].to).format('YYYY-MM-DD') : null
				localStorage.setItem("activeBalanceSheetQueryParams",JSON.stringify(this.queryParams))
			}
			
			this.valid = true
			this.spinner.hide()
			this.changeSide('left')
		}, err => {
			console.error(err)

		})
	}

  print() {
	  this.showWaterMark = true
	  setTimeout(() => {
		  window.print()
		  this.showWaterMark = false
		}, 10);
  }
  columnview(){
	this.tablelayout = false
	this.columnlayout = true
}
tableview(){
	this.tablelayout = true
	this.columnlayout = false
	}
	
	setHeight(height) {
		if (height == undefined) {
			height = window.innerHeight
		}
		this.innerHeight = height - 250 + 'px';
		
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.setHeight(event.target.innerHeight)
		
	}

	openDetailedScreen(data, accounts,elementId){
		this.moveToIndex(elementId)
		if(data.hierarchy > 1 || accounts.length > 0 ){
			if(data.hierarchy > 1 ) this.openLedger(data.account_code,data.tenant_id)
			this.toggleData(data)
			return
		} 
		let branchQuery = ''
		if(this.queryParams && this.queryParams.branch){
			branchQuery = `&branch=${this.queryParams.branch}`
		}
		
		this.spinner.show()
			this.commonService.getRequest(`${environment.okountUrl}/balancesheetv2/hierarchy?id=${data.id}&account_type=${data.account_type}&hierarchy=${data.hierarchy}&name=${data.name}&account_code=${data.account_code}&start_date=${this.queryParams.start_date}&end_date=${this.queryParams.end_date}${branchQuery}`).subscribe(res => {
			if(res['duration']){
					this.duration = res['duration']
			}
			if((!res["data"]) ||(!res["data"].length)) this.openLedger(data.account_code,data.tenant_id)


			if(data.account_type == "ASSETS" || data.account_type == "EXPENSE" ) {
				if(data.hierarchy == 0) {
					let selectedAccount = this.balanceSheetData.assets.account.find(acc => acc.account_code == data.account_code)
					selectedAccount.account = res['data']
					this.selectedData = this.balanceSheetData.assets.account.find(acc => acc.account_code == data.account_code)
				}

				if(data.hierarchy > 0) {
				 if(this.selectedData.account_code != data.parent) {
					this.selectedData = this.balanceSheetData.assets.account.find(acc => acc.account_code == data.parent)
				 }
				 let selectedChild = this.selectedData.account.find(acc => acc.account_code == data.account_code)
				  if(res['data'] && res['data'].length > 0) {
					selectedChild.hasChild = true
				  }
				  selectedChild.account = res['data']
				  let parent = this.balanceSheetData.assets.account.find(acc => acc.account_code == this.selectedData.account_code)
				  let child = parent.account.find(acc => acc.account_code == selectedChild.account_code)
				  child = selectedChild
				}		
			} 


			  if(data.account_type == "LIABILITY" || data.account_type == "INCOME") {
				if(data.hierarchy == 0) {
					let selectedAccount = this.balanceSheetData.liabilities.account.find(acc => acc.account_code == data.account_code)
					selectedAccount.account = res['data']
					this.selectedData = this.balanceSheetData.liabilities.account.find(acc => acc.account_code == data.account_code)
				}

				if(data.hierarchy > 0) {
					if(this.selectedData.account_code != data.parent) {
					this.selectedData = this.balanceSheetData.liabilities.account.find(acc => acc.account_code == data.parent)
				  }
				  let selectedChild = this.selectedData.account.find(acc => acc.account_code == data.account_code)
				  if(res['data'] && res['data'].length > 0) {
					selectedChild.hasChild = true
				  }
				  selectedChild.account = res['data']
				  let parent = this.balanceSheetData.liabilities.account.find(acc => acc.account_code == this.selectedData.account_code)
				  let child = parent.account.find(acc => acc.account_code == selectedChild.account_code)
				  child = selectedChild
				}
			}


			this.valid = true
			this.spinner.hide()
			this.toggleData(data)
		}, err => {


		})

	}


	openLedger(account_code,tenant_id){
		let queryParams = Object.assign({},this.route.queryParams['value'])
		if (account_code != undefined) {
			queryParams['client'] = account_code
		}
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/display/account-book/ledger/normal-ledger?client=${account_code}&start_date=${queryParams.start_date}&end_date=${queryParams.end_date}`
		if(window.location.protocol != 'file:'){
			if(account_code) {
				this.tabService.addTab(
					new Tab(NormalLedgerComponent, `Ledger-${account_code}`, { client: account_code , viewMode : 'view', 
					start_date: queryParams.start_date?queryParams.start_date:'', end_date: queryParams.end_date?queryParams.end_date:'',bf_balance: this.reportType == 'profit_loss' ? false : true,branch:tenant_id
				}, `Normal Ledger - ${account_code}`)
				);
			} else {
				window.open(path,"_blank");
			}
		}
		else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : path
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}

		// http://localhost:4200/#/display/account-book/ledger/normal-ledger?client=C0065
		// window.open(`/#/display/account-book/ledger/normal-ledger?{queryParams: queryParams}`, "_blank");
		// this.router.navigate(['/display/account-book/ledger/normal-ledger'], { queryParams: queryParams });
	}

	toggleData (data) {
		// if(data.account_type == "LIABILITY" || data.account_type == "INCOME") {
		// 	if(data.hierarchy == 0) {
		// 		let selectedAccount = this.balanceSheetData.liabilities.account.find(acc => acc.id == data.id)
		// 	    selectedAccount.account = []
		// 	}
			
		// 	if(data.hierarchy == 1) {
		// 	    let parent = this.balanceSheetData.liabilities.account.find(acc => acc.id == data.parent_id)
		//         let child = parent.account.find(acc => acc.id == data.id)
		// 	    child.account = []
		// 	}
		// }
		// if(data.account_type == "ASSETS" || data.account_type == "EXPENSE") {
		// 	if(data.hierarchy == 0) {
		// 		let selectedAccount = this.balanceSheetData.assets.account.find(acc => acc.id == data.id)
		// 	    selectedAccount.account = []
		// 	}

		// 	if(data.hierarchy == 1) {
		// 	    let parent = this.balanceSheetData.assets.account.find(acc => acc.id == data.parent_id)
		//         let child = parent.account.find(acc => acc.id == data.id)
		// 	    child.account = []
		// 	}
		// }
		data.showData = !data.showData
	}



	profitLossCalculation(account_type) {
		this.commonService.getRequest(`${environment.okountUrl}/balancesheet?start_date=${this.queryParams.start_date}&end_date=${this.queryParams.end_date}&balanceTillHierarchy=1`).subscribe(res => {
			let response = res
			if(account_type == "ASSETS") {
				let selectedAccount = this.balanceSheetData.assets.account.find(acc => acc.name == "CAPITAL")
				let capitalAccount = response['assets'].account.find(acc => acc.name == "CAPITAL")
				selectedAccount.balance = capitalAccount.balance
			}
			
			if(account_type == "LIABILITY") {
				let selectedAccount = this.balanceSheetData.liabilities.account.find(acc => acc.name == "CAPITAL")
				let capitalAccount = response['liabilities'].account.find(acc => acc.name == "CAPITAL")
				selectedAccount.balance = capitalAccount.balance
			}
		 })

	}

	onKeyDown(e: any) {
		console.log('openModalStatus : ' + GlobalVariable.openModalStatus)
		if (GlobalVariable.openModalStatus == false && GlobalVariable.openGlobalSearch == false && this.isMainTabVisible) {
			if (e.keyCode == 40) { // down key
				e.preventDefault();
				this.moveToNext()
			} else if (e.keyCode === 38) { // up key
				e.preventDefault();
				this.moveToPrev()
			}else if(e.keyCode === 39){ // right key
				if(this.currentSide != 'right'){
					this.changeSide('right')
				}

			}else if(e.keyCode === 37){ // left key
				if(this.currentSide != 'left'){
					this.changeSide('left')
				}
			}
		}
	}

	moveToNext(isRecursiveCall = false) {
		let nextFoundIndex = ''
		
		let currentIndexCopy = JSON.parse(JSON.stringify(this.currentIndex))
		let current = currentIndexCopy.join('_')
		let nextIndexCheck =  `${this.currentSide}_${current}`
		let incr = ''
		if(!isRecursiveCall){
			incr = '_0'
		}
		nextIndexCheck = `${nextIndexCheck}${incr}`
		
		let hasIndex = document.getElementById(`${nextIndexCheck}`)
		if(hasIndex){
			incr ? currentIndexCopy.push(0) : null
			nextFoundIndex = nextIndexCheck
		}else{
			let nexIn = Number(currentIndexCopy[currentIndexCopy.length-1])+1
			currentIndexCopy[currentIndexCopy.length-1] = nexIn
			current = currentIndexCopy.join('_')
			nextIndexCheck = `${this.currentSide}_${current}`
			hasIndex = document.getElementById(`${nextIndexCheck}`)
			if(!hasIndex){
				if(currentIndexCopy.length > 1){
					currentIndexCopy.pop()
					currentIndexCopy[currentIndexCopy.length-1] = Number(currentIndexCopy[currentIndexCopy.length-1])+1
					this.currentIndex = currentIndexCopy
					this.moveToNext(true)
				}
			}else{
				nextFoundIndex = nextIndexCheck
			}
		}
		if(hasIndex != null){
			// this.currentActiveId ? document.getElementById(`${this.currentActiveId}`).classList.remove('active') : null
			this.currentActiveId = nextIndexCheck
			// document.getElementById(`${nextIndexCheck}`).classList.add('active')
			let nextElement = document.querySelector(`#${nextIndexCheck} .account-name-div`) as HTMLElement
			nextElement.focus()
			console.log(`FOCUS TRIGGER : (${nextIndexCheck}) : `,document.getElementById(`${nextIndexCheck}`))
		}
		if(nextFoundIndex){
			this.currentIndex = currentIndexCopy
		}
		console.log(this.currentIndex)
		// return nextFoundIndex
	}
	moveToPrev(){
		let current = ''
		let prevIndexCheck = ''
		let currentIndexCopy = JSON.parse(JSON.stringify(this.currentIndex))
		if(currentIndexCopy[currentIndexCopy.length-1]-1 < 0){
			currentIndexCopy.pop()
		}else{
			currentIndexCopy[currentIndexCopy.length-1] = Number(currentIndexCopy[currentIndexCopy.length-1])-1
			let lastElement = this.findLastChild(this.currentSide,currentIndexCopy)
			if(lastElement){
				this.moveToIndex(lastElement)
				let nextElement = document.querySelector(`#${lastElement} .account-name-div`) as HTMLElement
				nextElement.focus()
				return
			}
		}
		current = currentIndexCopy.join('_')
		prevIndexCheck =  `${this.currentSide}_${current}`
		let hasIndex = document.getElementById(`${prevIndexCheck}`)
		if(hasIndex != null){
			// this.currentActiveId ? document.getElementById(`${this.currentActiveId}`).classList.remove('active') : null
			this.currentActiveId = prevIndexCheck
			this.currentIndex = currentIndexCopy
			// document.getElementById(`${prevIndexCheck}`).classList.add('active')
			let nextElement = document.querySelector(`#${prevIndexCheck} .account-name-div`) as HTMLElement
			nextElement.focus()
			console.log(`FOCUS TRIGGER (${prevIndexCheck}) : `,document.getElementById(`${prevIndexCheck}`))
		}
		console.log(this.currentIndex)
		// return `${side}_${currentIndex.join('_')}`
	}
	moveToIndex(index){
		let indexes = index.split('_')
		this.currentSide = indexes[0]
		indexes.shift()
		this.currentIndex = indexes
	}
	changeSide(sideName) {
		this.currentSide = sideName
		this.currentIndex = [0]
		this.moveToNext(true)
	}

	findLastChild(side,currentIndexCopy) {
		let current = currentIndexCopy.join('_')
		let found = document.querySelectorAll(`[id*="${side}_${current}_"]`)
		let lastElement = found && found.length>1 ? found[found.length-1] : null
		console.log(found.length>1 ? found[found.length-1] : null)
		if(lastElement){
			return lastElement.id
		}
	}

	download(){
		this.spinner.show()
		this.queryParams['reportType'] = this.reportType
		
		this.commonService.downloadPDF(`${environment.okountUrl}/balancesheetv2/download`,this.queryParams).subscribe((fileBlob:any) =>{
			let fileName = `${this.reportType} startDate=${this.queryParams.start_date} endDate=${this.queryParams.end_date}.xlsx` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/xlsx'
			});
			FileSaver.saveAs(blob, fileName);
	   })
	}
	
}



