import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  EventEmitter,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ReceiptV2Service } from "../services/receiptv2.service";
import { CommonServiceService } from "src/app/shared/common-service.service";
import { FormBuilder, FormArray, Validators, NgForm ,FormGroup, AbstractControl} from "@angular/forms";
import * as moment from "moment";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { CreateReceiptComponent } from "../create-receipt/create-receipt.component";
import { Store } from "@ngrx/store";
import { InitialState } from "src/app/store/reducer";
import { OpenAdvanceSearchModal } from "../../store/actions";

@Component({
  selector: "app-v2-create-receipt",
  templateUrl: "./v2-create-receipt.component.html",
  styleUrls: ["./v2-create-receipt.component.scss"],
  providers: [ReceiptV2Service]
})
export class V2CreateReceiptComponent implements OnInit {
  @Input() data;
  keyword = "name";
  @ViewChild("amountField", { static: true }) amountField: ElementRef;
  @Input() isUpdate;
  @ViewChild('bankAC', { static: true }) bankAC;
  @ViewChildren('accountAC') accountAC: QueryList<ElementRef>;
  @ViewChildren('subLedgerAC') subLedgerAC: QueryList<ElementRef>;
  @ViewChild("otherAccountAC", { static: true }) otherAccountAC;
  @ViewChild("accountType", { static: true }) accountType: ElementRef;
  // @ViewChild("confirmModal", { static: true }) confirmModal: ElementRef;
  // @ViewChild("updateConfirmModal", { static: true })
  @ViewChild('confirmModal',{static:true}) openConfirmationModal : ElementRef
	@ViewChild('closeConfirmModal',{static:true}) closeConfirmationModal : ElementRef
  @ViewChild('closeSendEmailModal') closebutton;
	@ViewChild('openEmailModal') openEmailModal;
  @ViewChild("openSuccessModal", { static: true }) openSuccessModal;
  @ViewChild("openMasterCreationModal", { static: true }) masterCreationModal : ElementRef;
  @ViewChild("closeMasterCreationModal", { static: true }) closeMasterCreationModal : ElementRef;
  
  updateConfirmModal: ElementRef;
  @ViewChild("closeModal", { static: true }) closeModal: ElementRef;
  shortcuts: ShortcutInput[] = [];
  @Output() callExitEntry: EventEmitter<any> = new EventEmitter();
  accountAutoComplete: boolean = false;
  generalMasterList: any = [];
  receiptArray: FormArray;
  sendEmailForm:FormGroup
  updateJvForm: any;
  updateReceipt: any;
  accountList = [];
  ledgerList = [];
  receiptCode: any;
  receiptData = [];
  mode = "save";
  searchId;
  showMessage: boolean = false;
  alertType = '';
  message: string = ``;
  previousTabCount = 2
  submitResponse
  confirmationModalType = ''
  clientData:any
  minDate;
  maxDate;
  newReceipt: boolean = true;
  totalAmount: number;

	copyELementTitle = 'Click to Copy'
  attachmentFile: File | null = null;


  showAdjustbtn: boolean = false;
  showCreateButton: boolean = false;
  currentMaster: any;
  currentMasterName ='';
  currentFormControlName;
  isModalOpened: boolean = false;
  private activeElementBeforeModal: HTMLElement
	gstRate:number = 18
  financialYearDiff = false;
  constructor(
    private spinner: NgxSpinnerService,
    public receiptService: ReceiptV2Service,
    public commonService: CommonServiceService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<InitialState>
  ) {
    this.route.paramMap.subscribe((params) => {
      this.mode = params["params"].mode;
      this.searchId = params["params"].id;
    });

    this.sendEmailForm = this.fb.group({
			toEmails: ["",Validators.required],
			bccEmails: [''],
			ccEmails:[''],
      subject:[''],
      body:['']
		})
  }

  async ngOnInit(){
    if(this.data) {
      this.searchId = this.data.receiptRef,
      this.mode = this.data.mode
     }
    // if (this.isUpdate) {
    // 	if (this.receiptService.receiptEntryForm.value.account_code_from_type != '') {
    // 		this.getAccounts(this.receiptService.receiptEntryForm.value.account_code_from_type)
    // 	}else{
    // 		// this.getAccounts('client')
    // 	}

    // 	if (this.receiptService.receiptEntryForm.value.sub_ledger_code_1_type != '') {
    // 		this.getLedgers(this.receiptService.receiptEntryForm.value.sub_ledger_code_1_type)
    // 	}else{
    // 		// this.getLedgers('client')
    // 	}
    // }
    // if (this.accountType != undefined) {
    // 	this.accountType.nativeElement.focus()
    // }
    this.receiptService.receiptEntryForm.valueChanges.subscribe(
      (selectedValue) => {
        console.log("address changed");
        console.log(selectedValue);
      }
    );
    this.receiptService.initialiseFormData({});

    let empConfiguration = this.commonService.employeeConfiguration;
    if(!empConfiguration || empConfiguration.length == 0){
      let loggedInUser = JSON.parse(localStorage.okountUserInfo);
      empConfiguration = await this.commonService.getConfiguration(loggedInUser.accountcode)
    }

    let globalSettingConfig = this.commonService.globalSettingConfig;
		if(!globalSettingConfig || globalSettingConfig.length == 0) {
			globalSettingConfig = await this.commonService.getGlobalSettings();
		}
			
    this.receiptDateValidation();
    
    // this.getGeneralMaster("general/bank");
    if (this.mode == "view") {
      await this.getReceiptDetails();
    } else {
      this.receiptService.receiptEntryForm.controls.receipt_date.setValue(
        moment().format("YYYY-MM-DD")
      );
      this.receiptService.receiptEntryForm.controls.receipt_code.setValue("");
      this.receiptService.addRow(this.receiptService.receiptEntryForm.value);
    }

    let queryParams = this.route.queryParams['value'];
    if(queryParams && queryParams['ubr_id']) {
       this.getUbrData(queryParams['ubr_id']);
    } 
  }

  validateDate(){
		return (control: AbstractControl) => {
			let valid = true
			if(this){
				if (moment(control.value).isBefore(this.minDate) || moment(control.value).isAfter(this.maxDate)) {
					if(this.receiptData && this.receiptData.length > 0 && this.receiptData[0].receipt_date && moment(control.value,'YYYY-MM-DD').isSame(moment(this.receiptData[0].receipt_date,'YYYY-MM-DD'))){
						valid = true
					}else{
						valid = false
					}
				}
			}
			return !valid ? {receipt_date: {value: control.value}} : null;
		};
	}

  checkDateOfEmployee() {
    try {
      this.minDate = moment().subtract(15, 'days').format('YYYY-MM-DD')
      this.maxDate = moment().format('YYYY-MM-DD')
      
			let config = this.commonService.getConfigurationByKey('receipt')
			if(config){
				let dateConfig = config['value'].filter(data => data.key == `minimum_${this.mode == 'view' ? 'update' : 'creation'}_date`)
				let daysConfig = config['value'].filter(data => data.key == `minimum_${this.mode == 'view' ? 'update' : 'creation'}_days`)
				let dateWithMinDate = moment(dateConfig ? dateConfig[0].value : '')
        let dateWithMinDays = dateConfig && daysConfig[0].value ? moment().subtract(Number(daysConfig[0].value), 'days') : dateWithMinDate
				if(dateWithMinDays.isBefore(dateWithMinDate)){
					this.minDate = dateWithMinDate.format('YYYY-MM-DD')
				}else{
					this.minDate = dateWithMinDays.format('YYYY-MM-DD')
				}
        this.receiptService.receiptEntryForm.controls.receipt_date.setValidators([Validators.required,this.validateDate()])
        this.receiptService.receiptEntryForm.controls.receipt_date.updateValueAndValidity()
			}
		} catch (error) {
			
		}
  }

  ngAfterViewInit(){

		this.shortcuts.push(			
			{
				key: "alt + s",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Save Receipt Entry",
				description: "Save Recept Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("save_receipt_btn")){

				document.getElementById("save_receipt_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
      {
				key: "alt + e",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Update Receipt Entry",
				description: "Update Recept Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("update_receipt_btn")){

				document.getElementById("update_receipt_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
      {
				key: "alt + d",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Delete Receipt Entry",
				description: "Delete Recept Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("delete_receipt_btn")){

				document.getElementById("delete_receipt_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
      {
				key: "alt + j",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Open Journal",
				description: "Open Journal",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("journal_receipt_btn")){

				document.getElementById("journal_receipt_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
      {
				key: "alt + l",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "View Activit Log",
				description: "View Activit Log",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("receipt_activity_log")){

				document.getElementById("receipt_activity_log").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
      
      {
				key: "alt + plus",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Add Row",
				description: "Add row",
				
				command: (e)=>{
				console.log(e)	
        this.addRow()
        setTimeout(() => {
          document.getElementsByTagName('body')[0].click()
          }, 100);
					},
					preventDefault: true
			},
      {
				key: "alt + -",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Delete Row",
				description: "Delete Row",
				
				command: (e)=>{
				console.log(e)
        if(this.receiptService.receiptArray.length>0){
          this.removeReceiptRow(this.receiptService.receiptArray.length-1)	
          setTimeout(() => {
            document.getElementsByTagName('body')[0].click()
            }, 100);
        }
					},
					preventDefault: true
			},
      {
				key: "alt + y",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Clicked On Yes Button",
				description: "Clicked On Yes Button",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById(`confirm_Opened_Modal_${this.confirmationModalType}_btn`)){

				document.getElementById(`confirm_Opened_Modal_${this.confirmationModalType}_btn`).click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
      this.confirmationModalType = ''
					},
					preventDefault: true
			},
      {
				key: "alt + n",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Clicked On No Button",
				description: "Clicked On No Button",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById(`confirm_close_Modal_${this.confirmationModalType}_btn`)){

				document.getElementById(`confirm_close_Modal_${this.confirmationModalType}_btn`).click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
      this.confirmationModalType = ''
					},
					preventDefault: true
			},

      {
				key: "f10",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Send mail",
				description: "Send mail",
				command: (e)=>{
				console.log(e)	
			if(document.getElementById('receipt_send_mail_btn') && this.searchId && this.searchId.length>0){
				document.getElementById('receipt_send_mail_btn').click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
     
	  )
	}

  get DynamicFormControls() {
    return <FormArray>this.receiptService.receiptEntryForm.get("receiptArray");
  }

  resetCurrentModalType(){
    this.confirmationModalType = ''
  }

  deleteReceipt(receiptData){
    this.closeConfirModal()
    this.spinner.show();
    this.receiptService.deleteMethod(`receiptentryv3?receipt_code=${receiptData['receipt_code']}`).subscribe(
      (res) => {
      this.submitResponse = ''
      this.showMessage = true
      this.alertType = 'success-box';
      this.message = `Receipt deleted successfully`      
      this.receiptService.initialiseFormData({})
      this.spinner.hide();
      },
      (err) => {
        this.showMessage = true
      this.alertType = 'error-box';
      this.message = `Failed to delete receipt.`
      this.spinner.hide();
      }
    );
  }

  getReceiptDetails() {
    this.receiptService
      .getMethod(`receiptentryv3?receipt_code=${this.searchId}`)
      .subscribe(
        async (res: any) => {
          if (res[0] != undefined) {
            this.receiptData = res;
              if(this.receiptData && this.receiptData != undefined) {
                this.checkReceiptFullyAdjustedOrNot(this.searchId, this.receiptData)
              }
  
            console.log(res);
            await this.setReceiptData();
            this.calculateDiff()

          }
        },
        (err) => {}
      );
  }
  setReceiptData() {
    this.receiptService.receiptEntryForm.controls.receipt_date.setValue(
      moment(this.receiptData[0].receipt_date).format("YYYY-MM-DD")
    );
    this.receiptService.receiptEntryForm.controls.receipt_code.setValue(
      this.receiptData[0].receipt_code
    );
    this.receiptService.receiptEntryForm.controls.account_code_to.setValue(
      this.receiptData[0].account_code_to
    );
    this.receiptService.receiptEntryForm.controls.narration_5.setValue(
      this.receiptData[0].narration_5
    );
    
    if(this.receiptData[0].hasOwnProperty("account_code_to_data") &&  this.receiptData[0].account_code_to_data && this.receiptData[0].account_code_to_data.name)
    this.receiptService.receiptEntryForm.controls.account_code_to_name.setValue(
      this.receiptData[0].account_code_to_data.name
    );else{
      let url = `general/autocomplete?name_like=${this.receiptData[0].account_code_to}` 
      this.receiptService.getMethod(url).subscribe(
        (res) => {
          this.generalMasterList = res;
          if(this.generalMasterList.length>0){
            this.receiptService.receiptEntryForm.controls.account_code_to_name.setValue(
              this.generalMasterList[0].name)
          }
        },
        (err) => {}
      );
    }

    for (let data of this.receiptData) {
      data.account_code_from_name = data.account_code_from_data && data.account_code_from_data.name?data.account_code_from_data.name:"";
      data.account_code_to_name = data.account_code_to_data && data.account_code_to_data.name?data.account_code_to_data.name:""
      this.receiptService.addRow(data);
    }
  }


  getGeneralMaster(event) {
    let name_like = event.target.value;
    if(name_like && name_like.length>2){
      this.currentMasterName = name_like;
      this.currentFormControlName = 'account_code_to_name'
      let url = `general/autocomplete?name_like=${name_like}` 
      this.receiptService.getMethod(url).subscribe(
        (res) => {
          this.generalMasterList = res;
          if(!this.generalMasterList || this.generalMasterList.length == 0) {
           this.showCreateButton = true;
           this.currentMaster = 'general'
          }else {
            this.showCreateButton = false;
            this.currentMaster = ''
          }
        }
       
        ,
        (err) => {}
      );
    }
   
  }

  resetReceipt(){
    this.receiptService.receiptEntryForm.reset()
  }





  

  removeReceiptRow(i) {
    console.log("removing receipt row num: ", i + 1);
    this.receiptService.receiptEntryForm.get("receiptArray").removeAt(i);
  }

  addRow() {
    this.receiptService.addRow(this.receiptService.receiptEntryForm.value);
  }

  // openConfirmModal(): void {
  //   this.confirmModal.nativeElement.click();
  //   this.commonService.selectedInput.next(24);
  // }
  // openUpdateConfirmModal(): void {
  //   this.updateConfirmModal.nativeElement.click();
  //   this.commonService.selectedInput.next(26);
  // }
  // closeConfirmModal(): void {
  //   this.closeModal.nativeElement.click();
  // }
  focusAccount(e: Event, index): void {
    e.stopPropagation();
    if (this.accountAC["_results"][index]) {
      this.accountAC["_results"][index].focus();
    }
  }

  focusBankAc(e) {
		if (this.bankAC != undefined && this.bankAC.focus()) {
			this.bankAC.focus()
		}
	}

  focusSubLedger(e: Event, index): void {
    e.stopPropagation();
    if (this.subLedgerAC["_results"][index]) {
      this.subLedgerAC["_results"][index].focus();
    }
  }

  focusOtherAccount(e): void {
    e.stopPropagation();
    this.otherAccountAC.focus();
  }

  async submit(formValue) {
    this.closeConfirModal()
    !this.financialYearDiff && this.checkDateOfEmployee();
    const invalid = [];
    const controls = this.receiptService.receiptEntryForm.controls;
    for (const name in controls) {
      if (controls[name].invalid && name != "receiptArray") {
        invalid.push(name);
      }
    }
    if(invalid.length > 0) {
      window.scroll(0,0)
      this.showMessage = true;
      this.alertType = 'error-box';
      this.message = `Invalid field ${invalid[0]} ${invalid.length > 1 ? `+${invalid.length-1}` : ''}`
      this.spinner.hide()
      return false
    }
    console.log("submited form value", formValue);
    let loggedInUser = JSON.parse(localStorage.getItem("okountUserInfo"));
    for (var data of formValue.receiptArray) {
      data.account_code_to = formValue['account_code_to']
      data.narration_5 = formValue['narration_5']
    }
    let requestData = {
      receipt_code: formValue.receipt_code,
      receipt_date: formValue.receipt_date,
      receipt_array: formValue.receiptArray,
      last_modified_by: loggedInUser.id,
      last_modified_by_name: loggedInUser.name,
      last_modified_by_code: loggedInUser.accountcode,
    };
	this.spinner.show();
	this.receiptService.postMethod(`receiptentryv3`, requestData).subscribe(
	  (res) => {
		this.submitResponse = res
		this.showMessage = false
		this.alertType = 'success-box';
		this.message = `Receipt created with `
    this.newReceipt = false
		// alert(JSON.stringify(this.submitResponse))
    this.openSuccessModal.nativeElement.click()
		this.receiptService.initialiseFormData({})
    this.receiptService.addRow(this.receiptService.receiptEntryForm.value);
		this.spinner.hide();
    let queryParams = this.route.queryParams['value']
    if(this.submitResponse && this.submitResponse.length>0 && queryParams && queryParams['ubr_id']) {
      this.updateReconcillation(queryParams, this.submitResponse[0])
    }
	  },
	  (err) => {
		  this.showMessage = true
		this.alertType = 'error-box';
    this.message = `Failed to create receipt.`
    if( err['error']['message']){
      this.message = err['error']['message']
    }
		this.spinner.hide();
	  }
	);
    
  }

  async updateAndExit(formValue){
    this.closeConfirModal()
    !this.financialYearDiff && this.checkDateOfEmployee();
    const invalid = [];
    const controls = this.receiptService.receiptEntryForm.controls;
    for (const name in controls) {
      if (controls[name].invalid && name != "receiptArray") {
        invalid.push(name);
      }
    }
    if(invalid.length > 0) {
      window.scroll(0,0)
      this.showMessage = true;
      this.alertType = 'error-box';
      this.message = `Invalid field ${invalid[0]} ${invalid.length > 1 ? `+${invalid.length-1}` : ''}`
      this.spinner.hide()
      return false
    }
	console.log("submited form value", formValue);
    let loggedInUser = JSON.parse(localStorage.getItem("okountUserInfo"));
    for (var data of formValue.receiptArray) {
      data.account_code_to = formValue['account_code_to']
      data.narration_5 = formValue['narration_5']
    }
    let requestData = {
      receipt_code: formValue.receipt_code,
      receipt_date: formValue.receipt_date,
      receipt_array: formValue.receiptArray,
      last_modified_by: loggedInUser.id,
      last_modified_by_name: loggedInUser.name,
      last_modified_by_code: loggedInUser.accountcode,
    };
	this.spinner.show();
	this.receiptService.putMethod(`receiptentryv3/${this.searchId}`, requestData).subscribe(
	  (res) => {
		let response = res;
		this.showMessage = true
		this.alertType = 'success-box';
		this.message = `Receipt updated.`
		console.log("Response : ", response);
		// this.getEntries()
		this.spinner.hide();
	  },
	  (err) => {
		// this.getEntries()
		this.showMessage = true
		this.alertType = 'error-box';
		this.message = `Failed to updated receipt.`
    this.message = `Failed to create receipt.`
    if( err['error']['message']){
      this.message = err['error']['message']
    }
		this.spinner.hide();
	  }
	);
  }
  hideError() {
	this.showMessage = false
}

  viewJournal(invoice_no) {
    let url = `${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/view-invoice-journal/${invoice_no}`;
		window.open(url, "_blank");
	}

  openActivity(){
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/activity/activity-summary/receipt/${this.searchId}`;
		if(window.location.protocol != 'file:'){
			window.open(path,'_blank')
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : path
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}

  getTotalAmount() {
    let receipt_id = this.receiptService.receiptForm.value.receipt_id;
    this.receiptService
      .getMethod(`receiptentryv3/getTotal?receipt_id=${receipt_id}`)
      .subscribe(
        (res) => {
          let response = res;
          this.receiptService.receiptForm.controls.amount.setValue(
            response["totalAmount"]
          );
          console.log("Response : ", response);
        },
        (err) => {}
      );
    console.log();
  }

  getTotal(amountType) {
    let amount = 0;
    if (
      this.receiptService.receiptEntryForm.controls.receiptArray.controls
        .length > 0
    ) {
      let controls =
        this.receiptService.receiptEntryForm.controls.receiptArray.controls;
      for (let index = 0; index < controls.length; index++) {
        const element = controls[index];
        amount += Number(element.controls[amountType].value);
      }
    }
    return Number(amount.toFixed(2));
  }

  openSendEmailModal(){
    let primary_email = ''
    let secondary_email = ''
    let subject = `RECEIPT NO. ${this.searchId}`
    let body = `Dear Travel Partner,\n\nPlease find Attached Receipt ${this.searchId}`
    body+=`\n\nPlease do not hesitate to contact us if you have any query or concern about attached document.\n\nIt's been a pleasure to serving you\n\nBest Regards\n\n${this.receiptData[0].accounting_company?.name}\nAccounts Team`
    if(this.receiptData[0].accounting_company && this.receiptData[0]?.accounting_company.telephone){
			body += `\nContact Us on ${this.receiptData[0]?.accounting_company.telephone}`
		}
    if(this.receiptData && this.receiptData.length>0 && this.receiptData[0].account_code_from_data){
      let clinetData = this.receiptData[0].account_code_from_data
      primary_email = clinetData.hasOwnProperty('primary_email')?clinetData.primary_email:''
      secondary_email = clinetData.hasOwnProperty('secondary_email')?clinetData.secondary_email:''
    }

    this.sendEmailForm.controls.toEmails.setValue(primary_email)
    this.sendEmailForm.controls.bccEmails.setValue("")
    this.sendEmailForm.controls.ccEmails.setValue(secondary_email?.split(',').join('\n'))
    this.sendEmailForm.controls.subject.setValue(subject)
    this.sendEmailForm.controls.body.setValue(body)
    this.openEmailModal.nativeElement.click();
	}
	callTosendEmail(){
  	
		let toEmails = this.sendEmailForm.value.toEmails
		let bccEmails = this.sendEmailForm.value.bccEmails?.split(",")
		let ccEmails = this.sendEmailForm.value.ccEmails?.split(",")
    let subject = this.sendEmailForm.value.subject
    let body = this.sendEmailForm.value.body
    let clientName = this.receiptData && this.receiptData.length>0 && this.receiptData[0].account_code_from_data &&  this.receiptData[0].account_code_from_data.name? this.receiptData[0].account_code_from_data.name:''
    if(toEmails)
    {
      toEmails = toEmails.split(",")
      window.scrollTo(0, 0)
      let emails = {
      to_addresses:toEmails,
      cc_addresses:ccEmails,
      bcc_addresses:bccEmails
      }
      let pageUrl = `transactions/receipt/print/${this.searchId}`
      const formData = new FormData();
			formData.append('email',JSON.stringify(emails))
			formData.append('name',clientName)
			formData.append('page_url',pageUrl)
			formData.append('subject',subject)
			formData.append('email_body',body)
			formData.append('document_num',this.searchId)
			if(this.attachmentFile){
				formData.append('attachmentFile',this.attachmentFile)
			}
			let data = formData
      this.commonService.fileUpload(`${environment.okountUrl}/send_mail?transaction_type=receipt`,data).subscribe((res : any)=> {
      console.log(res)
      this.showMessage = true;
      this.alertType = 'success-box'
      this.message = res.msg
      this.closebutton.nativeElement.click();
      },err => {
      this.showMessage = true
      this.alertType = "error-box"
      this.message = err.msg
      this.closebutton.nativeElement.click();
      
      })
  }
	}

  getClient(value) {
      if (value.length > 2) {
        this.commonService.getOkount(`client/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
            console.log("res:",res)
            this.clientData = res[0]
        }, err => {
          console.log(err)
        })
      }
    }

  resetFormData() {
    this.spinner.hide();
  }

  getAccounts(url) {
    this.receiptService.getMethod(url).subscribe(
      (res) => {
        this.receiptService.accountList = res;
      },
      (err) => {}
    );
  }
  getLedgers(url) {
    this.receiptService.getMethod(url).subscribe(
      (res) => {
        this.receiptService.ledgerList = res;
      },
      (err) => {}
    );
  }

  onValueChange(event) {
    this.amountField.nativeElement.focus();
  }

  onFocused(e) {}

  ledgerSearch(event, i) {
    let value = event.target.value;
    try {
      let searchType =
        this.receiptService.receiptEntryForm.controls.receiptArray.controls[i]
          .value.sub_ledger_code_1_type;
      if (value.length > 2 && searchType != null && searchType != "") {
        this.receiptService
          .searchAccount(
            `${searchType}/autocomplete?name_like=${value.toUpperCase()}&status=active`
          )
          .subscribe((res) => {
            this.ledgerList[i] = res;
          });
      }
    } catch (error) {}
  }

  selectAccount(nzEvent, formControlName, i) {
    let nzValue = nzEvent && nzEvent.nzValue;
    let value = nzValue;
    if(value) {
    try {
      this.receiptService.receiptEntryForm.controls.receiptArray.controls[
        i
      ].controls[formControlName].setValue(value.account_code);
    } catch (error) {}
   } else {
    this.receiptService.receiptEntryForm.controls.receiptArray.controls[i].controls[`${formControlName}_name`].setValue(this.currentMasterName);
    this.masterCreationModal.nativeElement.click()
    return false;
   }
  }

  selectBank(nzEvent, formControlName) {    
    let nzValue = nzEvent && nzEvent.nzValue;
    if(nzValue) {
      let value = nzValue;
      try {
        this.receiptService.receiptEntryForm.controls[formControlName].setValue(value.account_code);
      } catch (error) {}
    }  else {
      this.receiptService.receiptEntryForm.controls[`${formControlName}_name`].setValue(this.currentMasterName);
      this.masterCreationModal.nativeElement.click()
      return false;
   }
   
  }


  onChangeSearch(event, i) {
    let value = event.target.value;
    try {
      let searchType =
        this.receiptService.receiptEntryForm.controls.receiptArray.controls[i]
          .value.account_code_from_type;
      if (value.length > 2 && searchType != null && searchType != "") {
        this.currentMasterName = value;
        this.currentFormControlName = 'account_code_from_name'
        this.receiptService
          .searchAccount(
            `${searchType}/autocomplete?name_like=${value.toUpperCase()}&status=active`
          )
          .subscribe((res) => {
            this.accountList[i] = res;
            if(!this.accountList[i] || this.accountList[i].length == 0) {
              this.showCreateButton = true;
              this.currentMaster = this.receiptService.receiptEntryForm.controls.receiptArray.controls[i].value.account_code_from_type;
             } else {
              this.showCreateButton = false;
              this.currentMaster = '';
             }
          });
      }
    } catch (error) {}
  }

  setAccountAutoCompleteStatus(status) {
    this.accountAutoComplete = status;
  }

  focusTab(tabIndex) {
    this.commonService.selectedInput.next(tabIndex);
  }


  openConfirModal(confirmationModalType){
    if(this.commonService.gstFiledDate) {
      let dateToCheckCondition = confirmationModalType == 'save' ? moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.receiptService.receiptEntryForm.controls.receipt_date.value).format('YYYY-MM-DD')) : (moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.receiptData[0].receipt_date).format('YYYY-MM-DD')) || moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.receiptService.receiptEntryForm.controls.receipt_date.value).format('YYYY-MM-DD'))) ;
			if(dateToCheckCondition) {
				this.showMessage = true
				this.spinner.hide()
				this.alertType = "error-box"
				this.message = `Not Allowed to ${confirmationModalType} the invoice.`
				return false;
			}
		}
    this.confirmationModalType = confirmationModalType
    this.openConfirmationModal.nativeElement.click()
}

  closeConfirModal(){
    this.closeConfirmationModal.nativeElement.click()
  }




  openVoucher(code) {
    let path = `${
      environment.name == "v3-stage" || environment.name == "v3-prod"
        ? "/v3/"
        : "/"
    }#/transactions/receipt/print/${code}`;

    if (window.location.protocol != "file:") {
      window.open(path, "_blank");
    } else {
      var customeEventHandler = new CustomEvent("handle-window-open", {
        detail: {
          hashPath: path,
        },
      });
      window.dispatchEvent(customeEventHandler);
    }
  }

  openAdjustmentModal() {    
    let data = this.receiptData[0]
    let path = `${
      environment.name == "v3-stage" || environment.name == "v3-prod"
        ? "/v3/"
        : "/"
    }#/report/adjust-balance?account_code=${data.account_code_from}&adjustment_reference=${data.receipt_code}&reference_type=receipt`;
   
    if(window.location.protocol != 'file:') {
      window.open(path, '_blank')
    }
  }


  viewAdjustedInvoices() {    
    let data = this.receiptData && this.receiptData.length > 0 && this.receiptData[0] 
    let path = `${
      environment.name == "v3-stage" || environment.name == "v3-prod"
        ? "/v3/"
        : "/"
    }#/report/adjusted-invoices?account_code=${data.account_code_from}&adjustment_reference=${data.receipt_code}&reference_type=receipt`;
   
    if(window.location.protocol != 'file:') {
      window.open(path, '_blank')
    }
  }


  checkReceiptFullyAdjustedOrNot(searchId, receiptData) {
    let clientCode = receiptData && receiptData.length>0 && receiptData[0].account_code_from_data && receiptData[0].account_code_from_data.account_code? receiptData[0].account_code_from_data.account_code:''

    let receiptAmount = 0
    if(receiptData && receiptData.length > 0) {
      for(let item of receiptData) {
        receiptAmount += Number(item.amount)
      }
    }
    
		this.commonService
      .getOkount(`payment_adjustment/get-reference-data?account_code=${clientCode}&reference=${searchId}`)
		  .subscribe(
			async (res: any) => {
        if(res && res['data'] && res['data']['adjustment_amount']) {
          if(receiptAmount != res['data']['adjustment_amount']) {
            this.showAdjustbtn = true
          }
        }else {
          this.showAdjustbtn = true
        }
			}, err => {
        console.error(err)
      })
      this.spinner.hide()
	}

  viewReceipt(invoice_no) {
      this.router.navigateByUrl(`transactions/receipt/view/${invoice_no}`)
  }

  closeSuccesModal(){
		this.showMessage = false;
		this.alertType = 'success-box';
		this.message = ``
    this.newReceipt = true
		this.openSuccessModal.nativeElement.click()
	}

  copytext(val: string) {
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
		this.copyELementTitle = 'Copied'
	}

  getUbrData(ubr_id) {
    this.commonService
    .getOkount(`bank_reconciliation/ubr/data?ubr_id=${ubr_id}`)
    .subscribe(
      async (res: any) => {
        if(res && res['data']) {
          let data = res['data']
          if(data.value_date) {
            let valueDate = moment(data.value_date).format('YYYY-MM-DD')
            this.receiptService.receiptEntryForm.controls.receipt_date.setValue(valueDate)
          }
          if(data.bank_code) {
            this.receiptService.receiptEntryForm.controls.account_code_to.setValue(data.bank_code)
          }
          if(data.bank_name) {
            this.receiptService.receiptEntryForm.controls.account_code_to_name.setValue(data.bank_name)
          }
          if(data.transaction_amount){
            let receiptArrayControls = this.receiptService.receiptEntryForm.controls.receiptArray.controls
            if(receiptArrayControls && receiptArrayControls.length>0){
              for(let item of receiptArrayControls) {
                item.controls.amount.setValue(data.transaction_amount)
              }
            }
          }        
        }
    }, err => {
      console.error(err)
    })
  }

  updateReconcillation(queryParams, response){
    let requestData = {
      transaction_ref_no: response.receipt_code,
      narration_2: response.narration_2,
      id: queryParams['ubr_id'],
      v3_amount: queryParams.v3_amount
    };
    this.receiptService.postMethod(`bank_reconciliation/update`, requestData).subscribe(
      (res) => {
      window.close();      
      },
      (err) => {
      })
    }

    
   setFormValues(data) {
    let name = 'account_name'

    if(this.currentMaster == 'supplier' || this.currentMaster == 'client'){
      name = 'name'
    }

    this.showMessage = true;
    this.alertType = 'success-box';
    this.message = `${this.currentMaster} created successfully with account code. : ${data.account_code}`

    if(this.currentFormControlName  == 'account_code_to_name') {
      if(data.account_code) {
        this.receiptService.receiptEntryForm.controls.account_code_to.setValue(data.account_code)
      }
      if(data[name]) {
        this.receiptService.receiptEntryForm.controls.account_code_to_name.setValue(data[name])
      }
    }else {
      this.receiptService.receiptEntryForm.controls.receiptArray.controls[0].controls.account_code_from.setValue(data.account_code)
      this.receiptService.receiptEntryForm.controls.receiptArray.controls[0].controls.account_code_from_name.setValue(data[name])
    }
    
    this.showCreateButton = false;
   } 

   openMasterModal() {
    this.isModalOpened = true;

    this.activeElementBeforeModal = document.activeElement as HTMLElement;
    this.masterCreationModal.nativeElement.click()
   }

   closeMasterModal() {
    this.isModalOpened = false;
    if (this.activeElementBeforeModal) {
      this.activeElementBeforeModal.focus();
      this.activeElementBeforeModal = null;
    }
    this.closeMasterCreationModal.nativeElement.click()
   }

   calculateGstOnCharges(event, i) {
     let bankCharges = this.receiptService.receiptEntryForm.controls.receiptArray.controls[i].controls.charges.value;
     let gstOnCharges = (bankCharges* this.gstRate/100)
     this.receiptService.receiptEntryForm.controls.receiptArray.controls[i].controls.gst_on_charges.setValue(gstOnCharges)
   } 

   calculateDiff(){
    let amount = 0
    if (this.receiptService.receiptEntryForm.controls.receiptArray.controls.length > 0) {
      let controls = this.receiptService.receiptEntryForm.controls.receiptArray.controls
      for (let index = 0; index < controls.length; index++) {
        const element = controls[index];
        amount += Number(element.controls.amount.value)
      }
    }
    this.totalAmount = amount
   }
   handleAttachment(event: any) {
		this.attachmentFile = event.target.files[0];
	  }


  receiptDateValidation() {
      let financeYearResponse = this.commonService.checkFinancialYearValidation();
      this.financialYearDiff = financeYearResponse.isFinancialYearDiff;
      this.minDate = financeYearResponse.minDate;
      this.maxDate = financeYearResponse.maxDate;
      
      this.receiptService.receiptEntryForm.controls.receipt_date.setValue(this.maxDate);
      this.receiptService.receiptEntryForm.controls.receipt_date.setValidators([Validators.required, this.validateDate()]);
      this.receiptService.receiptEntryForm.controls.receipt_date.updateValueAndValidity()
    }  

  }

