import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { CommonServiceService } from '../../common-service.service';
import { environment } from 'src/environments/environment';
import { GlobalVariable } from '../../global-variable';
import { Store } from '@ngrx/store';
import { InitialState } from 'src/app/store/reducer';
@Component({
  selector: 'app-tds-report-search',
  templateUrl: './tds-report-search.component.html',
  styleUrls: ['../global-search.component.scss']

})
export class TdsReportSearchComponent implements OnInit {
  branches = []
  tdsreportFilterForm: FormGroup;
	@ViewChild('searchButton',{static:true}) searchButton : ElementRef;
	minDate = "2023-04-01"
  maxDate = moment().format('YYYY-MM-DD')
  constructor( 
    public commonService: CommonServiceService,
    private router: Router,
    private formBuilder: FormBuilder,private store: Store<InitialState>) 
    { 
      this.store.select('sharedReducers').subscribe((items)=>{
				if(items.financeYear && items.financeYear.start_date && items.financeYear.end_date){
					this.minDate = items.financeYear.start_date
					this.maxDate = this.commonService.getFinancialSession(moment(items.financeYear.end_date)) < this.commonService.getFinancialSession(moment()) ? 
					            moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
				} else {
					let current_financial_year =  JSON.parse(sessionStorage.getItem('current_financial_year'))
					if(!current_financial_year) {
						current_financial_year = JSON.parse(localStorage.getItem('current_financial_year'));
					}
					this.minDate = current_financial_year.start_date
					this.maxDate = this.commonService.getFinancialSession(moment(current_financial_year.end_date)) < this.commonService.getFinancialSession(moment()) ? 
					            moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
				}
				
		})
  }
  ngOnInit() {
    this.tdsreportFilterForm = this.formBuilder.group({
      start_date: [this.minDate, this.checkDate('start_date')],
			end_date: [this.maxDate,this.checkDate('end_date')],
      subgroup_type: ['client'],
      viewMode: ['normal'],
      selectedBranch: ['']

		})
    this.setFormData()
    this.getAllBranches()
  }

  checkDate(formControlName){
		return (control: AbstractControl) => {
			let valid = true
			if(!moment(control.value, 'YYYY-MM-DD').isValid()) {
				valid = false
			} else {
				if(this){
					if (moment(control.value).isBefore(moment(this.minDate, 'YYYY-MM-DD')) || moment(control.value).isAfter(moment(this.maxDate, 'YYYY-MM-DD'))) {
						valid = false
					}
				}
			}
			return !valid ? {[formControlName]: {value: control.value}} : null;
		};
	}

  setFormData() {
    let queryParams = localStorage.getItem('activeTDSQueryParams')
    if (queryParams != null) {
      let parsedParams = JSON.parse(queryParams)
        this.tdsreportFilterForm.controls.start_date.setValue(moment(this.minDate).format('YYYY-MM-DD'))
        this.tdsreportFilterForm.controls.end_date.setValue(moment(this.maxDate).format('YYYY-MM-DD'))
    }
  }

   search(values){
    GlobalVariable.openModalStatus = false;
    if (values.start_date == null || values.start_date == "" || values.start_date == "Invalid Date" || values.start_date == "Invalid date") {
      values.start_date = ""
    }
    else {
      values.start_date = moment(values.start_date).format('YYYY-MM-DD')
    }
    if (values.end_date == null || values.end_date == "" || values.end_date == "Invalid Date" || values.end_date == "Invalid date") {
      values.end_date = ""
    }
    else {
      values.end_date = moment(values.end_date).format('YYYY-MM-DD')
    }
    if(values.viewMode=='normal'){
      this.router.navigateByUrl(`/display/statutory-report/tds-report?start_date=${values.start_date}&end_date=${values.end_date}&subgroup_type=${values.subgroup_type}&viewMode=${values.viewMode}&branch=${values.selectedBranch}`)
    }else{
      this.router.navigateByUrl(`/report/tds-report-detailed?start_date=${values.start_date}&end_date=${values.end_date}&subgroup_type=${values.subgroup_type}&viewMode=${values.viewMode}&branch=${values.selectedBranch}`)
    }
    //  this.reset()
   }

   getFilteredData(queryParams) {
    this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/reports/tds`, queryParams).subscribe(res => {
      console.log(res);
    }, err => {
      console.error(err);
    })
  }

  reset(){
    GlobalVariable.openModalStatus = false;
    this.tdsreportFilterForm = this.formBuilder.group({
      start_date: [this.minDate],
			end_date: [this.maxDate],
      subgroup_type:['client'],
      viewMode:['normal']
    })
    localStorage.removeItem('activeTDSQueryParams')
  }

  clickSearchButton(){
		this.searchButton.nativeElement.click()
	}
  getAllBranches() {
    this.commonService.getOkount(`branch`).subscribe(res=> {
      if(res && res['data']) {
        this.branches = res['data'];
      }
    },err=> {

    })
  }

}

